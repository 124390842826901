import { AxiosPromise } from 'axios';
import { DEFAULT_TIMEOUT } from 'constants/timeout';
import moment from 'moment';
import { EmissionFactor } from 'types/entities/emissionFactor';
import { Organization, OrganizationPatchPayload } from 'types/entities/organization';
import { RegisterInfo2 } from 'types/entities/user';
import apiFetch from 'utils/apiFetch';
import dateToTimestamp from 'utils/dateToTimestamp';
/**
 * Helper function to conditionally add headers.
 */

export const getOrganizationEfs = async (
  id: string,
  startDate: Date,
  endDate: Date,
  category: string
) => {
  try {
    const startDateParsed = new Date(moment(startDate).format('YYYY-MM-DD'));
    const endDateParsed = new Date(moment(endDate).format('YYYY-MM-DD'));

    const startDateTimestamp = Math.floor(startDateParsed.getTime() / 1000);
    const endDateTimestamp = Math.floor(endDateParsed.getTime() / 1000);

    const request: AxiosPromise<EmissionFactor[]> = apiFetch(
      'GET',
      `/organizations/ef_list/${id}`,
      null,
      {
        'x-organization-id': id
      },
      {
        start_date: startDateTimestamp,
        end_date: endDateTimestamp,
        category: category === 'stationary' ? 'heat' : category
      },
      DEFAULT_TIMEOUT
    );

    const response = await request;
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getOrganizationRecomendationsOrganization = async (
  organizationId: string,
  returnRequest = false
) => {
  try {
    const request = apiFetch('GET', `/organization_recommendations/organization/${organizationId}`);

    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getOrganizationSectorComparison = async (
  organizationId: string,
  endDate: Date,
  returnRequest = false
) => {
  try {
    const request = apiFetch(
      'GET',
      `/organizations/sector_comparison/${organizationId}`,
      null,
      null,
      {
        end_date: dateToTimestamp(endDate)
      }
    );

    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (error) {
    return null;
  }
};

export const createOrganization = async (user_id: string, organization: RegisterInfo2) => {
  try {
    const response = await apiFetch('POST', `/organizations`, organization, {
      'x-user-id': user_id
    });
    return response.data as Organization;
  } catch (error) {
    console.error(error);
    return null;
  }
};

type CompanyLogoFile = {
  file: string;
  file_name: string;
};
export const uploadCompanyLogo = async (organizationId: string, file: CompanyLogoFile) => {
  try {
    const response = await apiFetch('PATCH', `/organizations/${organizationId}/logo`, file, {
      'x-organization-id': organizationId
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const updateOrganization = async (
  payload: OrganizationPatchPayload
): Promise<Organization> => {
  const { id, ...rest } = payload;

  const response = await apiFetch('PATCH', `/organizations/${id}`, rest);

  return response.data;
};

export const getOrganizations = async (): Promise<Organization[] | null> => {
  try {
    const response = await apiFetch('GET', `/organizations`);
    return response.data as Organization[];
  } catch (error) {
    console.error('Error fetching organizations:', error);
    return null;
  }
};

export const getOrganizationWastesReport = async () => {
  try {
    const response = await apiFetch('GET', `/organizations/files/wastes_report`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getOrganizationInfo = async (organizationId: string) => {
  const response = await apiFetch('GET', `/organizations/${organizationId}`);

  return response.data;
};
