import { useMutation } from '@tanstack/react-query';
import { downloadMaterials } from 'services/api/lca/materialManagement';

export const useDownloadMaterials = () => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: downloadMaterials
  });

  return { downloadMaterials: mutateAsync, downloading: isPending };
};
