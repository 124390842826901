import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createOrganizationAndLinkToHoldingAction } from '../../../../../actions/auth';
import { getCountries } from '../../../../../constants/phonePrefixes';
import { ROUTES } from '../../../../../constants/routes';
import { sectors } from '../../../../../constants/sectors';
import Button from '../../../../ui/button/Button';
import Checkbox from '../../../../ui/formComponents/checkbox/Checkbox';
import InputNumber from '../../../../ui/formComponents/inputNumber/InputNumber';
import InputText from '../../../../ui/formComponents/inputText/InputText';
import Select from '../../../../ui/formComponents/select/Select';
import './styles.scss';

const CreateOrganizationHolding = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleSubmitForm();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  // useEffect(() => {
  //   axios
  //     .get('https://ipapi.co/json/')
  //     .then((response) => {
  //       const data = response.data;

  //       setCountry({
  //         id: data.country_code,
  //         name: getPrefixes(i18n.resolvedLanguage).find(
  //           (prefix) => prefix.code === data.country_code
  //         )?.name
  //       });
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, []);

  const [company, setCompany] = useState('');
  const [cif, setCIF] = useState('');
  const [sector, setSector] = useState({
    id: '',
    name: ''
  });
  const [employees, setEmployees] = useState('');
  const [country, setCountry] = useState({
    id: '',
    name: ''
  });
  const [sharePercentage, setSharePercentage] = useState('');
  const [includeScope3, setIncludeScope3] = useState(false);
  const [tag, setTag] = useState('');

  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const onChangeCompany = (e: ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
    const newErrors = errors.filter((elem) => elem.error !== 'company');
    setErrors(newErrors);
  };

  const onChangeCIF = (e: ChangeEvent<HTMLInputElement>) => {
    setCIF(e.target.value);
    const newErrors = errors.filter((elem) => elem.error !== 'cif');
    setErrors(newErrors);
  };

  const onChangeEmployees = (value: string) => {
    const maxValue = 10000;
    if (Number(value) >= 0 && Number(value) <= maxValue) {
      setEmployees(value);
      const newErrors = errors.filter((elem) => elem.error !== 'employees');
      setErrors(newErrors);
    }
  };

  const onChangeSector = (value: SelectOptionFormat) => {
    setSector(value);
    const newErrors = errors.filter((elem) => elem.error !== 'sector');
    setErrors(newErrors);
  };

  const onChangeCountry = (value: SelectOptionFormat) => {
    setCountry(value);
    const newErrors = errors.filter((elem) => elem.error !== 'country' && elem.error !== 'vat');
    setErrors(newErrors);
  };

  const onChangeSharePercentage = (value: string) => {
    const newErrors = errors.filter((elem) => elem.error !== 'sharePercentage');
    setErrors(newErrors);
    const max = 100;
    const min = 0;
    if (value === '') {
      setSharePercentage(value);
    } else {
      const valueParsed = parseFloat(value);
      if (valueParsed <= max && valueParsed >= min) {
        setSharePercentage(value);
      }
    }
  };

  const onChangeTag = (e: ChangeEvent<HTMLInputElement>) => {
    setTag(e.target.value);
  };

  const handleSubmitForm = () => {
    const newErrors = [...errors].filter((elem) => elem.error !== 'general');
    if (!company && !errors.some((elem) => elem.error === 'company')) {
      newErrors.push({ error: 'company' });
    }
    if (!cif && !errors.some((elem) => elem.error === 'cif')) {
      newErrors.push({ error: 'cif' });
    }

    if ((!sector.id || !sector.name) && !errors.some((elem) => elem.error === 'sector')) {
      newErrors.push({ error: 'sector' });
    }
    if (!employees && !errors.some((elem) => elem.error === 'employees')) {
      newErrors.push({ error: 'employees' });
    }
    if ((!country.id || !country.name) && !errors.some((elem) => elem.error === 'country')) {
      newErrors.push({ error: 'country' });
    }
    if (sharePercentage === '') {
      newErrors.push({ error: 'sharePercentage' });
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
    } else {
      const { id } = params;
      const data: CreateOrganizationHoldingInfo = {
        sector: sector.id,
        vat: cif,
        employee_count_signup: Number(employees),
        country: country.id,
        company_name: company,
        share: parseFloat(sharePercentage) / 100,
        includeScope3: includeScope3,
        tag
      };
      if (id) {
        createOrganizationAndLinkToHolding(id, data);
      }
    }
  };

  const createOrganizationAndLinkToHolding = async (
    id: string,
    data: CreateOrganizationHoldingInfo
  ) => {
    setLoadingButton(true);
    if (!params.userId) {
      setLoadingButton(false);
      setErrors([...errors, { error: 'general' }]);
      return;
    }
    const response: any = await dispatch(
      createOrganizationAndLinkToHoldingAction(id, data, params.userId)
    );

    const newErrors = [...errors];
    setLoadingButton(false);
    if (response && response === 'INVALID_VAT_PASSED') {
      newErrors.push({ error: 'cif', description: t('register.cifNotValid') });
      setErrors(newErrors);
    } else if (response === 'DUPLICATED_VAT') {
      newErrors.push({ error: 'cif', description: t('register.cifDuplicated') });
      setErrors(newErrors);
    } else if (response) {
      navigate(ROUTES.MEASURE_COMPANIES);
    } else {
      newErrors.push({ error: 'general' });
      setErrors(newErrors);
    }
  };

  const sectorsParsed = sectors.map((elem) => {
    return {
      id: elem.id,
      name: elem.title[i18n.language.split('-')[0] as keyof Language]
    };
  });

  return (
    <section className='create-organization-holding'>
      <div className='create-organization-holding-section'>
        <img
          className='create-organization-holding-section__logo'
          src='/images/logoBlack.svg'
          alt='logo'
        />
        <div className='create-organization-holding-section__header'>
          <h1 className='headline1-font on-light-text-color'>
            {t('createOrganizationHolding.title')}
          </h1>
          <h3 className='subtitle1-font on-light-text-color'>
            {t('createOrganizationHolding.subtitle')}
          </h3>
        </div>
        <div className='create-organization-holding-section__register-form form-wrapper main-bg-color'>
          <InputText
            label={t('createOrganizationHolding.company')}
            icon={'/images/icons/briefcase.svg'}
            placeholder={t('createOrganizationHolding.companyPlaceholder')}
            value={company}
            onChangeValue={onChangeCompany}
            error={errors.find((elem) => elem.error === 'company')}
          />
          <Select
            label={t('createOrganizationHolding.sector')}
            icon='/images/icons/piechart.svg'
            placeholder={t('createOrganizationHolding.sectorPlaceholder')}
            value={sector}
            onChangeValue={onChangeSector}
            options={sectorsParsed}
            error={errors.find((elem) => elem.error === 'sector')}
          />
          <InputText
            label={t('createOrganizationHolding.cif')}
            icon='/images/icons/idcard.svg'
            placeholder={t('createOrganizationHolding.cifPlaceholder')}
            value={cif}
            onChangeValue={onChangeCIF}
            error={errors.find((elem) => elem.error === 'cif')}
          />
          <InputNumber
            label={t('createOrganizationHolding.employees')}
            icon='/images/icons/people.svg'
            placeholder={t('createOrganizationHolding.employeesPlaceholder')}
            value={employees}
            onChangeValue={onChangeEmployees}
            error={errors.find((elem) => elem.error === 'employees')}
          />
          <Select
            label={t('createOrganizationHolding.country')}
            icon='/images/icons/pin.svg'
            placeholder={t('createOrganizationHolding.countryPlaceholder')}
            value={country}
            onChangeValue={onChangeCountry}
            options={getCountries(i18n.resolvedLanguage, i18n.resolvedLanguage.toUpperCase())}
            error={errors.find((elem) => elem.error === 'country')}
          />
          <div className='input-element'>
            <InputText
              icon={'/images/icons/message.svg'}
              placeholder={t('companies.writeTag')}
              label={t('companies.tagOptional')}
              value={tag}
              onChangeValue={onChangeTag}
              height='31px'
              maxChars={25}
            />
          </div>
          <div className='input-element'>
            <InputNumber
              icon={'/images/icons/piechart.svg'}
              placeholder={t('createOrganizationHolding.writeSharePercentage')}
              label={t('createOrganizationHolding.sharePercentage')}
              value={sharePercentage}
              onChangeValue={onChangeSharePercentage}
              error={errors.find((elem) => elem.error === 'sharePercentage')}
            />
            <div className='checkbox-margin'>
              <Checkbox
                text={t('createOrganizationHolding.scope3Included')}
                onChange={setIncludeScope3}
                selected={includeScope3}
              />
            </div>
          </div>
        </div>
        <div className='flex create-organization-holding-section__register-form__footer'>
          <Button
            lookAndFeel='primary'
            text={t('createOrganizationHolding.startToUse')}
            onClick={handleSubmitForm}
            loading={loadingButton}
          />
        </div>
        {errors.find((elem) => elem.error === 'general') && (
          <span className='error-text error-font error-text-color'>
            {t('createOrganizationHolding.registerFail')}
          </span>
        )}
      </div>
    </section>
  );
};

export default CreateOrganizationHolding;
