import { useUser } from './useUser';

type Params = {
  contractOrgId?: string | null;
};

export const useIsOwnerOfContractOrg = ({ contractOrgId }: Params) => {
  const user = useUser();

  if (!contractOrgId) {
    return { isOwnerOfContractOrg: false };
  }

  const contractOrg = user?.organizations?.find((org) => org.id === contractOrgId);

  if (!contractOrg) {
    return { isOwnerOfContractOrg: false };
  }

  return { isOwnerOfContractOrg: contractOrg.role === 'owner' };
};
