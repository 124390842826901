import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import { useACVId } from '../../hooks/useACVId';
import { useCreateNode } from '../../hooks/useCreateNode';
import { useIsMutating } from '@tanstack/react-query';
import { NODE_TYPES } from '../../constants';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import { useEdgeHighlight } from '../EdgeHighlightContext';
import { useTransportBTNPos } from './useTransportBTNPos';
import { REM } from 'constants/measures';
import { TRANSPORT_BTN_OFFSETS } from './constants';

type Props = {
  x: number;
  y: number;
  target: string;
  origins: string[];
};

const CreateTransportBTNBase: React.FC<Props> = ({ x, y, target, origins }) => {
  const { t } = useLCATranslation();
  const { setHighlightedEdges } = useEdgeHighlight();
  const { acv_id } = useACVId();
  const { createNode } = useCreateNode({ acv_id });
  const isCreating = useIsMutating({ mutationKey: ['lca-create-node', acv_id] });

  const onMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (!origins) return;

    setHighlightedEdges(origins);
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setHighlightedEdges([]);
  };

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    createNode({
      type: NODE_TYPES.transport,
      coordinate_x: x - 10 * REM,
      coordinate_y: y,
      acv_id,
      origins: origins,
      target
    });
  };

  return (
    <div
      className='h-6 w-6 nodrag nopan'
      style={{
        position: 'absolute',
        transform: `translate(${TRANSPORT_BTN_OFFSETS.X}px, ${TRANSPORT_BTN_OFFSETS.Y}px)`
      }}>
      <TooltipWrapper text={t('transport_configuration.tooltip')} asChild>
        <Button
          lookAndFeel='secondary'
          className='h-full w-full'
          size='tiny'
          iconNode={!isCreating && <Icon icon='lorry' color='gray-dark' />}
          onClick={onClick}
          loading={isCreating > 0}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </TooltipWrapper>
    </div>
  );
};

type CreateTransportBTNProps = {
  target: string;
  origins: string[];
};

export const CreateTransportBTN = ({ target, origins }: CreateTransportBTNProps) => {
  const { x, y } = useTransportBTNPos({ target });

  return <CreateTransportBTNBase x={x} y={y} target={target} origins={origins} />;
};

CreateTransportBTN.Base = CreateTransportBTNBase;
