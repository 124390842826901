import { useInternalNode } from '@xyflow/react';
import { useTop0 } from '../CustomEdges/useTop0';
import { TRANSPORT_BTN_OFFSETS } from './constants';

type Params = {
  target: string;
};

export const useTransportBTNPos = ({ target }: Params) => {
  const internalNode = useInternalNode(target);

  const targetX = internalNode?.position.x ?? 0;
  const targetY = internalNode?.position.y ?? 0;
  const nodeWidth = internalNode?.width ?? 0;

  const left0 = targetX - nodeWidth / 2;

  const { top0 } = useTop0({ target, targetY });

  const x = left0 + TRANSPORT_BTN_OFFSETS.X;

  const y = top0 + TRANSPORT_BTN_OFFSETS.Y;

  return { x, y };
};
