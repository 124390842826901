import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { findLCATransportUpdates } from 'services/api/lca/transport';

type Params = {
  acv_id: string;
  node_id: string;
};

export const useFindLCATransportUpdates = ({ acv_id, node_id }: Params) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(true);

  const { mutateAsync } = useMutation({
    mutationKey: ['lca-transport-updates', acv_id, node_id],
    mutationFn: findLCATransportUpdates,
    onSuccess: (hasNew) => {
      if (!hasNew) {
        return;
      }

      queryClient.invalidateQueries({ queryKey: ['lca-transport-details', acv_id, node_id] });
    }
  });

  useEffect(() => {
    setIsLoading(true);
    mutateAsync({ acv_id, node_id }).finally(() => {
      setIsLoading(false);
    });
  }, [mutateAsync, acv_id, node_id]);

  return { isLoading };
};
