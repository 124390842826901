const adaptReferenceOutput = (entry: ReferenceOutput): ReferenceOutputFront => {
  return {
    name: entry.name ?? '',
    unit_id: entry.unit_id ?? '',
    value: entry.value?.toString() ?? ''
  };
};

const adaptProcessMetadata = (entry: LCAProcessMetadata): LCAProcessMetadataFront => {
  return {
    node_id: entry.node_id,
    entity_id: entry.entity_id,
    name: entry.name,
    reference_output: adaptReferenceOutput(entry.reference_output ?? {}),
    input_types: entry.input_types,
    country: entry.country
  };
};

const adaptMaterialMetadata = (entry: LCAMaterialMetadata): LCAMaterialMetadataFront => {
  return {
    node_id: entry.node_id,
    name: entry.name ?? '',
    entity_id: entry.entity_id ?? []
  };
};

const adaptBlockMetadata = <T extends { node_id: string }, Y extends { node_id: string }>(
  adapter: (block: T) => Y,
  blockMetadata?: T[]
) => {
  if (!blockMetadata) {
    return {};
  }

  return blockMetadata.reduce((acc, block) => {
    acc[block.node_id] = adapter(block);

    return acc;
  }, {} as Record<string, Y>);
};

export const adaptLCAMetadata = (response: LCAMetadataResponse) => {
  return {
    material: adaptBlockMetadata(adaptMaterialMetadata, response.material),
    process: adaptBlockMetadata(adaptProcessMetadata, response.process)
  };
};

export const adaptLCATransportMetadata = (response: LCATransportMetadata) => {
  return {
    node_id: response.node_id,
    name: response.name ?? '',
    entity_id: response.entity_id ?? [],
    matched_materials: response.matched_materials ?? 0,
    unmatched_materials: response.unmatched_materials ?? 0
  };
};
