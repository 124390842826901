import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

type EvidenceLabelProps = {
  onChangeFiles: (files: File[]) => void;
};

export const EvidenceLabel = ({ onChangeFiles }: EvidenceLabelProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esgMetrics.general' });
  const hiddenFileInput = useRef<any>(null);

  // Programatically click the hidden file input element when the Button component is clicked
  const handleClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      onChangeFiles(target.files.length > 0 ? Array.from(target.files) : []);
    }
  };

  return (
    <div className='flex gap-1'>
      <span>{t('textAreaModalLabelWithEvidence1')}</span>
      <div>
        <button className='input-label-font highlight-text-color underline' onClick={handleClick}>
          {t('textAreaModalLabelWithEvidence2')}
        </button>
        <input
          ref={hiddenFileInput}
          type='file'
          onChange={handleChange}
          style={{ display: 'none' }}
          multiple
        />
      </div>
      <span>{t('textAreaModalLabelWithEvidence3')}</span>
    </div>
  );
};
