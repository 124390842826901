import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loadUser } from 'actions/auth';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useDispatch } from 'react-redux';
import { createOrUpdateOrganization } from 'services/api/holdings';
import { authParseLoginCallback } from 'services/authService';
import type { GetHoldingBlocksQueryKey, OrganizationBlockBack } from 'types/entities/holdings';
import { Session } from 'types/entities/user';

export const useCreateOrUpdateOrganization = () => {
  const queryClient = useQueryClient();
  const org = useSelectedOrganization();
  const dispatch = useDispatch();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: createOrUpdateOrganization,
    onSuccess: async (data, params) => {
      queryClient.setQueryData<OrganizationBlockBack[], GetHoldingBlocksQueryKey>(
        ['holding-blocks', params.canvas_organization_id, 'connections', org?.id],
        (oldData) => {
          return oldData?.map((block) => {
            if (block.node_id === data.node_id) {
              return {
                ...block,
                ...data
              };
            }

            return block;
          });
        }
      );

      queryClient.invalidateQueries({
        queryKey: ['holding-connections', params.canvas_organization_id, org?.id]
      });

      queryClient.invalidateQueries({
        queryKey: ['get-contract-limits', params.canvas_organization_id, org?.id]
      });

      const sessionData: Session = await authParseLoginCallback();

      dispatch(loadUser(sessionData, false, false, params.canvas_organization_id));
    }
  });

  return { createOrUpdateOrganization: mutateAsync, isMutating: isPending };
};
