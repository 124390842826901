import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { SIZE_PAGINATION_SMALL } from '../../../../../constants';
import useDebounceValue from '../../../../../customHooks/useDebounceValue';
import { getCompaniesFromFundPaginated } from '../../../../../services/api/funds';
import { FundDetailType } from '../../../../../types/entities/groupFunds';
import { InputSize } from '../../../../../types/utilsEnums/input';
import formatNumber from '../../../../../utils/formatNumber';
import getCountryName from '../../../../../utils/getCountryName';
import { numberToDecimalNonZero } from '../../../../../utils/numberToDecimal';
import PaiReportDataForm from '../../../../communicate/paiReport/paiReportDataForm/PaiReportDataForm';
import Breadcrumb from '../../../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../../../layout/mainPageLayout/MainPageLayout';
import Button from '../../../../ui/button/Button';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import Icon from '../../../../ui/icon/Icon';
import InfiniteList from '../../../../ui/infiniteList/InfiniteListV2';
import useFetchInfiniteList from '../../../../ui/infiniteList/hooks/useFetchInfiniteList';
import Modal from '../../../../ui/modal/Modal';
import TotalLegend from '../../../../ui/totalLegend/TotalLegend';
import useColumns from './hooks/useColumns';
import LinkCompany from './linkCompany/LinkCompany';
import ModalNoFunds from './modalNoFunds/ModalNoFunds';
import ReportRequested from './reportRequested/ReportRequested';
import useFilters from '../../../../layout/Filters/hooks/useFilters';
import { FilterOptionType } from '../../../../layout/Filters/FilterSection';
import { getLanguage } from 'utils/lang';

const FundDetail = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();

  const [url, setUrl] = useState(`/funds/list_by_fund/${id}`);
  const [sideFilters, setFilters] = useState<FilterOptionType[]>([]);

  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showNoFunds, setShowNoFunds] = useState(false);
  const [showLinkCompany, setShowLinkCompany] = useState(false);
  const [showReportRequested, setShowReportRequested] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const debouncedValue = useDebounceValue(searchValue);

  const handleDownloadReport = () => {
    if (companies.length === 0) {
      setShowNoFunds(true);
      return;
    }
    setShowDownloadModal(true);
  };

  const onCloseModal = () => {
    setShowDownloadModal(false);
    setShowNoFunds(false);
  };

  const parseData = (companies: FundDetailType[]) =>
    companies
      .filter((company) =>
        debouncedValue !== '' ? company.company_name.includes(debouncedValue) : true
      )
      .map((company) => ({
        ...company,
        country: getCountryName(company.country, getLanguage(i18n)),
        enterprise_value: `${formatNumber(+company.enterprise_value)} €`,
        investment_value: `${formatNumber(+company.investment_value)} €`,
        debt_financing: company.debt_financing ? t('yesNoOptions.yes') : t('yesNoOptions.no'),
        investment_date: moment(company.investment_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        tag: company.tag ? (
          <div
            className={'text-tag body1-font tag-bg-text-color  corner-position'}
            style={{ padding: '0.25rem', width: 'fit-content', borderRadius: '4px' }}>
            {company.tag}
          </div>
        ) : (
          '-'
        ),
        co2e: (
          <span className='highlight-text-color' style={{ fontWeight: 600 }}>{`${formatNumber(
            numberToDecimalNonZero(+company.co2e / 1000, 4)
          )} t`}</span>
        )
      }));

  const fetch = async (page: number): Promise<Pagination<FundDetailType> | undefined> => {
    if (!id) return;

    const urlSearchParams = new URLSearchParams(url.split('?')[1]);
    const filterBy = urlSearchParams.get('filter_by');
    const sortBy = urlSearchParams.get('sort_by');

    const response = await getCompaniesFromFundPaginated(
      id,
      page,
      SIZE_PAGINATION_SMALL,
      filterBy,
      sortBy
    );
    if (response?.response?.status >= 400) return;
    return response;
  };

  const {
    fetchData,
    firstLoading,
    loading: loadingTable,
    total,
    data: companies,
    addElement
  } = useFetchInfiniteList<FundDetailType>(fetch, [url]);

  useFilters({
    setUrl,
    url,
    setFilters,
    filters: sideFilters,
    removeFilters: () => {
      console.log('remove');
    },
    remove: false,
    // dates,
    // setDates,
    // setClearDates,
    searchFilters: [
      {
        key: 'company_name',
        value: debouncedValue,
        setValue: setSearchValue
      }
    ]
  });

  const handleAddCompanyToFund = (elem: FundDetailType) => {
    addElement(elem);
    setShowLinkCompany(false);
  };

  const columns = useColumns();

  return (
    <>
      <MainPageLayout
        sectionTitle={t('fundDetail.title')}
        title={t('fundDetail.start')}
        description={<span>{t('fundDetail.startDescription')}</span>}
        breadcrumb={
          <Breadcrumb
            crumbsReplace={id ? [{ key: id, value: searchParams.get('name') ?? '' }] : []}
          />
        }
        buttons={
          <>
            <Button
              lookAndFeel={'primary'}
              text={
                <span className='flex' style={{ alignItems: 'center', gap: '0.25rem' }}>
                  <Icon icon='add' color={'white'} />
                  {t('fundDetail.linkCompany')}
                </span>
              }
              size='small'
              onClick={() => setShowLinkCompany(true)}
            />
            <Button
              lookAndFeel={'secondary'}
              onClick={handleDownloadReport}
              text={
                <span className='flex' style={{ alignItems: 'center', gap: '0.25rem' }}>
                  <Icon icon={'download'} color={'gray-dark'} />
                  {t('fundDetail.download')}
                </span>
              }
              size='small'
            />
          </>
        }>
        <InfiniteList
          header={
            <div
              className='flex'
              style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <div>
                <FormText
                  icon={'/images/icons/search.svg'}
                  placeholder={t('facilities.searchPlaceholder')}
                  onChange={(e: any) => setSearchValue(e.target.value)}
                  value={searchValue}
                  size={InputSize.SMALL}
                />
              </div>
              <TotalLegend total={total} i18key='fundDetail' loading={false} />
            </div>
          }
          fetchData={fetchData}
          columns={columns}
          data={parseData(companies)}
          loading={loadingTable}
          firstLoading={firstLoading}
        />
        <Modal show={showDownloadModal} onClose={onCloseModal} width='584px' maxWidth='584px'>
          <PaiReportDataForm
            closeModal={onCloseModal}
            fundDefault={{
              id: id ?? '',
              name: searchParams.get('name') ?? ''
            }}
          />
        </Modal>
        <Modal show={showNoFunds} onClose={onCloseModal} width='428px' maxWidth='428px'>
          <ModalNoFunds closeModal={onCloseModal} />
        </Modal>
        <Modal
          show={showLinkCompany}
          onClose={() => setShowLinkCompany(false)}
          width='600px'
          maxWidth='600px'>
          <LinkCompany
            onSubmit={handleAddCompanyToFund}
            fundId={id ?? ''}
            onClose={() => setShowLinkCompany(false)}
          />
        </Modal>
        <Modal
          show={showReportRequested}
          onClose={() => setShowReportRequested(false)}
          width='500px'
          maxWidth='500px'>
          <ReportRequested closeModal={() => setShowReportRequested(false)} />
        </Modal>
      </MainPageLayout>
    </>
  );
};

export default FundDetail;
