import { useCallback, useEffect, useMemo, useState } from 'react';
import { NodeChange, applyNodeChanges } from '@xyflow/react';
import type { HoldingNode, OrganizationBlock } from 'types/entities/holdings';
import { buildNodes, buildNodesFromBlocks } from '../utils';

type Params = {
  organizationBlocks: OrganizationBlock[];
  isLoading: boolean;
};

export const useHoldingNodes = ({ organizationBlocks, isLoading }: Params) => {
  const [nodes, setNodes] = useState<HoldingNode[]>([]);

  useEffect(() => {
    if (isLoading) return;

    setNodes(buildNodesFromBlocks(organizationBlocks));
  }, [organizationBlocks, isLoading]);

  const onNodesChange = useCallback((changes: NodeChange[]) => {
    setNodes((prevNodes) => {
      return buildNodes(applyNodeChanges(changes, prevNodes));
    });
  }, []);

  const nodesMap = useMemo(
    () =>
      nodes.reduce((acc, node) => {
        acc.set(node.id, node);
        return acc;
      }, new Map<string, HoldingNode>()),
    [nodes]
  );

  return [nodes, setNodes, onNodesChange, nodesMap] as const;
};
