import { Connection, Edge } from '@xyflow/react';
import type { HoldingNode } from 'types/entities/holdings';

const getInvalidNodes = (nodesMap: Map<string, HoldingNode>, source: string) => {
  const sourceNode = nodesMap.get(source);

  if (!sourceNode) {
    return [source];
  }

  if (sourceNode.data.inputs.length === 0) {
    return [source];
  }

  const accumulator: string[] = [
    source,
    ...sourceNode.data.inputs.flatMap((input) => getInvalidNodes(nodesMap, input))
  ];

  return accumulator;
};

export const validateConnection =
  (nodesMap: Map<string, HoldingNode>) => (edge: Edge | Connection) => {
    const target = nodesMap.get(edge.target);
    const source = nodesMap.get(edge.source);

    if (!target || !source) {
      return false;
    }

    const invalidNodes = getInvalidNodes(nodesMap, source.id).concat(source.data.outputs);

    return !invalidNodes.includes(target.id);
  };
