import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardCTA from '../../../ui/cards/cardCTA/CardCTA';
import Button from '../../../ui/button/Button';
import useColumns from './hooks/useColumns';
import './styles.scss';
import TotalLegendV2 from '../../../ui/totalLegend/TotalLegendV2';
import {
  IBackendLinkedCompany,
  IFrontendParentCompany,
  IGetBackendParentCompany
} from '../../../../types/entities/organization';
import Icon from '../../../ui/icon/Icon';
import ButtonDropdown from '../../../ui/buttonDropdown/ButtonDropdown';
import getCountryName from '../../../../utils/getCountryName';
import Label from '../../../ui/label/Label';
import SuccessLabel from '../../../ui/statusLabels/successLabel/SuccessLabel';
import PendingLabel from '../../../ui/statusLabels/pendingLabel/PendingLabel';
import Modal from '../../../ui/modal/Modal';
import LinkCompany from './linkCompany/LinkCompany';
import useFetchInfiniteList from '../../../ui/infiniteList/hooks/useFetchInfiniteList';
import { getMatricesParents } from '../../../../services/api/matrices';
import { SIZE_PAGINATION_SMALL } from '../../../../constants';
import {
  adaptMatricesParentsBackToFront,
  adaptParentLinkedCompanyBackToFront
} from '../../../../adapters/matrices';
import TooltipWrapper from '../../../ui/tooltip/TooltipWrapper';
import GiveAccess from './giveAccess/GiveAccess';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import RemoveAccess from './removeAccess/RemoveAccess';
import InfiniteList from '../../../ui/infiniteList/InfiniteListV2';
import DeletePending from '../groupFunds/companiesGroupFunds/deletePending/DeletePending';
import { UserContext } from '../../../../context/userContext';
import { getLanguage } from 'utils/lang';

const ParentCompanies = () => {
  const { t, i18n } = useTranslation();
  const [showLinkCompany, setShowLinkCompany] = useState(false);
  const [companyToGiveAccess, setCompanyToGiveAccess] = useState<IFrontendParentCompany>();
  const [companyToRemoveAccess, setCompanyToRemoveAccess] = useState<IFrontendParentCompany>();
  const [companyToUnlink, setCompanyToUnlink] = useState<IFrontendParentCompany>();

  const user = useContext(UserContext);

  const dispatch = useDispatch();

  const columns = useColumns();
  // const { parentCompanies: data, setParentCompanies: setData } = useFetchParentCompanies();

  const fetch = async (page: number) => {
    const response = await getMatricesParents(page, SIZE_PAGINATION_SMALL);
    if (response?.response?.status >= 400) return;

    const itemsAdapted = response.items.map((item: IGetBackendParentCompany) =>
      adaptMatricesParentsBackToFront(item)
    );
    return {
      ...response,
      items: itemsAdapted
    };
  };

  const { data, total, addElement, editElement, removeElement, fetchData, loading } =
    useFetchInfiniteList(fetch, []);

  const parseData = (data: IFrontendParentCompany[]) => {
    return data.map((company) => {
      const dropdownOptions = [
        {
          id: company.id + '-delete',
          name: t('parentCompanies.unlink'),
          onClick: () => setCompanyToUnlink(company)
        }
      ];

      if (company.isGroupFund && company.role === 'parent' && company.status !== 'pending') {
        dropdownOptions.unshift({
          id: company.id + '-link',
          name: t('parentCompanies.giveAccess'),
          onClick: () => setCompanyToGiveAccess(company)
        });
      }

      if (company.isGroupFund && company.role === 'fund') {
        dropdownOptions.unshift({
          id: company.id + '-remove',
          name: t('parentCompanies.removeAccess'),
          onClick: () => setCompanyToRemoveAccess(company)
        });
      }
      return {
        key: company.id,
        name: company.name,
        country: getCountryName(company.country, getLanguage(i18n)),
        type: (
          <Label lookAndFeel={company.isGroupFund ? 'primary' : 'secondary'}>
            {t(`parentCompanies.${company.isGroupFund ? 'fund' : 'company'}`)}
          </Label>
        ),
        status:
          company.status === 'accepted' ? (
            <SuccessLabel>{t('parentCompanies.linked')}</SuccessLabel>
          ) : (
            <TooltipWrapper
              text={t('parentCompanies.pendingTooltip', {
                replace: {
                  companyName: company.name
                }
              })}>
              <PendingLabel>{t('parentCompanies.pending')}</PendingLabel>
            </TooltipWrapper>
          ),
        edit: (
          <ButtonDropdown
            button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
            options={dropdownOptions}
          />
        )
      };
    });
  };

  const handleLinkCompany = (company: IBackendLinkedCompany) => {
    // add to the list the company
    addElement(adaptParentLinkedCompanyBackToFront(company));
    setShowLinkCompany(false);
  };

  const handleGiveAccess = (company: IFrontendParentCompany) => {
    setCompanyToGiveAccess(undefined);
    dispatch(
      setNotification(t('notification.accessGivenFund', { replace: { name: company.name } }))
    );
    editElement({ ...company, role: 'fund' });
  };

  const handleRemoveAccess = (company: IFrontendParentCompany) => {
    setCompanyToRemoveAccess(undefined);
    dispatch(
      setNotification(t('notification.accessRemovedFund', { replace: { name: company.name } }))
    );
    editElement({ ...company, role: 'parent' });
  };

  const handleRemoveCompany = (id: string) => {
    setCompanyToUnlink(undefined);
    dispatch(setNotification(t('notification.companyUnlinked')));
    removeElement(id);
  };

  return (
    <div className='parent-companies'>
      <CardCTA>
        <CardCTA.Header>
          <span className='headline4-font'>{t('parentCompanies.title')}</span>
          <span className='subtitle3-font'>{t('parentCompanies.description')}</span>
        </CardCTA.Header>
        <CardCTA.Buttons>
          <Button
            lookAndFeel='primary'
            text={t('parentCompanies.link')}
            onClick={() => setShowLinkCompany(true)}
            size='small'
          />
        </CardCTA.Buttons>
      </CardCTA>

      <InfiniteList
        header={
          <TotalLegendV2
            totalLabel='Total'
            totalElements={[{ value: total, label: t('parentCompanies.companies') }]}
            style={{ marginLeft: 'auto' }}
          />
        }
        data={parseData(data)}
        columns={columns}
        loading={loading}
        firstLoading={loading && data.length === 0}
        fetchData={fetchData}
      />
      <Modal.WithPortal show={showLinkCompany} onClose={() => setShowLinkCompany(false)}>
        <LinkCompany handleLinkCompany={handleLinkCompany} />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!companyToGiveAccess}
        onClose={() => setCompanyToGiveAccess(undefined)}
        maxWidth='584px'
        width='584px'>
        {companyToGiveAccess && (
          <GiveAccess
            company={companyToGiveAccess}
            handleClose={() => setCompanyToGiveAccess(undefined)}
            handleOnSubmit={handleGiveAccess}
          />
        )}
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!companyToRemoveAccess}
        onClose={() => setCompanyToRemoveAccess(undefined)}
        maxWidth='584px'
        width='584px'>
        {companyToRemoveAccess && (
          <RemoveAccess
            company={companyToRemoveAccess}
            handleClose={() => setCompanyToRemoveAccess(undefined)}
            handleOnSubmit={handleRemoveAccess}
          />
        )}
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!companyToUnlink}
        maxWidth='500px'
        width='500px'
        onClose={() => setCompanyToUnlink(undefined)}>
        {companyToUnlink && (
          <DeletePending
            parentId={companyToUnlink.id}
            childId={user?.selectedOrganization ?? ''}
            idToRemove={companyToUnlink.id}
            nameToRemove={companyToUnlink.name}
            deleteRequest={handleRemoveCompany}
            handleClose={() => setCompanyToUnlink(undefined)}
          />
        )}
      </Modal.WithPortal>
    </div>
  );
};

export default ParentCompanies;
