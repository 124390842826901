import { useNuvoCountries } from 'customHooks/useNuvoCountries';
import { ColumnAPI } from 'nuvo-react';
import { PURCHASE_FRAMEWORK_OPTIONS, PurchaseFramework } from 'utils/mapToPurchaseFramework';
import { useAdminFrameworkCodes } from './useAdminFrameworkCodes';
import { mergeArrays } from 'utils/arrays';

export const useColumns = () => {
  const { data: sicCodes } = useAdminFrameworkCodes({ framework: PurchaseFramework.SIC });
  const { data: cnaeCodes } = useAdminFrameworkCodes({ framework: PurchaseFramework.CNAE });
  const { countriesNuvo } = useNuvoCountries();

  const unitedCodes = mergeArrays(sicCodes, cnaeCodes, 'id');

  const codeOptions = unitedCodes.map((item) => {
    const code_type: PurchaseFramework[] = [];

    if (sicCodes.find((code) => code.id === item.id)) {
      code_type.push(PurchaseFramework.SIC);
    }

    if (cnaeCodes.find((code) => code.id === item.id)) {
      code_type.push(PurchaseFramework.CNAE);
    }

    return {
      label: item.name,
      value: item.id,
      type: 'string' as const,
      validations: [
        {
          validate: {
            EQ: { code_type }
          },
          errorMessage: 'Code must be from the selected framework'
        }
      ]
    };
  });

  const columns: ColumnAPI[] = [
    {
      key: 'name',
      label: 'Name',
      columnType: 'string',
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'country',
      label: 'Country',
      columnType: 'category',
      dropdownOptions: countriesNuvo,
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'code',
      label: 'Code',
      columnType: 'category',
      dropdownOptions: codeOptions,
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'code_type',
      label: 'Code Type',
      columnType: 'category',
      dropdownOptions: PURCHASE_FRAMEWORK_OPTIONS.map((item) => ({
        label: item.name,
        value: item.id,
        type: 'string'
      })),
      validations: [
        {
          validate: 'required'
        }
      ]
    }
  ];

  return { columns };
};
