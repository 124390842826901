import React from 'react';
import { useTranslation } from 'react-i18next';

const useToggleOptions = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'waste'
  });
  const toggleOptions = [
    {
      id: 'excel',
      name: t('uploadExcel')
    },
    {
      id: 'pdf',
      name: t('uploadPDF')
    }
  ];
  return toggleOptions;
};

export default useToggleOptions;
