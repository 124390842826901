export const sectors = [
  {
    id: 'administrative',
    title: {
      es: 'Administración auxiliar',
      en: 'Auxiliary administration',
      pt: 'Administração auxiliar',
      ca: 'Administració auxiliar'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'artistic',
    title: {
      es: 'Arte, cultura y entretenimiento',
      en: 'Art, culture and entertainment',
      pt: 'Arte, cultura e entretenimento',
      ca: 'Art, cultura i entreteniment'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'domestic',
    title: {
      es: 'Actividades domésticas',
      en: 'Domestic activities',
      pt: 'Atividades domésticas',
      ca: 'Activitats domèstiques'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'extraterritorial',
    title: {
      es: 'Extraterritorial',
      en: 'Extraterritorial',
      pt: 'Extraterritorial',
      ca: 'Extraterritorial'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'finance',
    title: {
      es: 'Finanzas y seguros',
      en: 'Finance and insurance',
      pt: 'Finanças e seguros',
      ca: 'Finances i assegurances'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'real_estate',
    title: {
      es: 'Venta y alquiler de inmuebles',
      en: 'Sale and rental of real estate',
      pt: 'Venda e aluguel de imóveis',
      ca: 'Venda i lloguer d’immobles'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'arquitecture',
    title: {
      es: 'Arquitectura',
      en: 'Architecture',
      pt: 'Arquitetura',
      ca: 'Arquitectura'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'scientific',
    title: {
      es: 'Ciencia y tecnología',
      en: 'Science and technology',
      pt: 'Ciência e tecnologia',
      ca: 'Ciència i tecnologia'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'sanitary',
    title: {
      es: 'Sanidad',
      en: 'Sanitary',
      pt: 'Sanidade',
      ca: 'Sanitat'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'public_administration',
    title: {
      es: 'Administración pública y defensa',
      en: 'Public administration and defense',
      pt: 'Administração pública e defesa',
      ca: 'Administració pública i defensa'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'agriculture',
    title: {
      es: 'Agricultura, ganadería, silvicultura y pesca',
      en: 'Agriculture, farming, forestry and fishing',
      pt: 'Agricultura, pecuária, silvicultura e pesca',
      ca: 'Agricultura, ramaderia, silvicultura i pesca'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'wholesale',
    title: {
      es: 'Comercio al por mayor',
      en: 'Wholesale trade',
      pt: 'Comércio atacadista',
      ca: 'Comerç al detall'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'trade_textile',
    title: {
      es: 'Comercio de textil y moda',
      en: 'Textile and fashion trade',
      pt: 'Comércio de têxteis e moda',
      ca: 'Comerç de tèxtil i moda'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'trade_food',
    title: {
      es: 'Comercio de alimentos y nutrición',
      en: 'Food and nutrition trade',
      pt: 'Comércio de alimentos e nutrição',
      ca: 'Comerç d’aliments i nutrició'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'trade_cosmetics',
    title: {
      es: 'Comercio de cosmética y cuidado personal',
      en: 'Cosmetics and personal care trade',
      pt: 'Comércio de cosméticos e cuidados pessoais',
      ca: 'Comerç de cosmètica i cura personal'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'trade_furniture',
    title: {
      es: 'Comercio de muebles y hogar',
      en: 'Furniture and home trade',
      pt: 'Comércio de móveis e casa',
      ca: 'Comerç de mobles i llar'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'trade_cleaning',
    title: {
      es: 'Comercio de productos de limpieza',
      en: 'Cleaning products trade',
      pt: 'Comércio de produtos de limpeza',
      ca: 'Comerç de productes de neteja'
    }
  },
  {
    id: 'construction',
    title: {
      es: 'Construcción y reformas',
      en: 'Construction and renovations',
      pt: 'Construção e reformas',
      ca: 'Construcció i reformes'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'education',
    title: {
      es: 'Educación',
      en: 'Education',
      pt: 'Educação',
      ca: 'Educació'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'hostelry',
    title: {
      es: 'Hostelería',
      en: 'Hostelry',
      pt: 'Hotelaria',
      ca: 'Hostaleria'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'manufacture',
    title: {
      es: 'Industria manufacturera',
      en: 'Manufacturing industry',
      pt: 'Indústria de manufatura',
      ca: 'Indústria manufacturera'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'manufacture_textile',
    title: {
      es: 'Producción textil y moda',
      en: 'Textile and fashion production',
      pt: 'Produção têxtil e moda',
      ca: 'Producció tèxtil i moda'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'manufacture_food',
    title: {
      es: 'Producción de alimenticia',
      en: 'Food production',
      pt: 'Produção de alimentos',
      ca: 'Producció d’alimentació'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'manufacture_cosmetics',
    title: {
      es: 'Producción cosmética ',
      en: 'Cosmetics production',
      pt: 'Produção cosmética',
      ca: 'Producció cosmètica'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'manufacture_furniture',
    title: {
      es: 'Producción de muebles y hogar',
      en: 'Furniture and home production',
      pt: 'Produção de móveis e casa',
      ca: 'Producció de mobles i llar'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'manufacture_cleaning',
    title: {
      es: 'Producción de productos de limpieza',
      en: 'Cleaning products production',
      pt: 'Produção de produtos de limpeza',
      ca: 'Producció de productes de neteja'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'extractive',
    title: {
      es: 'Industrias extractivas',
      en: 'Extractive industries',
      pt: 'Indústrias extrativas',
      ca: 'Indústries extractives'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'information_communications',
    title: {
      es: 'Información y comunicaciones',
      en: 'Information and communications',
      pt: 'Informação e comunicações',
      ca: 'Informació i comunicacions'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'marketing',
    title: {
      es: 'Marketing y publicidad',
      en: 'Marketing and advertising',
      pt: 'Marketing e publicidade',
      ca: 'Màrqueting i publicitat'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'other',
    title: {
      es: 'Otros servicios',
      en: 'Other services',
      pt: 'Outros serviços',
      ca: 'Altres serveis'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'water_waste_supply',
    title: {
      es: 'Suministro de agua, actividades de saneamiento, gestión de residuos y descontaminación',
      en: 'Water supply, activities of sanitation, management of waste and disinfection',
      pt: 'Fornecimento de água, atividades de saneamento, gestão de resíduos e desinfecção',
      ca: 'Subministrament d’aigua, activitats de sanejament, gestió de residus i desinfecció'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'electricity_gas_supply',
    title: {
      es: 'Suministro de energía eléctrica, gas, vapor y aire acondicionado',
      en: 'Electricity supply, gas, vapor and air conditioning',
      pt: 'Fornecimento de eletricidade, gás, vapor e ar condicionado',
      ca: 'Subministrament d’energia elèctrica, gas, vapor i aire condicionat'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  },
  {
    id: 'transport',
    title: {
      es: 'Transporte y almacenamiento',
      en: 'Transportation and storage',
      pt: 'Transporte e armazenamento',
      ca: 'Transport i emmagatzematge'
    },
    description: {
      en: '',
      es: '',
      pt: '',
      ca: ''
    }
  }
];

export const WATER_WASTE_SUPPLY_SECTOR = 'water_waste_supply';
