import { BaseEdge, Position, getSmoothStepPath } from '@xyflow/react';
import { useTop0 } from './useTop0';
import { useIsHighlighted } from '../EdgeHighlightContext';
import { REM } from 'constants/measures';

type Props = {
  id: string;
  source: string;
  sourceX: number;
  sourceY: number;
  sourcePosition: Position;
  targetX: number;
  targetY: number;
  target: string;
  targetPosition: Position;
  markerEnd?: string;
};

export const HighlightableEdge: React.FC<Props> = ({
  id,
  source,
  sourceX,
  sourceY,
  targetX,
  targetY,
  target,
  targetPosition,
  sourcePosition,
  markerEnd
}) => {
  const { top0 } = useTop0({ target, targetY });

  const isHighlighted = useIsHighlighted(source);

  const [edgePath] = getSmoothStepPath({
    sourceX: sourceX - 4,
    sourceY,
    targetX,
    targetY: top0 + 2 * REM,
    targetPosition,
    sourcePosition,
    borderRadius: 8
  });

  const classNames = [];

  if (isHighlighted) {
    classNames.push('stroke-secondary-purple-20 stroke-width-2px');
  }

  const marker = isHighlighted ? 'url(#highlighted-marker)' : markerEnd;

  return <BaseEdge id={id} path={edgePath} markerEnd={marker} className={classNames.join(' ')} />;
};
