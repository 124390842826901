import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import interactiveTutorial from './interactiveTutorial';
import notification from './notification';
import { purchasesLoader } from './purchasesLoader';

const rootReducer = combineReducers({
  notification,
  auth,
  interactiveTutorial,
  purchasesLoader
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
