import { createContext, useContext } from 'react';
import { DraggableProcess } from '../DraggableItems/DraggableProcess';
import { DraggableMaterial } from '../DraggableItems/DraggableMaterial';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import { MaterialsMassiveUpload } from '../MassiveUpload/Materials/MaterialsMassiveUpload';
import Icon from 'components/ui/icon/Icon';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import { useModalContext } from '../ModalHandler';
import { useACVId } from '../../hooks/useACVId';
import { useLCAProcess } from '../../hooks/useLCAProcess';
import { DraggableTransport } from '../DraggableItems/DraggableTransport';

type CreateNodeContextType = {
  target?: string;
  setTarget: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const CreateNodeContext = createContext<CreateNodeContextType | null>(null);

type ProviderProps = {
  target?: string;
  setTarget: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const CreateNodeProvider: React.FC<ProviderProps> = ({ children, target, setTarget }) => {
  return (
    <CreateNodeContext.Provider value={{ target, setTarget }}>
      {children}
    </CreateNodeContext.Provider>
  );
};

export const useCreateNodeContext = () => {
  const context = useContext(CreateNodeContext);
  if (context === null) {
    throw new Error('useCreateNodeContext must be used within a CreateNodeProvider');
  }

  return context;
};

const SectionTitle: React.FC = ({ children }) => <p className='m-0 font-body-b2-sb'>{children}</p>;
const SectionDescription: React.FC = ({ children }) => (
  <TooltipWrapper
    text={children}
    position='right'
    style={{ display: 'grid', placeItems: 'center' }}>
    <Icon icon='information' color='pending' size='medium' />
  </TooltipWrapper>
);
const SectionInfoLayout: React.FC = ({ children }) => (
  <div className='flex gap-x-1 items-center'>{children}</div>
);

type PropsTypeIcon = {
  target?: string;
};

const TypeIcon: React.FC<PropsTypeIcon> = ({ target }) => {
  if (!target) {
    return null;
  }

  return <Icon icon='connect' color='pending' size='large' className='border-box p-1' />;
};

export const CreateNode = () => {
  const { t } = useLCATranslation();
  const { acv_id } = useACVId();
  const { modalData } = useModalContext();

  const metadata = useLCAProcess({ acv_id, node_id: modalData?.target ?? '' });

  const title = modalData?.target ? metadata?.name : t('modules.modalTitleEmpty');

  const preTitleKey = modalData?.target
    ? 'modules.modalPreTitleFilled'
    : 'modules.modalPreTitleEmpty';

  return (
    <div className='flex-col gap-y-8'>
      <div className='flex-col gap-y-1'>
        <div className='flex-col gap-y-1'>
          <TypeIcon target={modalData?.target} />
          <p
            className={`m-0 ${
              modalData?.target ? 'text-secondary-purple-20' : 'text-neutral-gray-warm-20'
            } font-body-b2-sb`}>
            {t(preTitleKey)}
          </p>
          <p className='m-0 font-headings-h4-sb__dep text-neutral-gray-cold-20'>{title}</p>
        </div>
      </div>
      <div className='flex-col gap-y-4'>
        <SectionInfoLayout>
          <SectionTitle>{t('modules.title')}</SectionTitle>
          <SectionDescription>{t('modules.description')}</SectionDescription>
        </SectionInfoLayout>
        <div className='flex-col gap-y-2'>
          <DraggableProcess />
          <DraggableMaterial />
          {!modalData?.target && <DraggableTransport />}
        </div>
      </div>
      <div className='flex-col gap-y-4'>
        <SectionInfoLayout>
          <SectionTitle>{t('modules.actionsTitle')}</SectionTitle>
          <SectionDescription>{t('modules.actionsDescription')}</SectionDescription>
        </SectionInfoLayout>
        <MaterialsMassiveUpload />
      </div>
    </div>
  );
};

CreateNode.Provider = CreateNodeProvider;
