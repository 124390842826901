import { forwardRef } from 'react';

type Props = {
  children: React.ReactNode;
  title?: string;
  disabled?: boolean;
};

export const BlueTag = forwardRef<HTMLSpanElement, Props>(
  ({ children, title, disabled, ...rest }, ref) => {
    if (disabled) {
      return (
        <span
          className='text-neutral-gray-20 bg-neutral-gray-warm-60 font-12 weight-400 p-1 w-fit-content m-0 rounded-4 nodrag nopan'
          title={title}
          ref={ref}
          {...rest}>
          {children}
        </span>
      );
    }

    return (
      <span
        className='text-blue-tag bg-blue-tag font-12 weight-400 p-1 w-fit-content m-0 rounded-4 nodrag nopan'
        title={title}
        ref={ref}
        {...rest}>
        {children}
      </span>
    );
  }
);

BlueTag.displayName = 'BlueTag';
