import { isInNarrowedArray } from 'utils/arrays';
import { LCAOutputs } from '../types/LCABlock';
import { CustomEdge } from './components/CustomEdges/CustomEdge';
import { HighlightableEdge } from './components/CustomEdges/HighlightableEdge';
import { MaterialNode } from './components/CustomNodes/MaterialNode';
import { PieceNode } from './components/CustomNodes/PieceNode';
import { ProcessNode } from './components/CustomNodes/ProcessNode';
import { TransportNode } from './components/CustomNodes/TransportNode';
import { NODE_TYPES } from './constants';
import { ScenarioNodeType } from './types/LCAScenarios';

export const NODE_COMPONENTS = {
  [NODE_TYPES.material]: MaterialNode,
  [NODE_TYPES.process]: ProcessNode,
  [NODE_TYPES.piece]: PieceNode,
  [NODE_TYPES.product]: PieceNode,
  [NODE_TYPES.transport]: TransportNode
};

export const EDGE_TYPES = {
  lcaEdge: 'lcaEdge',
  materialProcess: 'materialProcess'
} as const;

export const EDGE_COMPONENTS = {
  [EDGE_TYPES.materialProcess]: HighlightableEdge,
  [EDGE_TYPES.lcaEdge]: CustomEdge
};

const HAS_TRANSPORT = [NODE_TYPES.process, NODE_TYPES.material] as const;

export const assignEdgeType = (blockType: ScenarioNodeType, outputs: LCAOutputs[]) => {
  if (!outputs.find((output) => output.type === NODE_TYPES.process)) {
    return EDGE_TYPES.lcaEdge;
  }

  if (isInNarrowedArray(blockType, HAS_TRANSPORT)) {
    return EDGE_TYPES.materialProcess;
  }

  return EDGE_TYPES.lcaEdge;
};
