import { InputHTMLAttributes } from 'react';
import { InputSize } from '../../../../types/utilsEnums/input';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: InputSize;
}

const InputEmail = ({ maxLength = 100, size = InputSize.MEDIUM, className, ...props }: Props) => {
  const { t } = useTranslation();
  const sizeClassName = {
    [InputSize.MEDIUM]: 'input-small-font',
    [InputSize.SMALL]: 'input-smaller-font',
    [InputSize.LARGE]: 'font-14'
  };
  const newClassName = `input-text ${sizeClassName[size]} basic-input ` + className;
  const placeholder = props.placeholder || t('input.placeholder');

  return (
    <>
      <input
        className={newClassName}
        maxLength={maxLength}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && props.onSubmit) props.onSubmit(e);
        }}
        {...props}
        type='email'
      />{' '}
    </>
  );
};

export default InputEmail;
