import CompaniesGroupFunds from './groupFunds/companiesGroupFunds/CompaniesGroupFunds';
import CompaniesHolding from './holding/companiesHolding/CompaniesHolding';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import CompaniesWrapper from './common/companiesWrapper/CompaniesWrapper';
import { useSearchParams } from 'react-router-dom';
import ParentCompanies from './parentCompanies/ParentCompanies';
import { HoldingDiagram } from './Diagram/HoldingDiagram';
import { DEFAULT_STEP, STEP_IDS } from './constants';
import { UsedEntitiesLegend } from './Diagram/components/UsedEntitiesLegend';
import { useContractParent } from './Diagram/hooks/useContractParent';
import LoaderStandard from 'components/ui/loaders/loaderStandard/LoaderStandard';
import { useHoldingTranslations } from 'customHooks/translations';

const Companies = () => {
  const { t } = useHoldingTranslations();
  const foundOrganization = useSelectedOrganization();

  const {
    data: contractOrgId,
    isLoading,
    isLoadingError
  } = useContractParent(foundOrganization?.id, {
    retry: false,
    retryOnMount: false
  });

  const [searchParams] = useSearchParams({ type: DEFAULT_STEP });

  if (foundOrganization?.is_group_fund) {
    return (
      <CompaniesWrapper>
        <CompaniesGroupFunds />
      </CompaniesWrapper>
    );
  }

  if (!isLoadingError && isLoading) {
    return (
      <section className='w-full h-full flex justify-center gap-x-8 items-center'>
        <LoaderStandard />
        {t('loadingContract')}
      </section>
    );
  }

  if (searchParams.get('type') === STEP_IDS.DIAGRAM) {
    return (
      <section className='w-full h-full flex flex-col gap-y-4'>
        <CompaniesWrapper.Base>
          <div className='flex gap-x-4 justify-between'>
            <CompaniesWrapper.Steps />
            {contractOrgId === foundOrganization?.id && <UsedEntitiesLegend />}
          </div>
          <HoldingDiagram />
        </CompaniesWrapper.Base>
      </section>
    );
  }

  return (
    <CompaniesWrapper>
      {searchParams.get('type') === STEP_IDS.PARENTS && <ParentCompanies />}
      {searchParams.get('type') === STEP_IDS.CHILDS && <CompaniesHolding />}
    </CompaniesWrapper>
  );
};

export default Companies;
