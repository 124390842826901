import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useEntitiesUsed } from '../../hooks/useEntitiesUsed';
import { useContractParent } from '../../hooks/useContractParent';
import { useHoldingTranslations } from 'customHooks/translations';
import { Legend } from './Legend';
import Icon from 'components/ui/icon/Icon';
import { ICON_MAP } from '../../constants';

export const UsedEntitiesLegend = () => {
  const { t } = useHoldingTranslations();

  const org = useSelectedOrganization();

  const organization_id = org?.id;

  const { data: canvas_org_id } = useContractParent(organization_id);

  const { data, isLoading } = useEntitiesUsed({ canvas_org_id, organization_id });

  if (isLoading) {
    return null;
  }

  return (
    <div className='flex gap-x-2 items-center'>
      <span className='font-12 weight-600 inter text-neutral-black'>
        {t('usedEntitiesLegend.totalCreated')}
      </span>
      {data?.map((entity) => {
        return (
          <Legend.Layout key={entity.limit}>
            <Icon icon={ICON_MAP[entity.limit]} color='gradient' />
            <Legend.Text used={entity.totalUsed} total={entity.total} />
          </Legend.Layout>
        );
      })}
    </div>
  );
};
