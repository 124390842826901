import type { QueryClient } from '@tanstack/react-query';
import { LCABlockBack } from 'components/lca/types/LCABlock';
import { NODE_TYPES } from '../constants';

type Params = {
  org_id?: string;
  acv_id: string;
  target_id?: string;
  this_node_id?: string;
};

export const findAndInvalidateTransport = (
  queryClient: QueryClient,
  { org_id, acv_id, target_id, this_node_id }: Params
) => {
  if (!target_id && !this_node_id) {
    throw new Error('Either target_id or this_node_id must be provided');
  }

  const blocks = queryClient.getQueryData(['lcaBlocks', org_id, acv_id]) as
    | LCABlockBack[]
    | undefined;

  if (this_node_id) {
    const thisNode = blocks?.find((block) => block.id === this_node_id);

    const target = thisNode?.outputs?.find((output) => {
      return output.entity_type === NODE_TYPES.transport;
    });

    if (target) {
      queryClient.invalidateQueries({ queryKey: ['lca-transport', acv_id, target.id] });
      queryClient.invalidateQueries({ queryKey: ['lca-transport-details', acv_id, target.id] });
    }

    return;
  }

  const target = blocks?.find((block) => block.id === target_id);

  if (target?.entity_type === NODE_TYPES.transport) {
    queryClient.invalidateQueries({ queryKey: ['lca-transport', acv_id, target_id] });
    queryClient.invalidateQueries({ queryKey: ['lca-transport-details', acv_id, target_id] });
  }
};
