export const MEASURE_ROUTES = {
  MEASURE: '/measure',
  MEASURE_FACILITIES: '/measure/facilities',
  MEASURE_VEHICLES: '/measure/vehicles',
  MEASURE_LOGISTICS: '/measure/logistics',
  MEASURE_EVENTS: '/measure/events',
  MEASURE_ACTIVITY_EMPLOYEES: '/measure/employees',
  MEASURE_ACTIVITY_WASTE: '/measure/waste',
  MEASURE_ACTIVITY_BUSINESS_TRAVELS: '/measure/travels',
  MEASURE_ACTIVITY_PURCHASES: '/measure/purchases',
  MEASURE_ACTIVITY_SOCIAL_AND_GOVERNANCE: '/measure/social-and-governance',
  MEASURE_FUNDS: '/measure/funds',
  MEASURE_DATADIS: '/measure/facilities/datadis',
  MEASURE_HUBS: '/measure/facilities/hubs',
  MEASURE_UPLOADED_FILES: '/measure/uploaded-files',
  MEASURE_ACTIVITY_USE_AND_END_OF_LIFE: '/measure/use-and-end-of-life',
  MEASURE_ESG_METRICS: '/measure/esg-metrics',
  MEASURE_ESG_METRICS_INFOGRAPHY: '/measure/esg-metrics/infography',
  MEASURE_ACTIVITY_SHIPMENTS: '/measure/shipments',
  MEASURE_COMPANIES: '/measure/companies'
};
