import { zodResolver } from '@hookform/resolvers/zod';
import InputNumber from 'components/ui/formComponents2/inputNumber/InputNumber';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import { useErrorTranslations, useHoldingTranslations } from 'customHooks/translations';
import { adaptHookFormError } from 'lib/ReactHookForm/adaptError';
import { useFormWithHelpers } from 'lib/ReactHookForm/useFormWithHelpers';
import type { SubmitErrorHandler, SubmitHandler } from 'react-hook-form';
import type { ShareFormValues } from 'types/entities/holdings';
import { SHARE_FORM_ID } from '../../constants';
import Button from 'components/ui/button/Button';

type Props = {
  zodSchema: Parameters<typeof zodResolver>[0];
  onSubmit: SubmitHandler<ShareFormValues>;
  onInvalid?: SubmitErrorHandler<ShareFormValues>;
  defaultValues: ShareFormValues;
};

export const ShareForm = ({ defaultValues, zodSchema, onSubmit, onInvalid }: Props) => {
  const { registerNumber, handleSubmit, formState } = useFormWithHelpers({
    defaultValues,
    resolver: zodResolver(zodSchema)
  });

  const { t } = useHoldingTranslations();
  const { t: tError } = useErrorTranslations();

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalid)} id={SHARE_FORM_ID}>
      <InputWrapper
        iconV2='input_numeric'
        className='w-fit-content'
        tooltip={t('shareForm.shareTooltip')}
        label={t('shareForm.shareLabel')}
        error={adaptHookFormError(formState.errors.share, tError)}>
        <InputNumber.WithPercentageOverlay
          {...registerNumber('share')}
          placeholder={t('shareForm.sharePlaceholder')}
        />
      </InputWrapper>
    </form>
  );
};

type SubmitBTNProps = {
  loading?: boolean;
};

const SubmitBTN: React.FC<SubmitBTNProps> = ({ loading }) => {
  const { t } = useHoldingTranslations();

  return (
    <Button
      lookAndFeel='primary'
      size='medium'
      form={SHARE_FORM_ID}
      loading={loading}
      type='submit'
      text={t('shareForm.submit')}
    />
  );
};

ShareForm.SubmitBTN = SubmitBTN;
