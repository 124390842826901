import { FieldPath, FieldPathValue, FieldValues, useForm, UseFormProps } from 'react-hook-form';

type Params<TFieldValues extends FieldValues = FieldValues, TContext = any> = UseFormProps<
  TFieldValues,
  TContext
>;

export const useFormWithHelpers = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  params: Params<TFieldValues, TContext>
) => {
  const { register, watch, setValue, ...rest } = useForm(params);

  const onSelectionChange =
    <TFieldName extends FieldPath<TFieldValues>>(key: TFieldName) =>
    ({ id }: SelectOptionFormat) => {
      setValue(key, id as FieldPathValue<TFieldValues, TFieldName>);
    };

  const getSelectValue = <TFieldName extends FieldPath<TFieldValues>>(
    key: TFieldName,
    options: SelectOptionFormat[]
  ) => {
    const value = watch(key);

    return options.find((option) => option.id === value) ?? { id: '', name: '' };
  };

  type RegisterNumberOptions = {
    max?: number;
    min?: number;
  };

  const registerNumber = <TFieldName extends FieldPath<TFieldValues>>(
    name: TFieldName,
    { max, min }: RegisterNumberOptions = {}
  ) => {
    const handleChange = (value: string) => {
      setValue(name, value as FieldPathValue<TFieldValues, TFieldName>);
    };

    return { ...register(name), onChange: handleChange, value: watch(name), max, min };
  };

  return { register, registerNumber, watch, setValue, onSelectionChange, getSelectValue, ...rest };
};
