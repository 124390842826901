import { STEPS_SOCIAL } from 'components/measure/esgMetrics/constants';
import Icon from 'components/ui/icon/Icon';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import { useIsOwner } from 'customHooks/useIsOwner';
import { useTranslation } from 'react-i18next';

type Props = {
  category: string;
};
const useSteps = ({ category }: Props) => {
  const { t } = useTranslation();
  const { isOwner, isLoading } = useIsOwner(category === 'social');

  const stepsEnvironmental = [
    {
      id: 'climate_change',
      text: 'esgMetrics.environmental.climate_change',
      isSelected: true
    },
    {
      id: 'pollution',
      text: 'esgMetrics.environmental.pollution',
      isSelected: false
    },
    {
      id: 'water_and_marine_resources',
      text: 'esgMetrics.environmental.water_and_marine_resources',
      isSelected: false
    },
    {
      id: 'biodiversity_and_ecosystems',
      text: 'esgMetrics.environmental.biodiversity_and_ecosystems',
      isSelected: false
    },
    {
      id: 'resource_use_and_circular_economy',
      text: 'esgMetrics.environmental.resources_and_circular_economy',
      isSelected: false
    }
  ];

  const stepsSocial = [
    {
      id: STEPS_SOCIAL.WORKERS_IN_THE_VALUE_CHAIN,
      text: t('esgMetrics.social.workers_in_the_value_chain'),
      isSelected: false
    },

    {
      id: STEPS_SOCIAL.CONSUMERS_AND_END_USERS,
      text: t('esgMetrics.social.consumers_and_end-users'),
      isSelected: false
    },

    {
      id: STEPS_SOCIAL.AFFECTED_COMMUNITIES,
      text: t('esgMetrics.social.affected_communities'),
      isSelected: false
    },
    {
      id: STEPS_SOCIAL.OWN_WORKFORCE,
      text: isOwner ? (
        t('esgMetrics.social.own_workforce')
      ) : (
        <TooltipWrapper asChild text={t('esgMetrics.social.own_workforce_disabledTooltip')}>
          <span className='flex items-center gap-x-1'>
            {t('esgMetrics.social.own_workforce')}
            <Icon icon='lock' color='disabled' size='small' />
          </span>
        </TooltipWrapper>
      ),
      isSelected: false,
      disabled: !isOwner
    }
  ];

  switch (category) {
    case 'environmental':
      return { steps: stepsEnvironmental, isLoading };
    case 'social':
      return { steps: stepsSocial, isLoading };
    default:
      return { steps: [], isLoading };
  }
};

export default useSteps;
