import { ReactElement } from 'react';
import Icon from '../../icon/Icon';
import Label from '../../label/Label';
import TooltipWrapper from '../../tooltip/TooltipWrapper';
import '../styles.scss';
import { IconColor } from '../../IconBase/IconBase';

type Props = {
  text: string | ReactElement;
  tooltip?: string;
  scoreData?: 'A' | 'B' | 'C';
  style?: React.CSSProperties;
};

export const StarLabel = ({ text, tooltip, scoreData, style }: Props) => {
  let lookAndFeel = 'secondary';
  let iconColor = 'blocked';

  if (scoreData === 'A') {
    lookAndFeel = 'success';
    iconColor = 'success';
  }

  if (scoreData === 'C') {
    lookAndFeel = 'error';
    iconColor = 'error';
  }

  const labelContent = (
    <Label
      style={{
        marginLeft: '1rem',
        height: '1.5rem',
        ...style
      }}
      lookAndFeel={lookAndFeel as any}>
      <Icon icon='multi_star' color={iconColor as IconColor} className='m-right-star-icon' />
      <span className='weight-500'>{text}</span>
    </Label>
  );

  return (
    <div className='flex'>
      {tooltip ? (
        <TooltipWrapper text={tooltip} position='top' size='small'>
          {labelContent}
        </TooltipWrapper>
      ) : (
        labelContent
      )}
    </div>
  );
};
