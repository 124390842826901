import { GrayBox } from 'components/ui/GrayBox/GrayBox';
import Modal from 'components/ui/modal/Modal';
import { modalStyles } from './constants';
import { EmptyState } from './EmptyState';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import Button from 'components/ui/button/Button';
import { useTransportDetails } from '../../hooks/useTransportDetails';
import { useACVId } from '../../hooks/useACVId';
import { useModalContext } from '../ModalHandler';
import { useFindLCATransportUpdates } from '../../hooks/useFindLCATransportUpdates';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TransportDetail, transportDetailSchema } from './schema';
import { useEffect } from 'react';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import { adaptHookFormError } from 'lib/ReactHookForm/adaptError';
import { useErrorTranslations } from 'customHooks/translations/useErrorTranslations';
import { useUpdateLCATransportDetails } from '../../hooks/useUpdateLCATransportDetails';
import { adaptLCATransportForm } from 'adapters/adaptLCATransport';
import { Table } from './Table';
import LoaderTables from 'components/ui/loaders/loaderTables/LoaderTables';

type Props = {
  onClose: () => void;
};

export const TransportConfigurationModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useLCATranslation();
  const { t: tError } = useErrorTranslations();
  const { acv_id } = useACVId();
  const { modalData } = useModalContext();

  const node_id = modalData?.target ?? '';

  const { isLoading: isPending } = useFindLCATransportUpdates({ acv_id, node_id });
  const { data, isLoading } = useTransportDetails({ acv_id, node_id, enabled: !isPending });

  const { updateLCATransportDetails, isUpdating } = useUpdateLCATransportDetails();

  const { reset, register, handleSubmit, formState, watch, setValue } = useForm({
    defaultValues: {
      name: data?.name ?? '',
      transports: data?.transports ?? []
    },
    resolver: zodResolver(transportDetailSchema)
  });

  const btnLoading = isPending || isUpdating || isLoading;

  const onSubmit = async (data: TransportDetail) => {
    if (btnLoading) {
      return;
    }

    await updateLCATransportDetails(adaptLCATransportForm({ acv_id, node_id, data }));

    onClose();
  };

  useEffect(() => {
    reset({
      name: data?.name ?? '',
      transports: data?.transports ?? []
    });
  }, [JSON.stringify(data)]);

  return (
    <Modal.WithPortal show onClose={onClose} width='880px' maxWidth='880px'>
      <Modal.Header title={t('transport_configuration.title')} />
      <Modal.Content style={modalStyles}>
        {(isLoading || isPending) && (
          <GrayBox.Padless className='h-full grid grid-cols-1 place-items-center'>
            <LoaderTables mode='fit' />
          </GrayBox.Padless>
        )}
        {!isLoading && !isPending && (
          <form
            id='transport-detail-form'
            className='flex flex-col gap-y-4 h-full'
            onSubmit={handleSubmit(onSubmit)}>
            <GrayBox.Padless className='grid grid-cols-3 p-4'>
              <InputWrapper
                iconV2='shirt'
                label={t('transport_configuration.name_label')}
                error={adaptHookFormError(formState.errors.name, tError)}>
                <InputText
                  {...register('name')}
                  placeholder={t('transport_configuration.name_placeholder')}
                />
              </InputWrapper>
            </GrayBox.Padless>
            <GrayBox.Padless className='flex-grow p-4'>
              {!watch('transports').length && <EmptyState />}
              {watch('transports') && watch('transports').length > 0 && (
                <Table.Layout>
                  <Table.Headers />
                  {watch('transports').map((row, index) => (
                    <Table.RowWrapper key={row.impact_source_id + index}>
                      <Table.Description row={row} />
                      <Table.Distance
                        error={adaptHookFormError(
                          formState.errors.transports?.[index]?.value,
                          tError
                        )}
                        value={watch(`transports.${index}.value`)}
                        onChange={(value) => {
                          setValue(`transports.${index}.value`, value);
                        }}
                      />
                      <Table.TOC
                        error={adaptHookFormError(
                          formState.errors.transports?.[index]?.impact_source_id,
                          tError
                        )}
                        impact_source_id={watch(`transports.${index}.impact_source_id`)}
                        onChange={(value) => {
                          setValue(`transports.${index}.impact_source_id`, value.id);
                        }}
                      />
                    </Table.RowWrapper>
                  ))}
                </Table.Layout>
              )}
            </GrayBox.Padless>
          </form>
        )}
      </Modal.Content>
      <Modal.Buttons>
        <Button lookAndFeel='secondary' onClick={onClose} size='medium'>
          {t('transport_configuration.cancel')}
        </Button>
        <Button
          lookAndFeel='primary'
          type='submit'
          form='transport-detail-form'
          size='medium'
          disabled={btnLoading}
          loading={btnLoading}>
          {t('transport_configuration.save')}
        </Button>
      </Modal.Buttons>
    </Modal.WithPortal>
  );
};
