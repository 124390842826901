import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../actions/notification';
import useYesNoOptions from '../../../customHooks/useYesNoOptions';

import apiFetch from '../../../utils/apiFetch';
import exportAsImage from '../../../utils/exportAsImage';
import getBase64 from '../../../utils/getBase64';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import Button from '../../ui/button/Button';
import FormElementFull from '../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../ui/formComponents/inputFile/InputFileSingle';
import InputNumber from '../../ui/formComponents/inputNumber/InputNumber';
import InputText from '../../ui/formComponents/inputText/InputText';
import Select from '../../ui/formComponents/select/Select';
import CategorySelected from './CategorySelected';
import DownloadCard from './DownloadCard';
import SnippetPreview from './SnippetPreview';
import './styles.scss';

type HTMLFileSnippet = {
  code: string;
  html: string;
  name: string;
};
const SnippetUpload = () => {
  const { t } = useTranslation();

  const yesNoOptions = useYesNoOptions();

  const [file, setFile] = useState<File | null>(null);
  const [categoriesSelected, setCategoriesSelected] = useState<SelectOptionFormat[]>([
    {
      id: 'climate_change_global_warming_potential_(gwp1000)',
      name: t(`snippetUpload.climate_change_global_warming_potential_(gwp1000)`)
    },
    {
      id: 'eutrophication',
      name: t(`snippetUpload.eutrophication`)
    },
    {
      id: 'water_use_water_consumption_potential_(wcp)',
      name: t(`snippetUpload.water_use_water_consumption_potential_(wcp)`)
    }
  ]);
  const [htmlFiles, setHtmlFiles] = useState<HTMLFileSnippet[]>([]);
  const [precision, setPrecision] = useState('3');
  const [barColor, setBarColor] = useState('');
  const [lang, setLang] = useState<SelectOptionFormat>({
    id: 'es',
    name: t('languages.es')
  });
  const [lcaName, setLcaName] = useState('');
  const [font, setFont] = useState({
    id: 'Inter',
    name: 'Inter'
  });
  const [comparison, setComparison] = useState<SelectOptionFormat>(yesNoOptions[0]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = useState<ErrorType[]>([]);

  const dispatch = useDispatch();

  const handleFileUpload = (fileUploaded: File) => {
    const newErrors = errors.filter((error) => error.error !== 'file');
    setErrors(newErrors);
    setFile(fileUploaded);
  };

  const handleGenerateSnippets = () => {
    const newErrors = [];
    if (!file) {
      newErrors.push({ error: 'file' });
    }
    if (categoriesSelected.length === 0) {
      newErrors.push({ error: 'categories' });
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }
    setLoadingButton(true);
    if (file) {
      getBase64(file, (file_url: string | null | ArrayBuffer) => {
        if (file_url && file_url) {
          apiFetch('POST', '/snippets/generate', {
            file: file_url,
            categories: categoriesSelected.map((category) => category.id),
            precision: parseInt(precision, 10),
            lang: lang.id,
            bar_color: barColor || null,
            showComparison: comparison.id === 'yes'
          })
            .then((res) => {
              dispatch(setNotification(t('notification.snippetsGenerated')));
              setHtmlFiles(
                res.data.map((snippet: HTMLFileSnippet) => ({
                  ...snippet,
                  name: snippet.name.replaceAll('.', '')
                }))
              );
              setLoadingButton(false);
            })
            .catch((err) => {
              dispatch(setNotification(t('notification.snippetsGeneratedError')));
              setLoadingButton(false);
            });
        }
      });
    }
    setLoadingButton(false);
  };

  const onSelectCategory = (selected: SelectOptionFormat) => {
    const newErrors = errors.filter((error) => error.error !== 'categories');
    setErrors(newErrors);
    if (
      categoriesSelected.length >= 5 ||
      categoriesSelected.find((elem) => elem.id === selected.id)
    )
      return;
    setCategoriesSelected([...categoriesSelected, selected]);
  };

  const removeCategory = (id: string) => {
    const newCategories = categoriesSelected.filter((category) => category.id !== id);
    setCategoriesSelected(newCategories);
  };

  const onChangePrecision = (value: string) => {
    const min = 1;
    const max = 8;
    if (parseInt(value, 10) < min) return;
    if (parseInt(value, 10) > max) return;
    setPrecision(value);
  };

  const onChangeBarColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBarColor(e.target.value);
  };

  const onChangeLang = (selected: SelectOptionFormat) => {
    setLang(selected);
  };

  const onChangeLcaName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLcaName(e.target.value);
  };

  const onChangeFont = (selected: SelectOptionFormat) => {
    setFont(selected);
  };

  const onChangeComparison = (selected: SelectOptionFormat) => {
    setComparison(selected);
  };

  const downloadAll = async () => {
    try {
      // Get all elements with className .export
      const elements = document.getElementsByClassName('export');
      const elementsToDownload = Array.from(elements);

      let width = 3840;
      let height = 2460;

      if (comparison.id !== 'yes') {
        width = 932;
        height = 800;
      }

      elementsToDownload.forEach((element, index) => {
        // Get property data-name from the element
        const name = element.getAttribute('data-name') || '';
        // Find the htmlFile with the same name
        const htmlFile = htmlFiles.find((elem) => elem.name === name);
        if (!htmlFile) return;
        const codeFirstLetterUppercase =
          htmlFile.code.charAt(0).toUpperCase() + htmlFile.code.slice(1);

        setTimeout(async () => {
          await exportAsImage(
            element,
            `${lcaName}_${codeFirstLetterUppercase}_${lang.id.toUpperCase()}_${name}_snippet`,
            width,
            height
          );
        }, 1000);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const categories = [
    'acidification_accumulated_exceedance_(ae)',
    'climate_change_global_warming_potential_(gwp1000)',
    'ecotoxicity',
    'energy_resources_non-renewable_abiotic_depletion_potential_(adp)_fossil_fuels',
    'eutrophication',
    'human_toxicity_carcinogenic',
    'human_toxicity_non-carcinogenic',
    'ionising_radiation_ionising_radiation_potential_(irp)',
    'land_use_agricultural_land_occupation_(lop)',
    'material_resources_metals_minerals_abiotic_depletion_potential_(adp)_elements',
    'ozone_depletion_ozone_depletion_potential_(odpinfinite)',
    'particulate_matter_formation_particulate_matter_formation_potential_(pmfp)',
    'photochemical_oxidant_formation_human_health_photochemical_oxidant_formation_potential_humans_(hofp)',
    'photochemical_oxidant_formation_terrestrial_ecosystems_photochemical_oxidant_formation_potential_ecosystems_(eofp)',
    'water_use_water_consumption_potential_(wcp)'
  ];

  const categoriesParsed: SelectOptionFormat[] = categories.map((category) => ({
    id: category,
    name: t(`snippetUpload.${category}`)
  }));

  return (
    <div className='snippet-upload'>
      <SectionHeader title={t('snippetUpload.title')} subtitle={'Files with MAX 20 products'} />
      <div className='snippet-upload-wrapper'>
        <div className='snippet-upload-form main-bg-color solid-border'>
          <InputFileSingle
            handleFile={handleFileUpload}
            buttonText={t('snippetUpload.upload')}
            labelText={t('snippetUpload.uploadLabel')}
            fileName={file?.name || ''}
            allowedTypes={['text/csv']}
            error={errors.find((error) => error.error === 'file')}
          />
          <FormWrapper>
            <InputText
              icon={'/images/icons/stacked50.svg'}
              label={t('snippetUpload.lcaNameLabel')}
              placeholder={t('snippetUpload.lcaName')}
              value={lcaName}
              onChangeValue={onChangeLcaName}
            />
            <InputNumber
              icon={'/images/icons/ruler.svg'}
              label={t('snippetUpload.precisionLabel')}
              placeholder={t('snippetUpload.precision')}
              value={precision}
              onChangeValue={onChangePrecision}
            />
            <InputText
              icon={'/images/icons/thermometer.svg'}
              label={t('snippetUpload.percentageBarColorLabel')}
              placeholder={t('snippetUpload.percentageBarColor')}
              value={barColor}
              onChangeValue={onChangeBarColor}
            />
            <Select
              icon='/images/icons/globe.svg'
              placeholder={t('snippetUpload.selectLanguage')}
              label={t('snippetUpload.language')}
              options={[
                { id: 'en', name: t('languages.en') },
                { id: 'es', name: t('languages.es') },
                { id: 'ca', name: t('languages.ca') },
                { id: 'fr', name: t('languages.fr') },
                { id: 'de', name: t('languages.de') },
                { id: 'pt', name: t('languages.pt') }
              ]}
              value={lang}
              onChangeValue={onChangeLang}
            />
            <Select
              icon='/images/icons/catalog.svg'
              placeholder={t('snippetUpload.font')}
              label={t('snippetUpload.font')}
              options={[
                { id: 'Inter', name: 'Inter' },
                { id: 'Montserrat', name: 'Montserrat' },
                { id: 'Roboto', name: 'Roboto' }
              ]}
              value={font}
              onChangeValue={onChangeFont}
            />
            <Select
              icon='/images/icons/stacked50.svg'
              placeholder={t('snippetUpload.comparison')}
              label={t('snippetUpload.comparison')}
              options={yesNoOptions}
              value={comparison}
              onChangeValue={onChangeComparison}
            />
            <FormElementFull>
              <Select
                icon='/images/icons/catalog.svg'
                placeholder={t('snippetUpload.selectCategories')}
                label={t('snippetUpload.categories')}
                options={categoriesParsed.filter(
                  (category) => !categoriesSelected.find((elem) => elem.id === category.id)
                )}
                value={{
                  id: '',
                  name: ''
                }}
                onChangeValue={onSelectCategory}
                error={errors.find((error) => error.error === 'categories')}
              />
            </FormElementFull>
          </FormWrapper>
          <div className='categories-selected'>
            {categoriesSelected.map((category) => (
              <CategorySelected
                key={category.id}
                category={category}
                removeCategory={removeCategory}
              />
            ))}
          </div>
          <div className='button-wrapper'>
            <Button
              lookAndFeel={'primary'}
              text={t('snippetUpload.generateSnippets')}
              onClick={handleGenerateSnippets}
              loading={loadingButton}
            />
            {htmlFiles.length > 0 && (
              <Button
                lookAndFeel={'primary'}
                text={t('snippetUpload.downloadAll')}
                onClick={downloadAll}
              />
            )}
          </div>
        </div>

        <div className='snippet-upload-html-files-list'>
          {htmlFiles.map((htmlFile, index) => {
            return (
              <DownloadCard
                htmlFile={htmlFile.html}
                key={index}
                code={htmlFile.code}
                name={htmlFile.name}
                lcaName={lcaName}
                lang={lang.id}
                font={font.id}
                showComparison={comparison.id === 'yes'}
              />
            );
          })}
        </div>
      </div>
      <div
        className='snippets-wrapper'
        style={{ width: comparison.id === 'yes' ? '1000px' : '400px' }}>
        {htmlFiles.slice(0, 20).map((htmlFile, index) => {
          const htmlParsed = htmlFile.html
            .replaceAll('Inter', font.id)
            .replaceAll('Roboto', font.id);
          // .replaceAll('https://dev-app.dcycle.io', 'http://localhost:3000');
          const codeFirstLetterUppercase =
            htmlFile.code.charAt(0).toUpperCase() + htmlFile.code.slice(1);
          return (
            <SnippetPreview
              key={index}
              htmlContent={htmlParsed ?? ''}
              showComparison={comparison.id === 'yes'}
              lcaName={lcaName}
              codeFirstLetterUppercase={codeFirstLetterUppercase}
              lang={lang.id}
              name={htmlFile.name}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SnippetUpload;
