import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotificationTranslation } from 'customHooks/translations';
import { useNotification } from 'customHooks/useNotification';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { createOrganizationBlock } from 'services/api/holdings';
import {
  GetHoldingBlocksQueryKey,
  GetHoldingConnectionsQueryKey,
  HoldingConnectionBack,
  OrganizationBlockBack
} from 'types/entities/holdings';

export const useCreateOrganizationBlock = () => {
  const queryClient = useQueryClient();
  const org = useSelectedOrganization();
  const { notify } = useNotification();
  const { t } = useNotificationTranslation();

  const { mutate, isPending } = useMutation({
    mutationFn: createOrganizationBlock,
    onSuccess: (data, params) => {
      queryClient.setQueryData<OrganizationBlockBack[], GetHoldingBlocksQueryKey>(
        ['holding-blocks', data.canvas_organization_id, 'connections', org?.id],
        (oldData) => {
          const newBlock = {
            node_id: data.id,
            org_name: null,
            country: null,
            sector: null,
            org_id: data.organization_id,
            cif: null,
            inputs: data.inputs,
            outputs: data.outputs,
            coordinates_x: data.coordinates_x,
            coordinates_y: data.coordinates_y,
            active_facilities: null,
            active_vehicles: null,
            active_employees: null,
            limit_facilities: null,
            limit_vehicles: null,
            limit_employees: null
          };

          if (!oldData) {
            return [newBlock];
          }

          return oldData
            .map((block) => {
              if (data.inputs?.includes(block.node_id)) {
                return {
                  ...block,
                  outputs: block.outputs ? [...block.outputs, data.id] : [data.id]
                };
              }

              if (data.outputs?.includes(block.node_id)) {
                return {
                  ...block,
                  inputs: block.inputs ? [...block.inputs, data.id] : [data.id]
                };
              }

              return block;
            })
            .concat(newBlock);
        }
      );

      queryClient.setQueryData<HoldingConnectionBack[], GetHoldingConnectionsQueryKey>(
        ['holding-connections', data.canvas_organization_id, org?.id],
        (oldData) => {
          const newConnection = {
            source_id: params.source_id,
            target_id: data.id,
            share: null
          };

          if (!oldData) {
            return [newConnection];
          }

          return [...oldData, newConnection];
        }
      );

      notify(t('organizationBlockCreate.success'));
    }
  });

  return { createOrganizationBlock: mutate, isPending };
};
