import { useQuery } from '@tanstack/react-query';
import { adapterOrganizationBlocks } from 'adapters/adaptHoldingBlocks';
import { getOrganizationBlocks } from 'services/api/holdings';

type Params = {
  contractOrgId?: string;
  organizationId?: string;
};

export const useOrganizationBlocks = ({ contractOrgId, organizationId }: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: ['holding-blocks', contractOrgId, 'connections', organizationId],
    queryFn: getOrganizationBlocks,
    select: adapterOrganizationBlocks,
    enabled: !!contractOrgId
  });

  return { data: data ?? [], isLoading };
};
