import { useTOCTranslations } from 'customHooks/translations/useTOCTranslations';

export const useTransportTOCOptions = () => {
  const { t } = useTOCTranslations();

  return {
    options: [
      {
        id: 'edacf857-09f5-48db-bb00-1b87abef887c',
        name: t('refrigerated aircraft')
      },
      {
        id: 'bd2ab9f4-980e-41d4-b42e-509ca4d7dc18',
        name: t('aircraft')
      },
      {
        id: '86f29bc6-9988-4356-954a-2d01be96f867',
        name: t('van')
      },
      {
        id: '3afcc703-cb26-4145-a1ab-fd675c728526',
        name: t('lorry')
      },
      {
        id: 'e643d278-69d2-401c-b6d0-9ed5f3733a4c',
        name: t('refrigerated lorry')
      },
      {
        id: '646f3ed3-5166-48f8-b4a0-6627ac8b05a3',
        name: t('container ship')
      },
      {
        id: 'db05335e-5e58-4faf-817b-dcf39545f417',
        name: t('refrigerated container ship')
      },
      {
        id: '7709c37f-cf99-4304-80aa-2e25ed52bff4',
        name: t('train')
      },
      {
        id: 'b40aef3a-81fd-4b61-a7fb-914438302710',
        name: t('refrigerated train')
      }
    ]
  };
};
