import { useContext, useEffect, useState } from 'react';
import { getGroups } from '../../../../../../../services/api/customEFOld';
import {
  getFacilityFuels,
  getLastUsedFacilityPercentages,
  getSuppliers
} from '../../../../../../../services/api/invoices';
import { EmissionFactorGroupType } from '../../../../../../../types/entities/emissionFactor';
import { FacilityFuelType } from '../../../../../../../types/entities/facilityFuel';
import useSelectedOrganization from '../../../../../../../customHooks/useSelectedOrganization';
import { UserContext } from '../../../../../../../context/userContext';
import { Organization } from '../../../../../../../types/entities/organization';
import { getMatrixChilds } from '../../../../../../../services/api/matrices';
import { HoldingCompany } from '../../../../../../../types/entities/holdings';
import makeRequestsInBatches from '../../../../../../../utils/makeRequestInBatches';
import { getLowCodes } from 'services/api/waste';
import { useTranslation } from 'react-i18next';

type Props = {
  type: string;
  country: string;
  facilityId: string;
};

type LastUsedFacilityPercentage = {
  organization_id: string;
  facility_id: string;
  percentage: number;
  company_name: string;
  facility_name: string;
};

const useGetData = ({ type, country, facilityId }: Props) => {
  const selectedOrganization = useSelectedOrganization();
  const user = useContext(UserContext);

  const [suppliers, setSuppliers] = useState<SupplierType[]>([]);
  const [facilityFuels, setFacilityFuels] = useState<FacilityFuelType[]>([]);
  const [groups, setGroups] = useState<EmissionFactorGroupType[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<SelectOptionFormat[]>([]);
  const [lastUsedFacilityPercentages, setLastUsedFacilityPercentages] = useState<
    LastUsedFacilityPercentage[]
  >([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    getData();
  }, [type]);

  const getData = async () => {
    if (!type) return;
    setLoadingData(true);
    const requests = [
      () => requestSuppliers(),
      () => requestFacilityFuels(),
      () => requestGroups(),
      () => requestOrganizations(),
      () => requestLastUsedFacilityPercentages()
    ];
    await makeRequestsInBatches(requests);
    setLoadingData(false);
  };

  const requestLastUsedFacilityPercentages = async () => {
    const data = await getLastUsedFacilityPercentages(facilityId, type);

    if (data?.response?.status >= 400) return;

    setLastUsedFacilityPercentages(data);
  };

  const requestSuppliers = async () => {
    const data = await getSuppliers(country);

    if (data?.response?.status >= 400) return;

    // Filter by type and remove suppliers with duplicate names
    const seen = new Set();
    const filteredData: SupplierType[] = data?.filter((elem: SupplierType) => {
      if (elem.type !== type) return false;
      const duplicate = seen.has(elem.name.toLocaleLowerCase());
      seen.add(elem.name.toLocaleLowerCase());
      return !duplicate;
    });

    setSuppliers(filteredData);
  };

  const requestFacilityFuels = async () => {
    const countryToRequest = country === 'ES' || type === 'recharge' ? 'ES' : country;
    const data = await getFacilityFuels(countryToRequest);

    if (data?.response?.status >= 400) return;

    // Filter facility fuels depending on type
    const filteredData = data?.filter((elem: FacilityFuelType) => elem.type === type);

    // Map facility fuels to display in select
    setFacilityFuels(filteredData);
  };

  const requestGroups = async () => {
    const data = await getGroups(selectedOrganization?.id ?? '');

    if (data?.response?.status >= 400) return;
    setGroups(data.items.filter((elem: EmissionFactorGroupType) => elem.category === type));
  };

  const requestOrganizations = async () => {
    const foundOrganization = user?.organizations?.find(
      (elem) => elem.id === user?.selectedOrganization
    );
    if (!foundOrganization || foundOrganization.role === 'fund') return;
    if (foundOrganization.is_group_fund || foundOrganization.role === 'member') {
      setOrganizationOptions([
        {
          id: selectedOrganization?.id ?? '',
          name: selectedOrganization?.company_name ?? ''
        }
      ]);
      return;
    }
    const data: HoldingCompany[] = await getMatrixChilds(selectedOrganization?.id ?? '');

    const newOrganizationOptions = data
      .filter((elem: HoldingCompany) => {
        const foundInUser = user?.organizations?.find(
          (organization: Organization) => organization.id === elem.child_id
        );

        if (!foundInUser) return false;

        return true;
      })
      .map((elem: HoldingCompany) => ({
        id: elem.child_id,
        name: elem.child_name
      }));
    newOrganizationOptions.push({
      id: selectedOrganization?.id ?? '',
      name: selectedOrganization?.company_name ?? ''
    });
    setOrganizationOptions(newOrganizationOptions);
  };

  return {
    suppliers,
    facilityFuels,
    groups,
    loadingData,
    organizationOptions,
    lastUsedFacilityPercentages
  };
};

export default useGetData;
