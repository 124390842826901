import { TFunction } from 'i18next';
export type FiscalPeriodKeys =
  | ''
  | 'janDec'
  | 'febJan'
  | 'marFeb'
  | 'aprMar'
  | 'mayApr'
  | 'junMay'
  | 'julJun'
  | 'augJul'
  | 'sepAug'
  | 'octSep'
  | 'novOct'
  | 'decNov';

export const fiscalPeriodTranslationMapper = (
  t: TFunction
): SelectOptionFormat<FiscalPeriodKeys>[] => {
  const fiscalPeriod = {
    janDec: '',
    febJan: '',
    marFeb: '',
    aprMar: '',
    mayApr: '',
    junMay: '',
    julJun: '',
    augJul: '',
    sepAug: '',
    octSep: '',
    novOct: '',
    decNov: ''
  };
  return Object.keys(fiscalPeriod).map((key) => ({
    id: key as FiscalPeriodKeys,
    name: t(`profile.fiscalPeriod.${key}`) // This dynamically translates each key based on the current language
  }));
};

const FISCAL_PERIODS = {
  janDec: { start_day: 1, start_month: 1, end_day: 31, end_month: 12 },
  febJan: { start_day: 1, start_month: 2, end_day: 31, end_month: 1 },
  marFeb: { start_day: 1, start_month: 3, end_day: 28, end_month: 2 },
  aprMar: { start_day: 1, start_month: 4, end_day: 31, end_month: 3 },
  mayApr: { start_day: 1, start_month: 5, end_day: 30, end_month: 4 },
  junMay: { start_day: 1, start_month: 6, end_day: 31, end_month: 5 },
  julJun: { start_day: 1, start_month: 7, end_day: 30, end_month: 6 },
  augJul: { start_day: 1, start_month: 8, end_day: 31, end_month: 7 },
  sepAug: { start_day: 1, start_month: 9, end_day: 31, end_month: 8 },
  octSep: { start_day: 1, start_month: 10, end_day: 30, end_month: 9 },
  novOct: { start_day: 1, start_month: 11, end_day: 30, end_month: 10 },
  decNov: { start_day: 1, start_month: 12, end_day: 30, end_month: 11 },
  '': { start_day: 0, start_month: 0, end_day: 0, end_month: 0 }
} as const;

export const getEndAmountOfDaysForFiscalYear = (fiscalYear: ReportingPeriod) => {
  return (
    Object.values(FISCAL_PERIODS).find(
      (fiscalPeriod) => fiscalPeriod.end_month === fiscalYear.end_month
    )?.end_day ?? 31
  );
};

export const fiscalPeriodToSelectOption = (fiscalYear: ReportingPeriod): string => {
  // Find the matching FiscalPeriodKeys based on start and end months
  const matchingPeriod = (Object.keys(FISCAL_PERIODS) as FiscalPeriodKeys[]).find(
    (key) =>
      FISCAL_PERIODS[key].start_month === fiscalYear.start_month &&
      FISCAL_PERIODS[key].end_month === fiscalYear.end_month
  );

  if (matchingPeriod) {
    return matchingPeriod;
  }
  return '';
};

export const selectOptionToFiscalPeriod = (id: FiscalPeriodKeys): ReportingPeriod => {
  if (id in FISCAL_PERIODS) {
    return {
      ...FISCAL_PERIODS[id]
    };
  }

  return {
    start_month: 0,
    end_month: 0
  };
};
