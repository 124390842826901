import { QueryFunction } from '@tanstack/react-query';
import apiFetch from 'utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';

export const getContract = async (org_id: string): Promise<ContractBack | null> => {
  const response = await apiFetch('GET', `/holdings/contract/${org_id}`);

  return response.data;
};

export const getContractLimits: QueryFunction<
  ResponseGetContractLimits,
  QueryKeyGetContractLimits
> = async ({ queryKey }) => {
  const [, canvas_org_id] = queryKey;

  const response = await apiFetch(
    'GET',
    getUrl('/holdings/contract/limits', {
      queryParams: generateQueryParamsFromObject({ canvas_org_id })
    })
  );

  return response.data;
};
