import useOnChangeValue from '../../../../../../../../customHooks/useOnChangeValue';
import FormWrapper from '../../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendar from '../../../../../../../ui/formComponents2/formInputs/formCalendar/FormCalendar';
import { FormDataStateESG } from '../ModalDatapoint';
import { EvidenceLabel } from './EvidenceLabel';

type Props = {
  formDataState: FormDataStateESG;
  onChangeFiles: (files: File[]) => void;
};

export const DateComponent = ({ formDataState, onChangeFiles }: Props) => {
  const { formData, setFormData } = formDataState;
  const { onChangeValue } = useOnChangeValue({ setFormData });
  return (
    <FormWrapper>
      <FormCalendar
        label={<EvidenceLabel onChangeFiles={onChangeFiles} />}
        handleChangeDate={onChangeValue('value')}
        dateValue={formData.value as string}
      />
    </FormWrapper>
  );
};
