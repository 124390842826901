import { DEFAULT_Y_GAP, NODE_TYPES } from 'components/measure/companies/Diagram/constants';
import type {
  HoldingNode,
  OrganizationBlock,
  OrganizationBlockBack,
  OrganizationFormValues,
  PayloadCreateOrganizationBlock,
  PayloadCreateOrganizationBlockFront,
  PayloadMoveOrganizationBlock,
  PayloadMoveOrganizationBlockFront
} from 'types/entities/holdings';

const adapterOrganizationBlock = (block: OrganizationBlockBack): OrganizationBlock => {
  return {
    node_id: block.node_id,
    org_id: block.org_id ?? '',
    name: block.org_name ?? '',
    cif: block.cif ?? '',
    country: block.country ?? '',
    sector: block.sector ?? '',
    type: NODE_TYPES.COMPANY,
    inputs: block.inputs ?? [],
    outputs: block.outputs ?? [],
    coordinate_x: block.coordinates_x,
    coordinate_y: block.coordinates_y,
    employees: block.active_employees ?? undefined,
    facilities: block.active_facilities ?? undefined,
    vehicles: block.active_vehicles ?? undefined,
    limit_employees: block.limit_employees ?? undefined,
    limit_facilities: block.limit_facilities ?? undefined,
    limit_vehicles: block.limit_vehicles ?? undefined
  };
};

export const adapterOrganizationBlocks = (blocks: OrganizationBlockBack[]): OrganizationBlock[] => {
  return blocks.map(adapterOrganizationBlock);
};

export const adapterCreateOrganizationBlock = ({
  source_id,
  source_x,
  source_y
}: PayloadCreateOrganizationBlockFront): PayloadCreateOrganizationBlock => {
  return {
    source_id,
    coordinates_x: source_x,
    coordinates_y: source_y + DEFAULT_Y_GAP
  };
};

const adapterMoveOrganizationBlock = (
  node: HoldingNode
): PayloadMoveOrganizationBlock['blocks'][number] => {
  return {
    block_id: node.id,
    coordinates_x: node.position.x,
    coordinates_y: node.position.y
  };
};

export const adapterMoveOrganizationBlocks = ({
  contract_org_id,
  nodes
}: PayloadMoveOrganizationBlockFront): PayloadMoveOrganizationBlock => {
  return {
    contract_org_id,
    blocks: nodes.map(adapterMoveOrganizationBlock)
  };
};

export const adapterHoldingNodeToFormValues = (
  node: HoldingNode | undefined
): OrganizationFormValues => {
  if (!node) {
    return {
      cif: '',
      name: '',
      country: '',
      sector: '',
      limit_employees: '0',
      limit_facilities: '0',
      limit_vehicles: '0'
    };
  }

  return {
    cif: node.data.cif,
    name: node.data.name,
    country: node.data.country,
    sector: node.data.sector,
    limit_employees: node.data.limits.employees?.toString() ?? '',
    limit_facilities: node.data.limits.facilities?.toString() ?? '',
    limit_vehicles: node.data.limits.vehicles?.toString() ?? ''
  };
};
