import type { Organization } from 'types/entities/organization';

export const DEFAULT_CONTRACT = {
  facilities: '0',
  vehicles: '0',
  employees: '0'
};

export const REALLY_BIG_NUMBER = 999_999_999;

export const HAVE_SCOPE_3_DEFAULT_FIELDS = {
  limitBusinessTravels: REALLY_BIG_NUMBER,
  limitShipments: REALLY_BIG_NUMBER,
  limitDeliveries: REALLY_BIG_NUMBER,
  limitPurchases: REALLY_BIG_NUMBER
} as const;

export const DONT_HAVE_SCOPE_3_DEFAULT_FIELDS = {
  limitBusinessTravels: 0,
  limitShipments: 0,
  limitDeliveries: 0,
  limitPurchases: 0
} as const;

export const SCOPE_3_FIELDS = [
  'limit_business_travels',
  'limit_shipments',
  'limit_deliveries',
  'limit_purchases'
] as (keyof Organization)[];
