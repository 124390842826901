import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteMatrixRel } from 'services/api/matrices';

type Params = {
  id: string;
  parent_id: string;
};

export const useUnbindCompany = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: ({ id, parent_id }: Params) => deleteMatrixRel(id, parent_id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['holding-connections'],
        exact: false
      });

      queryClient.invalidateQueries({
        queryKey: ['holding-blocks'],
        exact: false
      });
    }
  });

  return { unbindCompany: mutateAsync };
};
