import { getAllCountries } from '../constants/phonePrefixes';

const getCountryName = (country: string, lang: Lang) => {
  const countries = getAllCountries();
  const countrySelected = countries.find((element) => element.code === country);
  const language = lang || 'en';
  return countrySelected ? countrySelected.name[language] : country;
};

export default getCountryName;
