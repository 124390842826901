import apiFetch from 'utils/apiFetch';

type CreateMaterialBody = {
  name: string;
  description?: string;
  supplier?: string;
  supplier_country: string;
  location?: string;
  type: MaterialType;
};
export const createMaterial = async (body: CreateMaterialBody) => {
  const response = await apiFetch('POST', '/lcas/material/create', body);

  return response.data as LCAMaterial;
};

export const downloadMaterials = async (org_id: string) => {
  const response = await apiFetch('GET', `/lca/download/${org_id}`);

  return response.data as string;
};

export const getPressignedUrlLCAMaterials = async (fileName: string) => {
  const response = await apiFetch('POST', '/lcas/materials/bulk/csv', { file_name: fileName });

  return response.data.upload_url as string;
};

export const getLCAOfficialMaterial = async (id: string): Promise<LCAOfficialMaterial> => {
  const response = await apiFetch('GET', `/lca/materials/${id}`);

  return response.data as LCAOfficialMaterial;
};

type UpdateLCAOfficialMaterialParams = {
  id: string;
  name: string;
  country: string;
  supplier: string;
  description?: string;
  type: MaterialType;
};

export const updateLCAOfficialMaterial = async ({
  id,
  name,
  country,
  supplier,
  description,
  type
}: UpdateLCAOfficialMaterialParams) => {
  const response = await apiFetch('PATCH', `/lca/materials/edit/${id}`, {
    name,
    country,
    description,
    supplier,
    type
  });

  return response.data as LCAOfficialMaterial;
};

export const deleteMaterial = async (id: string) => {
  const response = await apiFetch('DELETE', `/lca/materials/delete/${id}`);

  return response.data as boolean;
};

type MaterialUnit = {
  unit_id: string;
  unit_name: string;
  result_unit_id?: string;
  value: number;
};

type UpdateMaterialUnitsParams = {
  id: string;
  units: Required<MaterialUnit>[];
};

export const updateMaterialUnits = async ({ id, units }: UpdateMaterialUnitsParams) => {
  const response = await apiFetch('POST', `/lca/materials/units/${id}`, units);

  return response.data as { piece_id: string; units: MaterialUnit[] };
};
