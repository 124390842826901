import type {
  OrganizationInvoicingPatchFront,
  OrganizationPatchPayload
} from 'types/entities/organization';

export const adapterPatchOrganization = (
  organizationFront: OrganizationInvoicingPatchFront
): OrganizationPatchPayload => {
  const patchedData: OrganizationPatchPayload = {};

  if (organizationFront.country.id && organizationFront.vat) {
    patchedData.invoicing_country = organizationFront.country.id;
  }

  if (organizationFront.tradeName) {
    patchedData.invoicing_trade_name = organizationFront.tradeName;
  }

  if (organizationFront.vat && organizationFront.country.id) {
    patchedData.vat = organizationFront.vat;
  }

  if (organizationFront.address) {
    patchedData.invoicing_street_address = organizationFront.address;
  }

  if (organizationFront.province) {
    patchedData.invoicing_province = organizationFront.province;
  }

  if (organizationFront.postalCode) {
    patchedData.invoicing_postal_code = organizationFront.postalCode;
  }
  return {
    id: organizationFront.organizationId,
    fiscal_year_start_month: organizationFront.fiscalYear.start_month,
    ...patchedData
  };
};

export const adapterPatchPayloadToInvoiceDataType = (
  payload: OrganizationPatchPayload
): InvoicingDataType => {
  return {
    invoicing_country: payload.invoicing_country ?? '',
    invoicing_postal_code: payload.invoicing_postal_code ?? '',
    invoicing_province: payload.invoicing_province ?? '',
    invoicing_street_address: payload.invoicing_street_address ?? '',
    invoicing_trade_name: payload.invoicing_trade_name ?? '',
    vat: payload.vat ?? ''
  };
};
