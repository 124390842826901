import { ReactNode } from 'react';
import Tooltip from '../../../../tooltip/Tooltip';

type Props = {
  label?: ReactNode | string;
  tooltip?: string;
};

const InputLabel = ({ label, tooltip }: Props) => {
  return (
    <div className='label-wrapper'>
      {label && (
        <label className='input-label-font on-light-text-color label-wrapper flex items-center'>
          <span>{label}</span>
          {tooltip && <Tooltip text={tooltip} asChild position='right' />}
        </label>
      )}
    </div>
  );
};

export default InputLabel;
