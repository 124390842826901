import { TransportForm } from 'components/lca/Scenarios/components/TransportConfigurationModal/types';
import { NODE_TYPES_ARRAY } from 'components/lca/Scenarios/constants';
import { isInNarrowedArray } from 'utils/arrays';

export const adaptLCATransportForm = ({
  acv_id,
  node_id,
  data
}: TransportForm): UpdateLCATransportDetailsParams => {
  return {
    acv_id: acv_id,
    node_id: node_id,
    data: {
      name: data.name,
      transports: data.transports.map((transport) => {
        if (!isInNarrowedArray(transport.type, NODE_TYPES_ARRAY)) {
          throw new Error(`Invalid transport type: ${transport.type}`);
        }

        return {
          name: transport.name,
          supplier: transport.supplier,
          country: transport.country,
          type: transport.type,
          value: Number(transport.value),
          impact_source_id: transport.impact_source_id
        };
      })
    }
  };
};

export const adaptLCATransportDetails = (
  data: LCATransportDetailsResponse
): LCATransportDetailsFront => {
  return {
    name: data.name,
    transports: data.transports.map((transport) => ({
      name: transport.name,
      supplier: transport.supplier,
      country: transport.country,
      type: transport.type,
      value: transport.value?.toString() ?? '',
      impact_source_id: transport.impact_source_id ?? ''
    }))
  };
};
