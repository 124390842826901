import { MarkerType, type Edge, type Node } from '@xyflow/react';
import type { LCAConnection, ScenarioNode } from '../types/LCAScenarios';
import { isInNarrowedArray } from 'utils/arrays';
import { NODE_TYPES_ARRAY } from '../constants';
import { LCABlock, LCAOutputs } from '../../types/LCABlock';

import uuid from 'uuid';
import { assignEdgeType } from '../reactFlowConfig';

export const buildNodes = (nodes: Node[]): ScenarioNode[] => {
  return nodes.map((node) => {
    if (!node.type || !isInNarrowedArray(node.type, NODE_TYPES_ARRAY)) {
      throw new Error(`Node type ${node.type} is not valid`);
    }

    return {
      ...node,
      type: node.type
    } as ScenarioNode;
  });
};

export const buildPieceFromString = (piece: string) => {
  return {
    id: uuid.v4(),
    label: piece,
    type: 'piece',
    inputs: [],
    outputs: []
  };
};

export const buildPieceFromOutput = (outputPiece: LCAOutputs) => {
  if (!outputPiece) {
    return null;
  }

  return {
    id: outputPiece.id,
    label: outputPiece.name,
    type: outputPiece.type,
    inputs: [],
    outputs: []
  };
};

export const buildNodesFromBlocks = (blocks: LCABlock[]): ScenarioNode[] => {
  return blocks.map((block) => {
    const position = {
      x: block.coordinate_x,
      y: block.coordinate_y
    };

    return {
      id: block.id,
      data: {
        id: block.id,
        label: block.name,
        inputs: block.inputs,
        outputs: block.outputs
      },
      position,
      type: block.type
    };
  });
};

export const buildEdge = (edge: Edge): Edge => {
  return {
    ...edge,
    markerEnd: {
      type: MarkerType.ArrowClosed
    }
  };
};

export const buildEdges = (edges: Edge[]) => {
  return edges.map(buildEdge);
};

export const buildEdgesFromBlock = (block: LCABlock): Edge[] => {
  const edgeType = assignEdgeType(block.type, block.outputs);

  return block.outputs.map((output) => ({
    id: `${block.id}-${output.id}`,
    source: block.id,
    target: output.id,
    markerEnd: {
      type: MarkerType.ArrowClosed
    },
    type: edgeType
  }));
};

export const buildEdgeFromConnection = (connection: LCAConnection): Edge => {
  return {
    id: connection.id,
    source: connection.source_id,
    target: connection.target_id,
    markerEnd: {
      type: MarkerType.ArrowClosed
    },
    type: 'lcaEdge'
  };
};
