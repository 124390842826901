import { useContext } from 'react';
import { EdgeHighlightContext } from './EdgeHighlightContext';

export const useEdgeHighlight = () => {
  const context = useContext(EdgeHighlightContext);

  if (!context) {
    throw new Error('useEdgeHighlight must be used within a EdgeHighlightProvider');
  }

  return context;
};
