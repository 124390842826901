import { setNotification } from 'actions/notification';
import { useDispatch } from 'react-redux';

export const useNotification = () => {
  const dispatch = useDispatch();

  const notify = (msg: string, timeout?: number) => {
    dispatch(setNotification(msg, timeout));
  };

  return { notify };
};
