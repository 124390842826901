import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { moveOrganizationBlocks } from 'services/api/holdings';
import { GetHoldingBlocksQueryKey, OrganizationBlockBack } from 'types/entities/holdings';

export const useMoveOrganizationBlocks = () => {
  const queryClient = useQueryClient();
  const org = useSelectedOrganization();
  const { mutate } = useMutation({
    mutationFn: moveOrganizationBlocks,
    onSuccess: (data, params) => {
      queryClient.setQueryData<OrganizationBlockBack[], GetHoldingBlocksQueryKey>(
        ['holding-blocks', params.contract_org_id, 'connections', org?.id],
        (oldData) => {
          return oldData?.map((block) => {
            const movedBlock = data.find((b) => b.node_id === block.node_id);

            if (!movedBlock) {
              return block;
            }

            return {
              ...block,
              coordinates_x: movedBlock.coordinates_x,
              coordinates_y: movedBlock.coordinates_y
            };
          });
        }
      );
    }
  });

  return { moveOrganizationBlocks: mutate };
};
