import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { patchShare } from 'services/api/holdings';
import { GetHoldingConnectionsQueryKey, HoldingConnectionBack } from 'types/entities/holdings';
import { useContractParent } from './useContractParent';
import { Session } from 'types/entities/user';
import { authParseLoginCallback } from 'services/authService';
import { loadUser } from 'actions/auth';
import { useDispatch } from 'react-redux';

export const usePatchShare = () => {
  const queryClient = useQueryClient();
  const org = useSelectedOrganization();
  const { data: contractOrgId } = useContractParent(org?.id);
  const dispatch = useDispatch();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: patchShare,
    onSuccess: async ({ source_id, target_id, share }) => {
      queryClient.setQueryData<HoldingConnectionBack[], GetHoldingConnectionsQueryKey>(
        ['holding-connections', contractOrgId, org?.id],
        (oldData) => {
          return oldData?.map((connection) => {
            if (connection.source_id === source_id && connection.target_id === target_id) {
              return { ...connection, share };
            }

            return connection;
          });
        }
      );

      const sessionData: Session = await authParseLoginCallback();

      dispatch(loadUser(sessionData, false, false, contractOrgId));
    }
  });

  return { patchShare: mutateAsync, isMutating: isPending };
};
