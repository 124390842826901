import { ENTITY_TYPE, EntityType } from 'components/measure/companies/Diagram/constants';

export const adapterEntityType = (entityTypeBack: EntityTypeBack): EntityType => {
  if (entityTypeBack === 'facility') {
    return ENTITY_TYPE.FACILITIES;
  }

  if (entityTypeBack === 'vehicle') {
    return ENTITY_TYPE.VEHICLES;
  }

  if (entityTypeBack === 'employee') {
    return ENTITY_TYPE.EMPLOYEES;
  }

  throw new Error('Unknown contract limit type');
};
