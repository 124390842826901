import { z } from 'zod';

export const shareSchema = z.object({
  share: z
    .string()
    .min(1, 'required')
    .refine((value) => {
      return !isNaN(Number(value));
    }, 'isNumber')
    .refine((value) => {
      return Number(value) >= 0;
    }, 'isPositiveNumber')
    .refine((value) => {
      return Number(value) <= 100;
    }, 'isLessThanOrEqual::{"count":100}')
});
