import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/userContext';
import { User, UserEdit } from '../../types/entities/user';
import Button from '../ui/button/Button';
import ButtonDropdown from '../ui/buttonDropdown/ButtonDropdown';
import InfiniteList from '../ui/infiniteList/InfiniteList';
import Modal from '../ui/modal/Modal';
import AddMember from './addMember/AddMember';
import DeleteMember from './DeleteMember';
import EditMember from './EditMember';
import './styles.scss';
import useColumns from './hooks/useColumns';
import useSelectedOrganization from '../../customHooks/useSelectedOrganization';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../actions/notification';
import { getInvites } from '../../services/api/invites';
import { useContractParent } from 'components/measure/companies/Diagram/hooks/useContractParent';
import { G_IFA_ID } from './constants';
import { useIsOwnerOfContractOrg } from 'customHooks/useIsOwnerOfContractOrg';

function Members() {
  const user = useContext(UserContext);
  const { t } = useTranslation();
  const foundOrganization = useSelectedOrganization();
  const { data: contractOrgId, isLoading, isError } = useContractParent(foundOrganization?.id);

  const isGIFA = contractOrgId === G_IFA_ID;

  const dispatch = useDispatch();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate() + 1);
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setMonth(0);
  oneYearAgo.setDate(1);
  oneYearAgo.setHours(0, 0, 0, 0);

  const [members, setMembers] = useState<User[]>([]);
  const [invitesLength, setInvitesLength] = useState(0);
  const [memberToEdit, setMemberToEdit] = useState<User | null>();
  const [memberToDelete, setMemberToDelete] = useState({
    id: '',
    type: 'member'
  });
  const [showAddMember, setShowAddMember] = useState(false);
  const [total, setTotal] = useState(0);

  const columns = useColumns();

  const url = `/memberships`;

  const fetchInvites = async () => {
    const data = await getInvites();
    setInvitesLength(data.length ?? 0);
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  const { isOwnerOfContractOrg } = useIsOwnerOfContractOrg({ contractOrgId });

  const canInvite = isGIFA
    ? isOwnerOfContractOrg
    : foundOrganization?.role === 'owner' || foundOrganization?.role === 'parent';

  const renderDropdownComponent = (member: User) => {
    const sameUser =
      user &&
      (member.id === user.id ||
        (foundOrganization?.role !== 'owner' && foundOrganization?.role !== 'parent'))
        ? true
        : false;
    return (
      <ButtonDropdown
        button={
          sameUser ? (
            <img src='/images/icons/editPen3.svg' height={19} width={18} alt='edit-pen' />
          ) : (
            <img src='/images/icons/editPen.svg' height={19} width={18} alt='edit-pen' />
          )
        }
        options={[
          {
            id: `${member.id}-edit`,
            name: t('members.edit'),
            onClick: () => {
              setMemberToEdit(member);
            }
          },
          {
            id: `${member.id}-delete`,
            name: t('members.delete'),
            onClick: () => {
              setMemberToDelete({
                id: member.first_name ? member.id : member.email,
                type: member.first_name ? 'member' : 'invite'
              });
            }
          }
        ]}
        disabled={sameUser}
      />
    );
  };

  const onCloseModal = () => {
    setShowAddMember(false);
    setMemberToEdit(null);
    setMemberToDelete({
      id: '',
      type: 'member'
    });
  };

  const renderEmailComponent = (email: string) => {
    return (
      <div className='icon-text-wrapper'>
        <img src='/images/icons/personGray.svg' alt='person' />
        <span>{email}</span>
      </div>
    );
  };

  const addMember = (member: User) => {
    const oldMembers = [...members];

    oldMembers.push({
      ...member,
      email: member.email,
      edit: renderDropdownComponent(member)
    });

    setMembers(oldMembers);
    onCloseModal();
    dispatch(setNotification(t('notification.addMember')));
  };

  const inviteUser = (member: User) => {
    const oldMembers = [...members];

    oldMembers.push({
      ...member,
      email: member.email,
      edit: renderDropdownComponent(member)
    });

    setMembers(oldMembers);
    dispatch(setNotification(t('notification.invitationSent')));
    onCloseModal();
  };

  const editMember = (member: UserEdit, id: string) => {
    const oldMembers = [...members];
    const indexFound = oldMembers.findIndex((elem) => elem.id === id);
    const foundMemberOld = { ...oldMembers[indexFound] };

    if (indexFound > -1) {
      oldMembers[indexFound] = {
        id,
        first_name: foundMemberOld.first_name,
        last_name: foundMemberOld.last_name,
        phone_number: foundMemberOld.phone_number,
        prefix: foundMemberOld.prefix,
        email: foundMemberOld.email,
        role: member.role,
        edit: renderDropdownComponent(foundMemberOld),
        created_at: foundMemberOld.created_at,
        tasks: {
          total: 0,
          total_completed: 0,
          items: []
        }
      };
    }

    setMembers(oldMembers);
    setMemberToEdit(null);
    onCloseModal();
    dispatch(setNotification(t('notification.editMember')));
  };

  const deleteMember = (id: string, type: string) => {
    const newMembers = [...members];
    let indexMemberFound = -1;
    if (type === 'invite') {
      indexMemberFound = newMembers.findIndex((elem) => elem.email === id);
    } else {
      indexMemberFound = newMembers.findIndex((elem) => elem.id === id);
    }
    if (indexMemberFound > -1) {
      newMembers.splice(indexMemberFound, 1);
    }

    setMembers(newMembers);
    dispatch(setNotification(t('notification.deleteMember')));
    setMemberToDelete({
      id: '',
      type: 'member'
    });
  };

  const handleShowAddMemberModal = () => {
    setShowAddMember(true);
  };

  const parseData = (members: User[]) => {
    const data = members
      .filter((member) => {
        if (user?.email.endsWith('@dcycle.io')) {
          return true;
        }

        return !member.email?.endsWith('@dcycle.io');
      })
      .map((member) => {
        return {
          ...member,
          first_name: member.first_name && member.first_name !== '' ? member.first_name : '-',
          last_name: member.last_name && member.last_name !== '' ? member.last_name : '-',
          created_at: member.created_at ? new Date(member.created_at).toLocaleDateString() : '-',
          email: renderEmailComponent(member.email),
          phone_number:
            member.prefix && member.phone_number ? `${member.prefix}${member.phone_number}` : '-',
          role: member.role ? t(`members.${member.role}`) : '-',
          edit: renderDropdownComponent(member),
          disabled:
            user &&
            (member.id === user.id ||
              (foundOrganization?.role !== 'owner' && foundOrganization?.role !== 'parent') ||
              member.isInvitation === true)
        };
      });

    return data;
  };

  if (!members || !foundOrganization || (isLoading && !isError)) {
    return null;
  }

  return (
    <section className={canInvite ? 'members' : 'members two-rows'}>
      <div className='members__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('members.title')}</h3>
        <h4 className='subtitle2-font on-light-text-color'>
          {foundOrganization?.role === 'member'
            ? t('members.subtitleMember')
            : t('members.subtitle')}
        </h4>
      </div>
      {canInvite && (
        <div className='members__body main-bg-color solid-border '>
          <div className='members-card main-bg-color solid-border '>
            <h1 className='headline4-font'>{t('members.start')}</h1>
            <p className='subtitle3-font'>{t('members.startDescription')}</p>
            {foundOrganization && (
              <div className='buttons'>
                <Button
                  lookAndFeel={
                    total + invitesLength >= foundOrganization.limit_users + 1
                      ? 'blocked'
                      : 'primary'
                  }
                  text={t('members.invite')}
                  size='small'
                  onClick={handleShowAddMemberModal}
                />
                {/* <Button lookAndFeel='secondary' text={t('members.uploadManual')} size='small' onClick={handleShowAddMemberModal} /> */}
              </div>
            )}
          </div>
        </div>
      )}

      <InfiniteList
        i18key={'members'}
        url={url}
        values={members}
        setValues={setMembers}
        columns={columns}
        parseData={parseData}
        organization={user?.selectedOrganization}
        total={total}
        setTotal={setTotal}
        filters={{
          inputTextKey: 'email',
          options: [
            { id: 'member', name: t('members.member') },
            { id: 'owner', name: t('members.owner') }
          ],
          otherOptions: {
            email_who_ask: user?.email
          }
        }}
      />
      <Modal show={showAddMember} onClose={onCloseModal} width='428px' maxWidth='428px'>
        <AddMember
          user={user}
          addMember={addMember}
          inviteUser={inviteUser}
          members={members}
          organizationId={user?.selectedOrganization ?? ''}
        />
      </Modal>

      <Modal show={!!memberToEdit} onClose={onCloseModal} width='428px' maxWidth='428px'>
        {memberToEdit && (
          <EditMember
            user={user}
            memberToEdit={memberToEdit}
            editMember={editMember}
            members={members}
          />
        )}
      </Modal>

      <Modal show={!!memberToDelete.id} onClose={onCloseModal} width='428px' maxWidth='428px'>
        <DeleteMember
          deleteMember={deleteMember}
          memberToDelete={memberToDelete}
          user={user}
          members={members}
        />
      </Modal>
    </section>
  );
}

export default Members;
