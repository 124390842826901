import { ChangeEvent, forwardRef, InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSize } from '../../../../types/utilsEnums/input';
import InputText from '../inputText/InputText';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'size'> {
  onlyIntegers?: boolean;
  onChange: (value: string) => void;
  max?: number;
  min?: number;
  size?: InputSize;
  decimals?: number;
  allowNegative?: boolean;
  Render?: React.FC<React.ComponentProps<typeof InputText>>;
}

const InputNumber = forwardRef<HTMLInputElement, Props>(
  (
    {
      onlyIntegers = false,
      onChange,
      max,
      min,
      size = InputSize.MEDIUM,
      allowNegative = false,
      maxLength = 50,
      decimals = 2,
      Render = InputText,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    const handleOnChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
      // if only integers is true do not let the user to type a dot
      if (onlyIntegers && e.target.value.includes('.')) return;

      const regexString = `^${allowNegative ? '-?' : ''}\\d+(\\.\\d{0,${decimals}})?$`;

      const regexDecimals = new RegExp(regexString);

      if (!regexDecimals.test(e.target.value) && e.target.value !== '') return;

      if (max && Number(e.target.value) > max) return;
      if (min && Number(e.target.value) < min) return;

      onChange(e.target.value);
    };

    const placeholder = props.placeholder || t('input.placeholder');

    const sizeClassName = size === InputSize.MEDIUM ? 'input-small-font' : 'input-smaller-font';
    const className = `${sizeClassName} ${props.className}`;
    return (
      <Render
        {...props}
        ref={ref}
        placeholder={placeholder}
        className={className}
        onChange={handleOnChangeValue}
        maxLength={maxLength}
        pattern={`^\\d+(\\.\\d{0,${decimals}})?$`}
      />
    );
  }
);

InputNumber.displayName = 'InputNumber';

const WithPercentageOverlay = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <InputNumber {...props} ref={ref} Render={InputText.WithPercentageOverlay} />;
});

WithPercentageOverlay.displayName = 'WithPercentageOverlay';

export default Object.assign(InputNumber, { WithPercentageOverlay });
