import { HoldingNodeData } from 'types/entities/holdings';

export const isValidOrgNode = (data: HoldingNodeData): boolean => {
  if (!data.org_id) {
    return false;
  }

  if (!data.cif) {
    return false;
  }

  if (!data.name) {
    return false;
  }

  return true;
};
