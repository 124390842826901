export const ICON_SIZES = {
  large: 'w-8 h-8 p-2 rounded-8',
  medium: 'w-4 h-4 rounded-4',
  small: 'w-3 h-3',
  xlarge: 'w-12 h-12 p-2 rounded-8'
} as const;

export const VARIANTS = {
  default: {
    bg: 'bg-secondary-purple-20',
    icon: 'white'
  }
} as const;
