import { useInternalNode } from '@xyflow/react';

type Params = {
  target: string;
  targetY: number;
};
export const useTop0 = ({ target, targetY }: Params) => {
  const targetInternalNode = useInternalNode(target);

  const top0 = targetY - (targetInternalNode?.measured.height || 0) / 2;

  return { top0 };
};
