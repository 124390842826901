import { useTranslation } from 'react-i18next';

import useOnChangeValue from '../../../../../../../../customHooks/useOnChangeValue';
import InputNumber from '../../../../../../../ui/formComponents2/inputNumber/InputNumber';
import InputWrapper from '../../../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from '../../../../../../../ui/formComponents2/select/Select';
import { FormDataStateESG } from '../ModalDatapoint';
import { EvidenceLabel } from './EvidenceLabel';
type Props = {
  formDataState: FormDataStateESG;
  onChangeFiles: (files: File[]) => void;
};
export const TextSelectComponent = ({ formDataState, onChangeFiles }: Props) => {
  const { t: unitT } = useTranslation('translation', { keyPrefix: 'units' });
  const { t: generalT } = useTranslation('translation', { keyPrefix: 'esgMetrics.general' });
  const { formData, setFormData } = formDataState;
  const { onChangeValue } = useOnChangeValue({ setFormData });

  return (
    <InputWrapper
      label={<EvidenceLabel onChangeFiles={onChangeFiles} />}
      iconV2='input_numeric'
      error={formData.errors.find(
        (elem: ErrorType) => elem.error === 'value' || elem.error === 'unit'
      )}
      className='col-span-1 md-col-span-2'>
      <InputNumber
        value={(formData.value as string) ?? ''}
        onChange={onChangeValue('value')}
        placeholder={generalT('quantityPlaceholder')}
        max={formData.csrd_data_type === 'percent' ? 100 : undefined}
        min={formData.csrd_data_type === 'percent' ? 0 : undefined}
      />
      {formData.unit && (
        <Select
          value={{ ...formData.unit, name: unitT(formData.unit.name) }}
          onChange={/* handleOnChangeUnit */ () => null}
          options={[{ ...formData.unit, name: unitT(formData.unit.name) }]}
          placeholder={generalT('unitPlaceholder')}
        />
      )}
    </InputWrapper>
  );
};
