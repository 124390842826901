import { useMutation, useQueryClient } from '@tanstack/react-query';
import { confirmLink } from 'services/api/matrices';

export const useConfirmLink = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: confirmLink,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['holding-connections'],
        exact: false
      });

      queryClient.invalidateQueries({
        queryKey: ['holding-blocks'],
        exact: false
      });
    }
  });

  return { confirmLink: mutateAsync };
};
