import Button from '../../../../../ui/button/Button';
import { useFormContext } from '../FormContext';
import Icon from '../../../../../ui/icon/Icon';
import TableComponent from '../../../../../ui/table/Table';
import { useColumns } from './useColumns';
import StepCarouselList from '../../../../../ui/stepCarouselList/StepCarouselList';
import { useStepContext } from '../../../../../ui/stepCarouselList/StepContext';
import Modal from '../../../../../ui/modal/Modal';
import { useLCATranslation } from '../../../../../../customHooks/translations/useLCATranslation';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from '../../../constants';
import { useModalContext } from '../../ModalHandler';
import { STEP_IDS } from '../constants';

type Props = {
  openSelectMaterialModal: () => void;
};

export const SecondStep = ({ openSelectMaterialModal }: Props) => {
  const { form, setForm } = useFormContext();

  const { t } = useLCATranslation();

  const { steps, handleSelect } = useStepContext();

  const deleteMaterial = (material_id: string) => () => {
    setForm((prev) => ({
      ...prev,
      inputs: prev.inputs.filter((input) => input.material_id !== material_id)
    }));
  };

  const updateMaterialQuantity = (id: string, quantity: string) => {
    setForm((prev) => ({
      ...prev,
      inputs: prev.inputs.map((input) =>
        input.material_id === id ? { ...input, quantity } : input
      )
    }));
  };

  const updateMaterialUnit = (id: string, unit: SelectOptionFormat<string>) => {
    setForm((prev) => ({
      ...prev,
      inputs: prev.inputs.map((input) =>
        input.material_id === id
          ? {
              ...input,
              unit: {
                id: unit.id,
                name: unit.name
              }
            }
          : input
      )
    }));
  };

  const { columns } = useColumns({ deleteMaterial, updateMaterialQuantity, updateMaterialUnit });

  return (
    <>
      <Modal.Header
        title={t('lcaDetail.processFlow.processModal.title')}
        description={t('lcaDetail.processFlow.processModal.description')}
      />

      <StepCarouselList
        steps={steps}
        handleSelect={handleSelect}
        style={{ marginBottom: '1rem' }}
      />
      <div className='flex-col gap-y-2' style={{ height: DEFAULT_HEIGHT, width: DEFAULT_WIDTH }}>
        <Modal.Content style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          {!form.inputs.length && (
            <span className='font-body-b2-r text-neutral-gray-20 bg-neutral-gray-warm-80 w-full py-2 px-4 border-box rounded-4 grow'>
              {t('lcaDetail.processFlow.processModal.noInputs')}
            </span>
          )}
          {!!form.inputs.length && (
            <div className='bg-neutral-gray-warm-80 w-full p-4 border-box rounded-4 grow'>
              <TableComponent data={form.inputs} columns={columns} loading={false} />
            </div>
          )}
        </Modal.Content>
        <Button
          lookAndFeel='link-dark'
          className='flex items-center gap-x-1'
          text={t('lcaDetail.processFlow.processModal.addInputs')}
          iconNode={<Icon icon='add' color='gray-dark' size='medium' />}
          onClick={openSelectMaterialModal}
        />
      </div>
      <Modal.Buttons>
        <Buttons />
      </Modal.Buttons>
    </>
  );
};

const Buttons = () => {
  const { handleSelect } = useStepContext();

  const { t } = useLCATranslation();

  const { setModalData } = useModalContext();

  const onClick = () => {
    handleSelect(STEP_IDS.THIRD);
  };

  const onCancel = () => {
    setModalData(undefined);
  };

  return (
    <>
      <Button
        lookAndFeel='secondary'
        text={t('lcaDetail.processFlow.processModal.cancelFirstStep')}
        size='small'
        onClick={onCancel}
      />
      <Button
        lookAndFeel='primary'
        text={t('lcaDetail.processFlow.processModal.saveFirstStep')}
        size='small'
        onClick={onClick}
      />
    </>
  );
};

SecondStep.Buttons = Buttons;
