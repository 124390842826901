import { HoldingNode, OrganizationBlock } from 'types/entities/holdings';

export const buildNodesFromBlocks = (blocks: OrganizationBlock[]): HoldingNode[] => {
  return blocks.map((block) => {
    const position = {
      x: block.coordinate_x,
      y: block.coordinate_y
    };

    return {
      id: block.node_id,
      data: {
        id: block.node_id,
        org_id: block.org_id,
        name: block.name,
        cif: block.cif,
        country: block.country,
        sector: block.sector,
        inputs: block.inputs,
        outputs: block.outputs,
        facilities: block.facilities,
        employees: block.employees,
        vehicles: block.vehicles,
        limits: {
          facilities: block.limit_facilities,
          employees: block.limit_employees,
          vehicles: block.limit_vehicles
        }
      },
      position,
      type: block.type
    };
  });
};
