import { z } from 'zod';

export const editLCAOfficialMaterialSchema = z.object({
  name: z.string(),
  supplier: z.string().nullish(),
  description: z.string().nullish(),
  country: z.object({
    id: z.string(),
    name: z.string()
  }),
  type: z.object({
    id: z.enum(['material', 'energy']),
    name: z.string()
  })
});

export type EditLCAOfficialMaterial = z.infer<typeof editLCAOfficialMaterialSchema>;
