import { useTranslation } from 'react-i18next';
import FileAttached from '../../../../../../../tasks/components/form/components/fileAttached/FileAttached';
import FormElementFull from '../../../../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../../../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../../../../../../ui/formComponents/inputFile/InputFileSingle';
import { FormDataStateESG } from '../ModalDatapoint';

type Props = {
  formDataState: FormDataStateESG;
  onChangeFile: (file: File) => void;
};

export const UploadFileComponent = ({ formDataState, onChangeFile }: Props) => {
  const { formData } = formDataState;

  return (
    <Layout>
      <AddFileButton
        handleUploadFile={onChangeFile}
        fileName={''}
        error={formData.errors.find((error: ErrorType) => error.error === 'file')}
      />
    </Layout>
  );
};

type AddFileButtonProps = {
  handleUploadFile: (file: File) => void;
  fileName: string;
  error: ErrorType | undefined;
};

export const AddFileButton = ({ handleUploadFile, fileName, error }: AddFileButtonProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esgMetrics.general' });

  return (
    <InputFileSingle
      handleFile={handleUploadFile}
      buttonText={t('uploadButton')}
      fileName={fileName}
      allowedTypes={[]}
      error={error}
      labelText={t('uploadModalLabel')}
      buttonIcon={'/images/icons/plusWhite.svg'}
    />
  );
};

type LayoutProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const Layout = ({ children }: LayoutProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esgMetrics.general' });

  return (
    <FormWrapper>
      <FormElementFull>
        <p className='font-10 weight-600'>{t('uploadModalDescription')}</p>
        {children}
      </FormElementFull>
    </FormWrapper>
  );
};

type FilesAttachmentProps = {
  files: {
    id: string;
    name: string;
    url?: string;
  }[];
  handleRemove: (file: string) => void;
};

export const FilesAttachment = ({ files, handleRemove }: FilesAttachmentProps) => {
  return (
    <div className='overflow-y-auto max-h-10'>
      {files.map((file) => (
        <FileAttached key={file.name} file={file} handleRemove={() => handleRemove(file.name)} />
      ))}
    </div>
  );
};

UploadFileComponent.Layout = Layout;
UploadFileComponent.AddFileButton = AddFileButton;
UploadFileComponent.FilesAttachment = FilesAttachment;
