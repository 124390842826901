import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parseDatapointOutputDataToBackend } from '../../../../../../../adapters/datapoints';
import { FilterDatesContext } from '../../../../../../../context/filterDatesContext';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import useYearOptions from '../../../../../../../customHooks/useYearOptions';
import {
  DataType,
  ModalType,
  modalMapping
} from '../../../../../../../types/entitiesEnums/datapoints';
import { convertStringToDate } from '../../../../../../../utils/convertDates';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementHalf from '../../../../../../ui/formComponents/formElementHalf/FormElementHalf';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import { DateComponent } from './components/DateComponent';
import { TextAreaComponent } from './components/TextAreaComponent';
import { TextSelectComponent } from './components/TextSelectComponent';
import { FilesAttachment, UploadFileComponent } from './components/UploadFileComponent';
import { YearComponent } from './components/YearComponent';
import { YesNoComponent } from './components/YesNoComponent';
import { FrameworkDatapoint } from 'components/dashboard/controlPanel/components/frameworkProgress/components/FrameworkDashboard/components/DatapointListRenderer';
import Icon from 'components/ui/icon/Icon';
import ReadMoreParagraph from 'components/ui/readMoreParagraph/ReadMoreParagraph';
import { useDatapointOutputData } from './hooks/useDatapointOutputData';
import { useAddDatapointOutputData } from './hooks/useAddDatapointOutputData';
import moment from 'moment';
import getFileNameFromUrl from 'utils/getFileNameFromUrl';

type Props = {
  datapoint: FrameworkDatapoint;
  edit?: boolean;
  closeModal: () => void;
  editElement: (newValue: FrameworkDatapoint, key?: keyof FrameworkDatapoint) => void;
};

interface FormData extends FrameworkDatapoint {
  year: SelectOptionFormat;
  errors: ErrorType[];
  value: string | boolean | File | undefined;
  files: {
    name: string;
    url?: string;
    file?: File;
  }[];
  unit?: {
    id: string;
    name: string;
    type: string;
  };
  value_unit_id: string;
}

export type FormDataStateESG = {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
};

export const ModalDatapoint = ({ datapoint, closeModal }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esgMetrics.general' });
  const { i18n } = useTranslation();
  const yearOptions = useYearOptions();

  const { startDate, endDate } = useContext(FilterDatesContext);
  const currentLanguage = i18n.language;
  const getTranslatedName = (datapoint: FrameworkDatapoint, currentLanguage: string) => {
    switch (currentLanguage) {
      case 'es':
        return datapoint.name_es;
      case 'pt':
        return datapoint.name_pt;
      default:
        return datapoint.name_en;
    }
  };

  const year = convertStringToDate(startDate).getFullYear().toString();

  const [formData, setFormData] = useState<FormData>({
    ...datapoint,
    year: {
      id: year,
      name: year
    },
    files: [],
    errors: [],
    value: '',
    value_unit_id: ''
  });

  // Datapoints output stored in the backend
  const { datapointOutputData, isLoading } = useDatapointOutputData({
    baseDataId: datapoint.id,
    year
  });
  // Extract the first datapoint from the output data that matches the year
  // If there is no datapoint for the year, the value is empty
  const datapointData = datapointOutputData?.items[0];

  const { mutate } = useAddDatapointOutputData();

  useEffect(() => {
    setFormData({
      ...formData,
      value: datapointData?.value || '',
      files:
        datapointData?.file_urls?.map((file) => ({
          name: getFileNameFromUrl(file),
          url: file
        })) || [],
      value_unit_id: datapoint.reporting_unit_id || ''
    });
  }, [datapointData]);

  const formDataState: FormDataStateESG = {
    formData: formData,
    setFormData: setFormData
  };

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleChangeFiles = (newFiles: File[]) => {
    setFormData({
      ...formData,
      files: [
        ...formData.files,
        ...newFiles.map((file) => ({
          name: file.name,
          file
        }))
      ]
    });
  };

  const handleChangeFile = (file: File) => {
    setFormData({
      ...formData,
      files: [
        {
          name: file.name,
          file
        }
      ]
    });
  };

  const handleRemoveFile = (fileName: string) => {
    const newFiles = formData.files.filter((file) => file.name !== fileName);
    setFormData({
      ...formData,
      files: newFiles
    });
  };

  const renderFragmentAccordingToType = (type: DataType) => {
    const componentMap: { [key: string]: JSX.Element } = {
      [ModalType.YesNo]: (
        <YesNoComponent formDataState={formDataState} onChangeFiles={handleChangeFiles} />
      ),
      [ModalType.Table]: (
        <UploadFileComponent formDataState={formDataState} onChangeFile={handleChangeFile} />
      ),
      [ModalType.Narrative]: (
        <TextAreaComponent formDataState={formDataState} onChangeFiles={handleChangeFiles} />
      ),
      [ModalType.Numeric]: (
        <TextSelectComponent formDataState={formDataState} onChangeFiles={handleChangeFiles} />
      ),
      [ModalType.Date]: (
        <DateComponent formDataState={formDataState} onChangeFiles={handleChangeFiles} />
      ),
      [ModalType.Year]: (
        <YearComponent formDataState={formDataState} onChangeFiles={handleChangeFiles} />
      )
    };

    const modalType = modalMapping[type];

    return componentMap[modalType] || type;
  };

  const handleSubmit = async () => {
    if (!datapointOutputData) {
      return;
    }

    if (datapointOutputData.size !== 0 && datapointData) {
      // Modify existing datapoint value
      const datapointBackend = parseDatapointOutputDataToBackend({
        ...datapointData,
        unit_id: datapoint.reporting_unit_id ?? '',
        value: formData.value,
        description: '',
        newFiles: formData.files.filter((file) => !file.url).map((file) => file.file!),
        file_urls: datapointData.file_urls.filter((file) => {
          return formData.files.find((newFile) => newFile.url === file);
        })
      });
      mutate({
        datapointData: datapointBackend,
        id: datapointData.id
      });
    } else {
      // Create new datapoint value
      const datapointBackend = parseDatapointOutputDataToBackend({
        base_data_id: datapointOutputData.base_data_id,
        start_date: moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        end_date: moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        value: formData.value,
        description: '',
        data_type: typeof formData.value,
        unit_id: datapoint.reporting_unit_id ?? '',
        newFiles: formData.files.map((file) => file.file!),
        file_urls: []
      });
      mutate({
        datapointData: datapointBackend
      });
    }

    closeModal();
  };

  const foundGeneralError = formData.errors.find((error) => error.error === 'general');

  return (
    <>
      <FormHeader title={datapointData ? t('editModalTitle') : t('addResponseModalTitle')} />
      <p>
        {datapoint.datapoint_id} - {getTranslatedName(datapoint, currentLanguage)}
      </p>
      <div
        style={{
          maxHeight: '350px',
          overflowY: 'auto'
        }}>
        <FormWrapper>
          <FormElementHalf>
            <FormSelect
              icon='/images/icons/calendar.svg'
              label={t('selectYear')}
              onChange={onChangeValue('year')}
              value={formData.year}
              options={yearOptions.years}
              error={formData.errors.find((error) => error.error === 'year')}
              sort={false}
              disabled
            />
          </FormElementHalf>
        </FormWrapper>
        {/* //depending on the incoming datapoint.type, render the second field that corresponds to it */}
        {renderFragmentAccordingToType(datapoint.csrd_data_type as DataType)}
        <FilesAttachment
          files={formData.files.map((file) => ({ id: '', name: file.name, url: file.url }))}
          handleRemove={handleRemoveFile}
        />
        {datapoint.default_response && (
          <div className='flex flex-col p-4 align-start form-wrapper-standard rounded-12 app-bg-color'>
            <div className='flex gap-2 items-center'>
              <Icon icon='multi_star' color={'blocked'} className='m-right-star-icon' />
              <span className='weight-600 secondary-text-color font-12'>
                {t('dcycleAiExampleText')}
              </span>
            </div>
            <ReadMoreParagraph
              id='aiText'
              className='weight-400 font-12 on-light-text-color m-0'
              text={datapoint.default_response}
              amountOfWords={27}
            />
          </div>
        )}
      </div>
      <FormButtonSection style={{ marginTop: '1em' }}>
        <Button lookAndFeel='secondary' text={t('cancel')} onClick={closeModal} size='medium' />
        <Button
          lookAndFeel='primary'
          text={t('saveClose')}
          size='medium'
          onClick={handleSubmit}
          loading={isLoading}
        />
      </FormButtonSection>
      {foundGeneralError && <ErrorText>{foundGeneralError.description}</ErrorText>}
    </>
  );
};
