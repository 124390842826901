import { useTranslation } from 'react-i18next';
import Button from '../../ui/button/Button';

type Props = {
  position?: 'top' | 'bottom';
  text: string;
  textVariables?: { [key: string]: string | number };
  showButton?: boolean;
  buttonText?: string;
  buttonFunction?: () => void;
};

const SmallBanner = ({
  text = 'smallBanner.defaultMessage',
  textVariables = {},
  position = 'top',
  showButton = true,
  buttonFunction = () => null,
  buttonText = 'smallBanner.close'
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className='w-full flex justify-center items-center p-2 font-12 gap-4 px-2'
      style={{
        position: 'absolute',
        [position]: 0,
        left: 0,
        zIndex: 100,
        backgroundColor: 'white'
      }}>
      <p className='m-0' style={{ maxWidth: '90%' }}>
        {t(text, textVariables)}
      </p>
      {showButton && (
        <div className='inline-block'>
          <Button
            lookAndFeel='primary'
            text={t(buttonText, textVariables)}
            onClick={buttonFunction}
            size='tiny'
          />
        </div>
      )}
    </div>
  );
};

export default SmallBanner;
