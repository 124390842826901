import NuvoImporter from 'components/ui/nuvoImporter/NuvoImporter';
import { LanguageType, SettingsAPI } from 'nuvo-react';
import { useTranslation } from 'react-i18next';
import { basicNuvoStyle } from 'styles/nuvo';
import { MAX_NUVO_ENTRIES, TEMPLATE_FILE_NAME } from './constants';
import { useColumns } from './useColumns';
import Button from 'components/ui/button/Button';
import { updateOrCreateAnonymousSuppliers } from 'services/api/purchaseSuppliers';
import { supplier_schema } from './supplier_schema';

const treatAnonymousSupplierString = (str: string) => {
  return str.replace(',', '.').toLowerCase();
};

type Props = {
  org_id?: string;
};

export const AnonymousSuppliers: React.FC<Props> = ({ org_id }) => {
  const { i18n } = useTranslation();

  const { columns } = useColumns();

  const settings: SettingsAPI = {
    multipleFileUpload: true,
    processingEngine: 'default',
    language: i18n.resolvedLanguage as LanguageType,
    style: basicNuvoStyle,
    automaticHeaderDetection: true,
    maxEntries: MAX_NUVO_ENTRIES,
    identifier: TEMPLATE_FILE_NAME,
    columns
  };

  if (!org_id)
    return (
      <Button text='Subir proveedores anónimos' lookAndFeel='secondary' disabled size='small' />
    );

  return (
    <NuvoImporter
      btnI18nKey='Subir proveedores anónimos'
      settings={settings}
      lookAndFeel='secondary'
      onResults={async (results) => {
        const adaptedResults =
          results.map((result) => {
            const line = supplier_schema.safeParse(result);

            if (!line.success) {
              throw new Error('Invalid data');
            }

            const code = line.data.code.split('-')[0];

            const { code_type, name, country } = line.data;

            return {
              business_name: treatAnonymousSupplierString(name),
              country: country,
              framework: code_type,
              code
            };
          }) ?? [];

        await updateOrCreateAnonymousSuppliers(adaptedResults);
      }}
    />
  );
};
