import { CSSProperties } from 'react';
import { ScenarioNode } from '../../types/LCAScenarios';
import { DEFAULT_DELETE_TOOLTIP_TEXT, PROCESS_DELETE_TOOLTIP_TEXT } from './constants';

export const checkIssues = (
  nodeMetadata: LCAProcessMetadata | undefined,
  data: ScenarioNode['data']
) => {
  const issues = [];
  if (!nodeMetadata?.input_types?.consumption && !nodeMetadata?.input_types?.energy) {
    issues.push('noInputs');
  }

  if (!nodeMetadata?.country) {
    issues.push('noLocation');
  }

  if (!data.inputs.length) {
    issues.push('noRawMaterial');
  }

  return issues;
};

export const checkMetadata = (nodeMetadata: LCAProcessMetadataFront | undefined) => {
  if (!nodeMetadata) {
    return false;
  }

  return Boolean(nodeMetadata.country);
};

const generateProcessTags = (
  nodeMetadata: LCAProcessMetadata | undefined,
  data: ScenarioNode['data']
) => {
  const tags = [];

  tags.push({
    lookAndFeel: 'primary',
    disabled: !nodeMetadata?.input_types?.consumption && !nodeMetadata?.input_types?.energy,
    value: 'inputs'
  });

  tags.push({
    lookAndFeel: 'primary',
    disabled: !nodeMetadata?.country,
    value: 'location'
  });

  tags.push({
    lookAndFeel: 'primary',
    disabled: !data.inputs.length,
    value: 'rawMaterial'
  });

  return tags;
};

const generateTransportTags = (
  nodeMetadata: LCATransportMetadata | undefined,
  data: ScenarioNode['data']
) => {
  const tags = [];

  if (!nodeMetadata?.name) {
    tags.push({
      lookAndFeel: 'primary',
      disabled: true,
      value: 'noDetails'
    });
  }

  if (nodeMetadata?.matched_materials) {
    tags.push({
      lookAndFeel: 'primary',
      disabled: false,
      value: 'matchedMaterials',
      count: nodeMetadata?.matched_materials
    });
  }

  if (nodeMetadata?.unmatched_materials) {
    tags.push({
      lookAndFeel: 'primary',
      disabled: true,
      value: 'unmatchedMaterials',
      count: nodeMetadata.unmatched_materials
    });
  }

  if (!data.inputs.length) {
    tags.push({
      lookAndFeel: 'primary',
      disabled: true,
      value: 'noMaterial'
    });
  }

  return tags;
};

export const generateTags = <T extends { node_id: string }>(
  metadata: T | undefined,
  data: ScenarioNode['data'],
  type: ScenarioNode['type']
): NodeTag[] => {
  if (type === 'process') {
    return generateProcessTags(metadata, data);
  }

  if (type === 'transport') {
    return generateTransportTags(metadata, data);
  }

  return [];
};

export const getDeleteStateDefault = (
  hasConnections: boolean
): keyof typeof DEFAULT_DELETE_TOOLTIP_TEXT => {
  if (hasConnections) {
    return 'has_connections';
  }

  return 'default';
};

export const getDeleteStateProcess = (
  hasConnections: boolean,
  isFinalProcess: boolean
): keyof typeof PROCESS_DELETE_TOOLTIP_TEXT => {
  if (isFinalProcess) {
    return 'final_process';
  }

  if (hasConnections) {
    return 'has_connections';
  }

  return 'default';
};

export const getHandleStyle = (
  type: 'target' | 'source',
  hasProperConnection = true
): CSSProperties => {
  if (type === 'target') {
    return {
      top: '0',
      left: '0',
      opacity: '0',
      zIndex: hasProperConnection ? '1' : '0',
      border: '0',
      position: 'absolute',
      width: '100%',
      height: '100%',
      transform: 'none'
    };
  }

  return {
    boxSizing: 'border-box',
    backgroundColor: hasProperConnection ? '#EAEAEA' : '#FFFF',
    width: hasProperConnection ? '0.5rem' : '0.75rem',
    height: hasProperConnection ? '0.5rem' : '0.75rem',
    border: hasProperConnection ? '0px solid transparent' : '2px solid #D8D1D1'
  };
};
