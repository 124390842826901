import { z } from 'zod';

export const transportDetailSchema = z.object({
  name: z.string().min(1, 'required'),
  transports: z.array(
    z.object({
      name: z.string(),
      supplier: z.string().nullish(),
      country: z.string().nullish(),
      type: z.string(),
      value: z.string().min(1, 'required'),
      impact_source_id: z.string().min(1, 'required')
    })
  )
});

export type TransportDetail = z.infer<typeof transportDetailSchema>;
