import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSuppliers, getRdCodesByLowId } from 'services/api/waste';

const useGetData = () => {
  const { t } = useTranslation();
  const [rdCodes, setRdCodes] = useState<any[]>([]);
  const [suppliers, setSuppliers] = useState<SelectOptionFormat[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const response = await getRdCodesByLowId({ lowId: undefined });
      if (response.status >= 400) return;
      setRdCodes(
        response.map((elem: { rd_id: string; rd_name: string; rd_code: string }) => ({
          id: elem.rd_id,
          name: `${elem.rd_code} ${t(`rd_codes.${elem.rd_name}`)}`
        }))
      );
      const data = await getSuppliers();
      const mappedSuppliers = data.map((provider: any) => ({
        id: provider,
        name: provider
      }));

      setSuppliers(mappedSuppliers);
      setLoading(false);
    };

    getData();
  }, []);

  return {
    rdCodes,
    suppliers,
    loading
  };
};

export default useGetData;
