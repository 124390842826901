import { CreateDatapoinSourceDatatBody } from 'services/api/sotOrganizationData';
import { EsgMetricsEnhanced } from '../types/entities/esgMetrics';

export const adaptDatapoint = (dataArray: BackendDatapoint[]): FrontendDatapoint[] => {
  return dataArray?.map((data) => ({
    assignedTo: {
      id: data.assigned_to.id,
      firstName: data.assigned_to.first_name,
      lastName: data.assigned_to.last_name
    },
    category: data.category,
    datapoint_id: data.data_point_id,
    dataType: data.data_type,
    description: data.description,
    endDate: data.end_date,
    esgCategory: data.esg_category,
    esgSubcategory: data.esg_subcategory,
    frameworks: data.frameworks,
    id: data.id,
    datapoint: data.name,
    responsibleTaskId: data.responsible_task_id,
    startDate: data.start_date,
    status: data.status,
    type: data.type,
    value: data.value,
    shortName: data.short_name,
    relevance: data.relevance
  }));
};

export const parseDatapointToBackend = (data: EsgMetricsEnhanced): any => {
  return {
    value: data.value,
    description: data.description,
    start_date: data.start_date.replaceAll('/', '-'),
    end_date: data.end_date.replaceAll('/', '-'),
    data_point_id: data.id,
    relevance: data.relevance,
    file_urls: data.file_urls ?? [],
    value_unit_id: data.value_unit_id
  };
};

interface DatapointSourceDataCreate {
  base_data_id: string;
  start_date: string;
  end_date?: string;
  value: string | boolean | File | undefined;
  description?: string;
  data_type: string;
  file_urls?: string[];
  unit_id: string;
  newFiles: File[];
}

export const parseDatapointOutputDataToBackend = (
  data: DatapointSourceDataCreate
): CreateDatapoinSourceDatatBody => ({
  base_data_id: data.base_data_id,
  start_date: data.start_date,
  end_date: data.end_date,
  value: data.value,
  description: data.description,
  data_type: data.data_type,
  unit_id: data.unit_id,
  file_urls: data.file_urls,
  newFiles: data.newFiles
});
