import { useInternalNode } from '@xyflow/react';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import type { HoldingNode } from 'types/entities/holdings';
import { useCreateOrganizationBlock } from '../../hooks/useCreateOrganizationBlock';
import { adapterCreateOrganizationBlock } from 'adapters/adaptHoldingBlocks';

const VARIANTS = {
  default: {
    btnLookAndFeel: 'secondary',
    iconColor: 'gray-dark'
  },
  active: {
    btnLookAndFeel: 'primary',
    iconColor: 'white'
  }
} as const;

const getVariant = (isFirstNode?: boolean) => {
  if (isFirstNode) {
    return VARIANTS.active;
  }

  return VARIANTS.default;
};

type Props = {
  node_id: string;
  isFirstNode?: boolean;
};

export const CreateNodeBTN: React.FC<Props> = ({ node_id, isFirstNode }) => {
  const nodeInfo = useInternalNode<HoldingNode>(node_id);
  const { createOrganizationBlock, isPending } = useCreateOrganizationBlock();

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!nodeInfo || isPending) {
      return;
    }

    createOrganizationBlock(
      adapterCreateOrganizationBlock({
        source_id: node_id,
        source_x: nodeInfo?.position.x,
        source_y: nodeInfo?.position.y
      })
    );
  };

  const variant = getVariant(isFirstNode);

  return (
    <Button
      lookAndFeel={variant.btnLookAndFeel}
      style={{
        position: 'absolute',
        width: '2rem',
        height: '2rem',
        bottom: '-2.5rem',
        right: 'calc(50% - 1rem)'
      }}
      loading={isPending}
      onClick={onClick}
      iconNode={!isPending && <Icon icon='add' color={variant.iconColor} size='small' />}
      size='small'
    />
  );
};
