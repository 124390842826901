type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  return <div className='px-1 py-2 flex gap-x-1'>{children}</div>;
};

type TextProps = {
  used: number;
  total: number;
};

const Text: React.FC<TextProps> = ({ used, total }) => {
  return (
    <p className='font-12 weight-600 inter p-0 m-0'>
      <span className='text-gradient'>{used}</span>
      <span className='text-neutral-gray-20'>/{total}</span>
    </p>
  );
};

export const Legend = {
  Layout,
  Text
};
