import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../../../layout/breadcrumb/Breadcrumb';
import { useTranslation } from 'react-i18next';
import CardCTA from '../../../../ui/cards/cardCTA/CardCTA';
import Button from '../../../../ui/button/Button';
import Modal from '../../../../ui/modal/Modal';
import './styles.scss';
import TotalLegendV2 from '../../../../ui/totalLegend/TotalLegendV2';
import { replaceStrongTags } from '../../../../../utils/stringToBold';
import UpdateInvestment from './updateInvestment/UpdateInvestment';
import { getFundsRelationsHistoric } from '../../../../../services/api/funds';
import { SIZE_PAGINATION_SMALL } from '../../../../../constants';
import {
  IBackendFundRelation,
  IBackendFundRelationHistoric,
  IFrontendFundRelationHistoric
} from '../../../../../types/entities/organization';
import {
  adaptFundRelationBackToFront,
  adaptFundRelationHistoricBackToFront
} from '../../../../../adapters/matrices';
import useFetchInfiniteList from '../../../../ui/infiniteList/hooks/useFetchInfiniteList';
import InfiniteList from '../../../../ui/infiniteList/InfiniteListV2';
import useColumns from './hooks/useColumns';
import Label from '../../../../ui/label/Label';
import ButtonDropdown from '../../../../ui/buttonDropdown/ButtonDropdown';
import Icon from '../../../../ui/icon/Icon';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { GroupFunds } from '../../../../../types/entities/groupFunds';
import RequestAccess from '../../groupFunds/companiesGroupFunds/requestAccess/RequestAccess';
import { switchOrganization } from '../../../../../actions/auth';
import { ROUTES } from '../../../../../constants/routes';

const CompanyInvestments = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const name = searchParams.get('name');

  const role = searchParams.get('role');

  const [showUpdateInvestment, setShowUpdateInvestment] = useState(false);

  const [investmentToEdit, setInvestmentToEdit] = useState<IFrontendFundRelationHistoric>();

  const [companyToRequestAccess, setCompanyToRequestAccess] = useState<GroupFunds>();

  const dispatch = useDispatch();

  const columns = useColumns();

  const fetch = async (page: number) => {
    if (!id) return;
    const response = await getFundsRelationsHistoric(id, page, SIZE_PAGINATION_SMALL);
    if (response?.response?.status >= 400) return;

    const itemsAdapted = response.items.map((item: IBackendFundRelationHistoric) =>
      adaptFundRelationHistoricBackToFront(item)
    );
    return {
      ...response,
      items: itemsAdapted
    };
  };

  const { fetchData, data, addElement, editElement, total } = useFetchInfiniteList(fetch, []);

  const parseData = (data: IFrontendFundRelationHistoric[]) => {
    return data.map((item) => {
      return {
        ...item,
        tag: item.tag ? <Label lookAndFeel='secondary'>{item.tag}</Label> : '',
        edit: (
          <ButtonDropdown
            button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
            options={[
              {
                id: 'edit',
                name: t('companyDetail.companyGroupFund.edit'),
                onClick: () => setInvestmentToEdit(item)
              }
            ]}
          />
        )
      };
    });
  };

  const handleUpdateInvestment = (
    data: IBackendFundRelation,
    fundName: string,
    investmentId?: string
  ) => {
    const adaptedItem = adaptFundRelationBackToFront(data);

    if (investmentId) {
      editElement({
        ...adaptedItem,
        fundName,
        id: investmentId
      });
    } else {
      addElement({
        ...adaptedItem,
        fundName
      });
    }
    setShowUpdateInvestment(false);
    setInvestmentToEdit(undefined);

    dispatch(setNotification(t('notification.investmentUpdated')));
  };

  if (!id) return null;
  return (
    <section className='company-detail-group-fund'>
      <div className='companies__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('companies.title')}</h3>
        <Breadcrumb crumbsReplace={id ? [{ key: id, value: name ?? '' }] : []} />
      </div>
      <CardCTA style={{ marginTop: '1rem' }}>
        <CardCTA.Header>
          <span className='headline4-font'>{t('companyDetail.companyGroupFund.title')}</span>
          <span className='subtitle3-font'>
            {replaceStrongTags(
              t('companyDetail.companyGroupFund.description', {
                replace: {
                  name: name ?? ''
                }
              })
            )}
          </span>
        </CardCTA.Header>
        <CardCTA.Buttons>
          <Button
            lookAndFeel='primary'
            text={t('companyDetail.companyGroupFund.updateInvestment')}
            onClick={() => setShowUpdateInvestment(true)}
            size='small'
          />
          {role !== 'fund' ? (
            <Button
              lookAndFeel='secondary'
              text={t('companyDetail.companyGroupFund.requestAccess')}
              onClick={() =>
                setCompanyToRequestAccess({
                  child_id: id,
                  child_name: name ?? '',
                  status: 'linked',
                  enterprise_value: '',
                  data_progress: '',
                  country: '',
                  role: ''
                })
              }
              size='small'
            />
          ) : (
            <Button
              lookAndFeel='secondary'
              text={t('companyDetail.companyGroupFund.access')}
              onClick={async () => {
                await dispatch(switchOrganization(id));
                navigate(ROUTES.IMPROVE_DASHBOARD);
                window.location.reload();
              }}
              size='small'
            />
          )}
        </CardCTA.Buttons>
      </CardCTA>

      <InfiniteList
        header={
          <TotalLegendV2
            totalLabel='Total'
            totalElements={[
              { value: total, label: t('companyDetail.companyGroupFund.investments') }
            ]}
            style={{ marginLeft: 'auto' }}
          />
        }
        data={parseData(data)}
        columns={columns}
        loading={false}
        fetchData={fetchData}
      />
      <Modal
        show={showUpdateInvestment || !!investmentToEdit}
        onClose={() => {
          setShowUpdateInvestment(false);
          setInvestmentToEdit(undefined);
        }}
        width='600px'
        maxWidth='600px'>
        <UpdateInvestment
          onSubmit={handleUpdateInvestment}
          onClose={() => {
            setShowUpdateInvestment(false);
            setInvestmentToEdit(undefined);
          }}
          formDataFilled={
            investmentToEdit
              ? {
                  fund: {
                    id: investmentToEdit?.fundId,
                    name: investmentToEdit?.fundName
                  },
                  investment: investmentToEdit?.investmentValue.toString() ?? '',
                  isDebtFinancing: false,
                  companyValue: investmentToEdit?.enterpriseValue.toString() ?? '',
                  investmentDate: investmentToEdit?.investmentDate ?? '',
                  tag: investmentToEdit?.tag ?? '',
                  errors: []
                }
              : undefined
          }
          investmentId={investmentToEdit?.id ?? undefined}
          fundId={investmentToEdit?.fundId ?? undefined}
        />
      </Modal>
      <Modal
        show={!!companyToRequestAccess}
        onClose={() => setCompanyToRequestAccess(undefined)}
        width='584px'
        maxWidth='584px'>
        {companyToRequestAccess && (
          <RequestAccess
            company={companyToRequestAccess}
            handleClose={() => setCompanyToRequestAccess(undefined)}
          />
        )}
      </Modal>
    </section>
  );
};

export default CompanyInvestments;
