import { useQuery } from '@tanstack/react-query';
import { adapterContractLimits } from 'adapters/adaptContract';
import { getContractLimits } from 'services/api/holdings';

type Params = {
  canvas_org_id?: string;
  organization_id?: string;
};

export const useContractLimits = ({ canvas_org_id, organization_id }: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: ['get-contract-limits', canvas_org_id, organization_id],
    queryFn: getContractLimits,
    select: adapterContractLimits,
    enabled: !!canvas_org_id
  });

  return {
    data,
    isLoading
  };
};
