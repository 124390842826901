import { CSSProperties } from 'react';

export const getSourceHandleStyle = (isHovered: boolean, isInChildren?: boolean): CSSProperties => {
  if (!isHovered || isInChildren) {
    return {
      boxSizing: 'border-box',
      left: '2rem',
      backgroundColor: 'transparent',
      width: '0.75rem',
      height: '0.75rem',
      border: '2px solid transparent'
    };
  }
  return {
    boxSizing: 'border-box',
    left: '2rem',
    backgroundColor: '#FFFF',
    width: '0.75rem',
    height: '0.75rem',
    border: '2px solid #D8D1D1'
  };
};
