import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { createConnection } from 'services/api/holdings';
import { useContractParent } from './useContractParent';
import type {
  GetHoldingBlocksQueryKey,
  GetHoldingConnectionsQueryKey,
  HoldingConnectionBack,
  OrganizationBlockBack
} from 'types/entities/holdings';
import { useDispatch } from 'react-redux';
import { loadUser } from 'actions/auth';
import { Session } from 'types/entities/user';
import { authParseLoginCallback } from 'services/authService';

export const useCreateConnection = () => {
  const queryClient = useQueryClient();
  const org = useSelectedOrganization();
  const { data: contractOrgId } = useContractParent(org?.id);
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: createConnection,
    onMutate: ({ source_id, target_id }) => {
      const connections_snapshot = queryClient.getQueryData<
        HoldingConnectionBack[],
        GetHoldingConnectionsQueryKey
      >(['holding-connections', contractOrgId, org?.id]);

      const blocks_snapshot = queryClient.getQueryData<
        OrganizationBlockBack[],
        GetHoldingBlocksQueryKey
      >(['holding-blocks', contractOrgId, 'connections', org?.id]);

      const target = blocks_snapshot?.find((block) => block.node_id === target_id);

      const data = {
        source_id,
        target_id,
        share: target?.org_id ? 1 : null
      };

      queryClient.setQueryData<HoldingConnectionBack[], GetHoldingConnectionsQueryKey>(
        ['holding-connections', contractOrgId, org?.id],
        (oldData) => {
          if (!oldData) {
            return [data];
          }

          return [...oldData, data];
        }
      );

      queryClient.setQueryData<OrganizationBlockBack[], GetHoldingBlocksQueryKey>(
        ['holding-blocks', contractOrgId, 'connections', org?.id],
        (oldData) => {
          return oldData?.map((block) => {
            if (block.node_id === data.source_id) {
              return {
                ...block,
                outputs: block.outputs ? [...block.outputs, data.target_id] : [data.target_id]
              };
            }

            if (block.node_id === data.target_id) {
              return {
                ...block,
                inputs: block.inputs ? [...block.inputs, data.source_id] : [data.source_id]
              };
            }

            return block;
          });
        }
      );

      return { connections_snapshot, blocks_snapshot };
    },
    onError: (_error, _variables, context) => {
      if (context) {
        queryClient.setQueryData(
          ['holding-connections', contractOrgId, org?.id],
          context.connections_snapshot
        );

        queryClient.setQueryData(
          ['holding-blocks', contractOrgId, 'connections', org?.id],
          context.blocks_snapshot
        );
      }
    },
    onSuccess: async () => {
      const sessionData: Session = await authParseLoginCallback();

      dispatch(loadUser(sessionData, false, false, contractOrgId));
    }
  });

  return { createConnection: mutate };
};
