import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../ui/button/Button';
import './styles.scss';
import { User } from '../../../../../types/entities/user';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import { useUnbindCompany } from './useUnbindCompany';

type Props = {
  unbindCompany: (id: string) => void;
  companyToUnbind: string;
  user: User | null;
};
function UnbindCompany({ user, companyToUnbind, unbindCompany }: Props) {
  const { t } = useTranslation();

  const { unbindCompany: requestUnbindCompany } = useUnbindCompany();

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleUnbindCompany();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const handleUnbindCompany = async () => {
    try {
      setLoadingButton(true);

      await requestUnbindCompany({
        id: companyToUnbind,
        parent_id: user?.selectedOrganization ?? ''
      });

      unbindCompany(companyToUnbind);
      setLoadingButton(false);
    } catch (err) {
      setLoadingButton(false);
    }
  };

  return (
    <div className='unbind-company'>
      <FormHeader
        title={t('companies.unbindCompany')}
        description={t('companies.areYouSureUnbind')}
      />
      <FormButtonSection>
        <Button
          lookAndFeel='warning'
          text={t('companies.unbind')}
          onClick={handleUnbindCompany}
          loading={loadingButton}
          size='small'
        />
      </FormButtonSection>
    </div>
  );
}

export default UnbindCompany;
