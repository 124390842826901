import { Edge, Node } from '@xyflow/react';
import { ScenarioNode } from '../types/LCAScenarios';
import { NODE_TYPES } from '../constants';

const isNotDeleteableTransportEdge =
  (nodesMap: Map<string, ScenarioNode>) =>
  (edge: Edge): boolean => {
    const sourceNode = nodesMap.get(edge.source);

    if (sourceNode?.type !== NODE_TYPES.transport) {
      return false; // is not a transport node, so it is deletable
    }

    return sourceNode.data.inputs.length !== 0;
  };

export const isDeletableEdge = (
  nodes: Node[],
  edges: Edge[],
  nodesMap: Map<string, ScenarioNode>
): boolean => {
  if (nodes.length) {
    return false;
  }

  if (edges?.some((edge) => nodesMap.get(edge.target)?.type === NODE_TYPES.product)) {
    return false;
  }

  if (edges?.some(isNotDeleteableTransportEdge(nodesMap))) {
    return false;
  }

  return true;
};
