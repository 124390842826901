import { useQuery } from '@tanstack/react-query';
import { adapterUsedEntities } from 'adapters/adaptOrganizations';
import { getUsedEntities } from 'services/api/holdings';

type Params = {
  canvas_org_id?: string;
  organization_id?: string;
};

export const useEntitiesUsed = ({ canvas_org_id, organization_id }: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: ['used-entities', canvas_org_id, organization_id],
    queryFn: getUsedEntities,
    select: adapterUsedEntities,
    enabled: Boolean(canvas_org_id) && Boolean(organization_id) && canvas_org_id === organization_id
  });

  return { data, isLoading };
};
