import {
  Organization,
  OrganizationFiscalYearPatchFront,
  OrganizationPatchPayload
} from 'types/entities/organization';

export const adapterFiscalYear = (org?: Organization): ReportingPeriod => {
  return {
    start_month: org?.fiscal_year_start_month ?? 0,
    end_month: org?.fiscal_year_end_month ?? 0
  };
};

export const adapterFiscalYearForUpdateOrg = (
  payloadFront: OrganizationFiscalYearPatchFront
): OrganizationPatchPayload => {
  return {
    id: payloadFront.organizationId,
    fiscal_year_start_month: payloadFront.fiscalYear.start_month
  };
};
