import {
  HoldingConnection,
  HoldingConnectionBack,
  HoldingEdge,
  PayloadPatchShare,
  PayloadPatchShareFront,
  ShareFormValues
} from 'types/entities/holdings';

export const adaptHoldingConnection = (connection: HoldingConnectionBack): HoldingConnection => {
  return {
    source_id: connection.source_id,
    target_id: connection.target_id,
    share: connection.share
  };
};

export const adaptHoldingConnections = (
  connections: HoldingConnectionBack[]
): HoldingConnection[] => {
  return connections.map(adaptHoldingConnection);
};

export const adapterHoldingEdgeToShareFormValues = (edge?: HoldingEdge): ShareFormValues => {
  if (edge?.data?.share === undefined || edge?.data?.share === null) {
    return {
      share: '0'
    };
  }

  return {
    share: (edge.data.share * 100).toString()
  };
};

export const adapterShareFormValuesToPatchPayload = (
  payload: PayloadPatchShareFront
): PayloadPatchShare => {
  return {
    source_id: payload.source_id,
    target_id: payload.target_id,
    share: Number(payload.formValues.share) / 100
  };
};
