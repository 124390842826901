import { MutationFunction, QueryFunction } from '@tanstack/react-query';
import apiFetch from 'utils/apiFetch';

export const getLCATransportMetadata: QueryFunction<
  LCATransportMetadata,
  LCATransportMetadataQueryKey
> = async ({ queryKey }) => {
  const [_, acv_id, node_id] = queryKey;

  const response = await apiFetch('GET', `/lca/${acv_id}/transport/metadata/${node_id}`);

  return response.data;
};

export const findLCATransportUpdates: MutationFunction<
  FindLCATransportUpdatesResponse,
  FindLCATransportUpdatesParams
> = async ({ acv_id, node_id }) => {
  const response = await apiFetch('GET', `/lca/${acv_id}/transport/new_materials/${node_id}`);

  return response.data;
};

export const getLCATransportDetails: QueryFunction<
  LCATransportDetailsResponse,
  LCATransportDetailsQueryKey
> = async ({ queryKey }) => {
  const [_, acv_id, node_id] = queryKey;

  const response = await apiFetch('GET', `/lca/${acv_id}/transport/details/${node_id}`);

  return response.data;
};

export const updateLCATransportDetails = async ({
  acv_id,
  node_id,
  data
}: UpdateLCATransportDetailsParams): Promise<LCATransportDetailsResponse> => {
  const response = await apiFetch('PATCH', `/lca/${acv_id}/transport/details/${node_id}`, data);

  return response.data;
};
