import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateLCATransportDetails } from 'services/api/lca/transport';

export const useUpdateLCATransportDetails = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: updateLCATransportDetails,
    onSuccess: (response, { acv_id, node_id }) => {
      queryClient.setQueryData<LCATransportDetailsResponse, LCATransportDetailsQueryKey>(
        ['lca-transport-details', acv_id, node_id],
        response
      );

      queryClient.invalidateQueries({ queryKey: ['lca-transport', acv_id, node_id] });
      queryClient.resetQueries({ queryKey: ['lcaImpacts'] });
    }
  });

  return { updateLCATransportDetails: mutateAsync, isUpdating: isPending };
};
