import { z } from 'zod';
import { ContractLimitFront } from '../constants';
import type { OrganizationFormValues } from 'types/entities/holdings';

export const organizationSchema = z.object({
  name: z.string().min(1, 'required'),
  country: z.string().min(1, 'required'),
  cif: z.string().min(1, 'required'),
  sector: z.string().min(1, 'required'),
  limit_facilities: z.string().min(1, 'required'),
  limit_vehicles: z.string().min(1, 'required'),
  limit_employees: z.string().min(1, 'required')
});

const getAmountSurpassing = (value: string, totalAvailable: number, defaultLimitNum: number) => {
  const amountAvailable = totalAvailable + defaultLimitNum;

  const numVal = Number(value);

  return numVal - amountAvailable;
};

const validateLimit = (
  contractLimit: ContractLimitFront[keyof ContractLimitFront],
  defaultLimit: string
) => {
  const defaultLimitNum = Number(defaultLimit);
  const max = contractLimit.totalAvailable + defaultLimitNum;

  return z
    .string()
    .min(1, 'required')
    .refine((value) => {
      return !isNaN(Number(value));
    }, 'isNumber')
    .refine(
      (value) => {
        if (contractLimit.totalAvailable >= 0) {
          return true;
        }

        const amountSurpassing = getAmountSurpassing(
          value,
          contractLimit.totalAvailable,
          defaultLimitNum
        );

        return amountSurpassing <= 0;
      },
      (arg) => {
        const amountSurpassing = getAmountSurpassing(
          arg,
          contractLimit.totalAvailable,
          defaultLimitNum
        );

        return {
          message: `isSurpassingLimit::{"count":${amountSurpassing}}`
        };
      }
    )
    .refine((value) => {
      const valueNum = Number(value);

      return valueNum <= max;
    }, `isSurpassingLimit::{"count":${max}}`);
};

const validateCif = (usedCifs: string[]) => {
  return z
    .string()
    .min(1, 'required')
    .refine((value) => {
      return !usedCifs.includes(value);
    }, 'cifExists');
};

export const generateOrganizationSchema = (
  defaultValues: OrganizationFormValues,
  usedCifs: string[],
  contractLimits?: ContractLimitFront
) => {
  if (!contractLimits) {
    return organizationSchema;
  }

  return organizationSchema.extend({
    limit_facilities: validateLimit(contractLimits.facilities, defaultValues.limit_facilities),
    limit_vehicles: validateLimit(contractLimits.vehicles, defaultValues.limit_vehicles),
    limit_employees: validateLimit(contractLimits.employees, defaultValues.limit_employees),
    cif: validateCif(usedCifs)
  });
};
