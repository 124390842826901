import { create } from 'zustand';
import {
  createLCAMaterialsLoaderSlice,
  LCAMaterialsLoaderSlice
} from './slices/lcaMaterialsLoader';

type Store = LCAMaterialsLoaderSlice;

export const useStore = create<Store>()((set) => ({
  ...createLCAMaterialsLoaderSlice((newVal) => set({ lcaMaterialsLoader: newVal }))
}));
