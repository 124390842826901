import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SIZE_PAGINATION_BIG } from '../../../../constants';
import { ROUTES } from '../../../../constants/routes';
import { FilterDatesContext } from '../../../../context/filterDatesContext';
import { NotificationContext } from '../../../../context/notificationContext';
import { UserContext } from '../../../../context/userContext';
import usePaginatedList from '../../../../customHooks/paginatedList';
import { updateVehicle } from '../../../../services/api/vehicle';
import { Vehicle } from '../../../../types/entities/vehicle';
import { InputSize } from '../../../../types/utilsEnums/input';
import { getUrl } from '../../../../utils/url';
import { FilterSection } from '../../../layout/NewFilters/FilterSection';
import { FilterText } from '../../../layout/NewFilters/FilterText';
import { Filters } from '../../../layout/NewFilters/Filters';
import FilterHandlers from '../../../layout/NewFilters/FiltersHandlers';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import Button from '../../../ui/button/Button';
import CardList from '../../../ui/cardList/CardListRefactored';
import CardsFilter from '../../../ui/filter/CardsFilter';
import Icon from '../../../ui/icon/Icon';
import LoaderTables from '../../../ui/loaders/loaderTables/LoaderTables';
import Modal from '../../../ui/modal/Modal';
import Sorting from '../../../ui/sorting/Sorting';
import useOnSort from '../../../ui/sorting/hooks/useOnSort';
import TooltipWrapper from '../../../ui/tooltip/TooltipWrapper';
import TotalLegend from '../../../ui/totalLegend/TotalLegend';
import ArchiveVehicle from '../archiveVehicle/ArchiveVehicle';
import CreateVehicle from '../createVehicle/CreateVehicle';
import DeleteVehicle from '../deleteVehicle/DeleteVehicle';
import EditVehicle from '../editVehicle/EditVehicle';
import LimitCompleted from '../limitCompleted/LimitCompleted';
import UploadConsumptions from '../uploadConsumptions/UploadConsumptions';
import InputNuvoConsumptions from './components/nuvo/InputNuvoConsumptions';
import InputNuvoVehicles from './components/nuvo/InputNuvoVehicles';
import VehicleCard from './components/vehicleCard/VehicleCard';
import { useFilters } from './hooks/useFilters';
import useSortingOptions from './hooks/useSortingOptions';
import './styles.scss';

const BASE_URL = '/vehicles';

function Vehicles() {
  const { t } = useTranslation();
  const { dateError } = useContext(FilterDatesContext);

  const setNotification = useContext(NotificationContext);
  const dispatch = useDispatch();
  const filtersForVehicles = useFilters();

  const sortingOptions = useSortingOptions();

  const defaultSort = {
    ...sortingOptions[0],
    direction: 'desc' as 'desc' | 'asc'
  };

  const [showCreateVehicle, setShowCreateVehicle] = useState(false);
  const [showUploadConsumptions, setShowUploadConsumption] = useState(false);
  const [vehicleToEdit, setVehicleToEdit] = useState<Vehicle>();
  const [vehicleToDelete, setVehicleToDelete] = useState('');
  const [vehicleToArchive, setVehicleToArchive] = useState<Vehicle>();

  const [loading, setLoading] = useState(true);
  const [listView, setListView] = useState(false);
  const [open, setOpen] = useState(false);

  const [filters, setFilters] = useState<IFilter[]>([
    {
      field: 'status',
      value: ['active', 'error'],
      type: 'in'
    }
  ]);

  const [sorters, setSorters] = useState<ISorter[]>([
    {
      field: defaultSort.id,
      order: defaultSort.direction
    }
  ]);
  const [url, setUrl] = useState('');

  const [showArchivedVehicles, setShowArchivedVehicles] = useState(false);
  const [showLimitCompleted, setShowLimitCompleted] = useState(false);

  // Filters

  const onSort = useOnSort({ url, setUrl });

  useEffect(() => {
    const foundSearchFilter = filters.find((filter) => filter.field === 'search');

    const searchValue = foundSearchFilter ? foundSearchFilter.value : '';
    const urlParsed = getUrl(BASE_URL, {
      filters,
      sorters,
      queryParams: [{ key: 'search', value: searchValue?.toString() }]
    });

    setUrl(urlParsed);
  }, [JSON.stringify(filters)]);

  const size = SIZE_PAGINATION_BIG;

  const navigate = useNavigate();
  const user = useContext(UserContext);
  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  // fetchPagination
  const {
    fetchData,
    fetchMoreData,
    hasMore,
    values,
    setValues: setVehicles,
    total,
    setTotal,
    total2,
    setTotal2,
    page
  } = usePaginatedList({
    url,
    listSize: size,
    setLoading,
    organization: user?.selectedOrganization,
    total2Key: 'total_archived'
  });

  const vehicles: Vehicle[] = values;

  const sideBarFilters = [
    {
      options: filtersForVehicles?.ownership,
      title: t('vehicles.vehicleOwnership'),
      elToShow: 0
    },
    {
      options: filtersForVehicles?.unknownVehicles,
      title: t('vehicles.vehicleType'),
      elToShow: 0
    },
    {
      options: filtersForVehicles?.fuelVehicles,
      title: t('vehicles.fuel'),
      elToShow: 0
    }
  ];

  useEffect(() => {
    if (dateError) return;
    if (!url || !url.includes('filter_by')) return;
    fetchData();
  }, [url]);

  const [sideFilters, setSideFilters] = useState<any>([
    {
      id: 'active',
      name: '',
      type: 'status'
    }
  ]);

  const handleShowCreateVehicle = () => {
    setShowCreateVehicle(true);
  };

  const handleHideCreateVehicle = () => {
    setShowCreateVehicle(false);
  };

  const handleHideUploadConsumptions = () => {
    setShowUploadConsumption(false);
  };

  const onClickCard = (id: string) => {
    navigate(`${ROUTES.MEASURE_VEHICLES}/${id}`);
  };

  const handleHideEditVehicle = () => {
    setVehicleToEdit(undefined);
  };

  const handleHideDeleteVehicle = () => {
    setVehicleToDelete('');
  };

  const addVehicle = (value: Vehicle) => {
    let newVehicles: Vehicle[] = [];
    if (vehicles) {
      newVehicles = [...vehicles];
    }
    newVehicles.unshift(value);
    setVehicles(newVehicles);
    dispatch(setNotification(t('notification.createVehicle')));
    handleHideCreateVehicle();
    setTotal(total + 1);
  };

  const editVehicle = (value: Vehicle, id: string) => {
    const newVehicles = vehicles ? [...vehicles] : [];
    const indexVehicleFound = newVehicles.findIndex((elem) => elem.id === id);
    if (indexVehicleFound > -1) {
      newVehicles[indexVehicleFound] = value;
    }

    setVehicles(newVehicles);
    dispatch(setNotification(t('notification.editVehicle')));

    handleHideEditVehicle();
  };

  const deleteVehicle = (id: string) => {
    const newVehicles = vehicles ? [...vehicles] : [];
    const indexVehicleFound = newVehicles.findIndex((elem) => elem.id === id);
    if (indexVehicleFound > -1) {
      newVehicles.splice(indexVehicleFound, 1);
    }

    setVehicles(newVehicles);
    dispatch(setNotification(t('notification.deleteVehicle')));
    handleHideDeleteVehicle();
    setTotal(total - 1);
  };

  const handleChangeListView = () => {
    setListView(!listView);
  };

  const handleEditVehicle = (id: string) => {
    const foundVehicle = vehicles?.find((vehicle) => vehicle.id === id);
    if (foundVehicle) {
      setVehicleToEdit(foundVehicle);
    }
  };

  const handleUploadConsumptions = () => {
    handleHideUploadConsumptions();
    dispatch(setNotification(t('notification.uploadConsumptionsSuccess')));
  };

  const handleGoToFiles = (category?: string) => () => {
    if (!category) return;
    navigate(`${ROUTES.MEASURE_UPLOADED_FILES}/${category}`);
  };

  const handleArchive = (id: string) => {
    // Change status of vehicle to 'archive', no need to remove it from list
    const newVehicles = vehicles ? [...vehicles] : [];
    const indexVehicleFound = newVehicles.findIndex((elem) => elem.id === id);
    if (indexVehicleFound > -1) {
      // remove vehicle from list
      newVehicles.splice(indexVehicleFound, 1);
    }

    setVehicles(newVehicles);
    dispatch(setNotification(t('notification.archiveVehicle')));
    setVehicleToArchive(undefined);
    setTotal(total - 1);
    setTotal2(total2 + 1);
  };

  const handleDearchiveVehicle = async (id: string) => {
    if (foundOrganization?.limit_vehicles && total >= foundOrganization?.limit_vehicles) {
      setShowLimitCompleted(true);
      return;
    }
    // Change status of vehicle to 'active', and remove it from list
    const response = await updateVehicle(id, { status: 'active' });
    if (!response) return;
    const newVehicles = vehicles ? [...vehicles] : [];
    const indexVehicleFound = newVehicles.findIndex((elem) => elem.id === id);
    if (indexVehicleFound > -1) {
      // remove vehicle from list
      newVehicles.splice(indexVehicleFound, 1);
    }
    setVehicles(newVehicles);
    dispatch(setNotification(t('notification.dearchiveVehicle')));
    setTotal(total + 1);
    setTotal2(total2 - 1);
  };

  if (!foundOrganization) {
    return null;
  }

  const typeFiltersObj: Set<string> = new Set();
  sideBarFilters?.map((section) => {
    Object.values(section.options).map((elem: any) => {
      typeFiltersObj.add(elem.type);
    });
  });

  const fields: string[] = Array.from(typeFiltersObj);

  const handleSetShowArchiveState = () => {
    setShowArchivedVehicles((prev) => !prev);

    const statusValue = !showArchivedVehicles ? ['archived'] : ['active', 'error'];

    const prevFilters = [...sideFilters];

    const indexFilterFound = prevFilters.findIndex((elem) => elem.type === 'status');

    if (indexFilterFound === -1) {
      prevFilters.push({ field: 'status', value: statusValue, type: 'in' });
    } else {
      prevFilters[indexFilterFound] = { field: 'status', value: statusValue, type: 'in' };
    }

    setFilters(prevFilters);
  };

  const dropdownOptionsGetter = (vehicle: Vehicle) => [
    {
      id: 'dearchive',
      name: t('vehicles.dearchive'),
      onClick: () => handleDearchiveVehicle(vehicle.id)
    },

    {
      id: 'edit',
      name: t('vehicles.edit'),
      onClick: () => handleEditVehicle(vehicle.id)
    },
    {
      id: 'archive',
      name: t('vehicles.archive'),
      onClick: () => setVehicleToArchive(vehicle)
    },
    {
      id: 'delete',
      name: t('vehicles.delete'),
      onClick: () => setVehicleToDelete(vehicle.id)
    }
  ];

  const NuvoBTN =
    total >= foundOrganization?.limit_vehicles ? (
      <Button
        lookAndFeel='blocked'
        hasIcon={false}
        text={t('vehicles.automaticUploadVehicles')}
        size='small'
      />
    ) : (
      <InputNuvoVehicles />
    );

  return (
    <section className='vehicles'>
      <Filters.Root setFilters={setFilters} filters={filters} setOpen={setOpen} open={open}>
        <div className='vehicles__header page-header'>
          <h3 className='headline3-font on-light-text-color'>{t('measureMain.title')}</h3>
          <Breadcrumb />
        </div>
        <div className='vehicles__subheader'>
          <CardsFilter listView={listView} onChangeListView={handleChangeListView}>
            <>
              <FilterText
                field='search'
                type='il'
                placeholder={t('vehicles.nameOrLicensePlateSearch')}
                size={InputSize.SMALL}
              />
              <Sorting
                options={sortingOptions}
                label={t('input.sorting.orderBy')}
                placeholder={t('input.placeholderSelect')}
                onSort={onSort}
                defaultSort={defaultSort}
              />
              <Filters.Menu>
                {sideBarFilters?.map((section, idx) => (
                  <FilterSection.Multiple
                    key={`section-${idx}`}
                    title={section.title}
                    field={fields[idx]}
                    type='in'
                    options={Object.values(section.options).map((elem: any) => ({
                      label: elem?.name,
                      value: elem.id
                    }))}
                  />
                ))}
              </Filters.Menu>
              <FilterHandlers blacklistedFilters={{ all: ['search', 'status'] }} />
            </>
          </CardsFilter>
          <div className='main-bg-color card-border-color legend-wrapper flex'>
            <Button
              lookAndFeel={showArchivedVehicles ? 'primary' : 'secondary'}
              text={t('vehicles.archivedVehicles')}
              size='small'
              iconNode={
                showArchivedVehicles ? (
                  <Icon icon='archive' color='white' />
                ) : (
                  <Icon icon='archive' color='gray-dark' />
                )
              }
              onClick={handleSetShowArchiveState}
            />
            <TotalLegend total={total} loading={loading} i18key={'vehicles'} total2={total2} />
          </div>
        </div>
        <div id={'vehicles'} className='infinite-scroll-wrapper-card'>
          <InfiniteScroll
            dataLength={vehicles ? vehicles.length : 0}
            next={fetchMoreData}
            hasMore={hasMore}
            style={{ overflow: 'visible' }}
            endMessage={<></>}
            loader={undefined}
            scrollableTarget={'vehicles'}>
            <CardList
              information={
                <TooltipWrapper
                  text={t('vehicles.tooltipLimitVehicles', {
                    vehicles: foundOrganization?.limit_vehicles - total,
                    limit: foundOrganization?.limit_vehicles
                  })}>
                  <CardList.Information>{`${total}/${foundOrganization?.limit_vehicles}`}</CardList.Information>
                </TooltipWrapper>
              }
              listView={listView}
              title={t('vehicles.newVehicle')}
              description={t('vehicles.newVehicleDescription')}
              loading={loading && page === 1}
              buttons={
                <div className='flex-col items-center gap-1'>
                  <div style={{ width: '90%' }} className='flex'>
                    {NuvoBTN}
                  </div>
                  <Button
                    hasIcon={false}
                    lookAndFeel={
                      total >= foundOrganization?.limit_vehicles ? 'blocked' : 'secondary'
                    }
                    text={t('vehicles.create')}
                    onClick={handleShowCreateVehicle}
                    size='small'
                    style={{ width: '90%' }}
                  />
                </div>
              }>
              <div className='card'>
                <div className='create-new-card card-border-color flex flex-col items-center justify-center'>
                  <h1 className='headline4-font' style={{ paddingTop: '1rem' }}>
                    {t('vehicles.newConsumption')}
                  </h1>
                  <p className='subtitle3-font' style={{ width: '80%', textAlign: 'center' }}>
                    {t('vehicles.newConsumptionDescription')}
                  </p>
                  <div className='button-wrapper'>
                    <InputNuvoConsumptions />
                  </div>
                </div>
              </div>
              {vehicles?.map((elem) => (
                <VehicleCard
                  key={elem.id}
                  vehicle={elem}
                  listView={listView}
                  onClickCard={onClickCard}
                  dropdownOptions={dropdownOptionsGetter(elem)}
                />
              ))}

              {loading && page > 1 && <LoaderTables mode='list' />}
            </CardList>
          </InfiniteScroll>
        </div>
      </Filters.Root>
      <Modal
        show={showCreateVehicle}
        onClose={handleHideCreateVehicle}
        width='700px'
        maxWidth='700px'>
        <CreateVehicle addVehicle={addVehicle} />
      </Modal>
      <Modal show={!!vehicleToEdit} onClose={handleHideEditVehicle} width='700px' maxWidth='700px'>
        {vehicleToEdit && <EditVehicle editVehicle={editVehicle} vehicleToEdit={vehicleToEdit} />}
      </Modal>
      <Modal
        show={vehicleToDelete !== ''}
        onClose={handleHideDeleteVehicle}
        width='428px'
        maxWidth='428px'>
        <DeleteVehicle
          user={user}
          vehicleToDelete={vehicleToDelete}
          removeVehicle={deleteVehicle}
        />
      </Modal>
      <Modal
        show={showUploadConsumptions}
        onClose={handleHideUploadConsumptions}
        width='700px'
        maxWidth='700px'>
        <UploadConsumptions uploadConsumptions={handleUploadConsumptions} />
      </Modal>
      <Modal
        show={!!vehicleToArchive}
        onClose={() => setVehicleToArchive(undefined)}
        width='428px'
        maxWidth='516px'>
        {vehicleToArchive && (
          <ArchiveVehicle
            vehicleToArchive={vehicleToArchive}
            closeModal={() => setVehicleToArchive(undefined)}
            handleArchive={handleArchive}
          />
        )}
      </Modal>
      <Modal
        show={showLimitCompleted}
        onClose={() => setShowLimitCompleted(false)}
        maxWidth='484px'>
        <LimitCompleted handleClose={() => setShowLimitCompleted(false)} />
      </Modal>
    </section>
  );
}
export default Vehicles;
