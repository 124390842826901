import { Handle, Position, useConnection } from '@xyflow/react';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import { NODE_ICONS, NODE_TYPES } from '../../constants';
import { PieceNode } from './PieceNode';
import { buildPieceFromString } from '../../utils/builders';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import { useLCAProcess } from '../../hooks/useLCAProcess';
import { useParams } from 'react-router-dom';
import { checkMetadata, generateTags, getDeleteStateProcess, getHandleStyle } from './utils';
import { capitalizeFirstLetter } from 'utils/strings';
import { useNodeLCAImpact } from '../../hooks/useNodeLCAImpact';
import { buildLegendUnitFromNodeImpact } from 'components/lca/utils';
import { Toolbar } from './Toolbar';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import { PROCESS_DELETE_TOOLTIP_TEXT } from './constants';
import { DeleteNodeActionBTN } from './ActionButtons/DeleteNodeActionBTN';
import { useHover } from 'customHooks/useHover';
import { REQUIRED_TAGS } from './ProcessNode/constants';
import { useModalContext } from '../ModalHandler';
import { ScenarioNode } from '../../types/LCAScenarios';
import { CustomNode } from './CustomNode';
import { CreateTransportBTN } from '../CreateTransportBTN/CreateTransportBTN';

type ProcessNodeProps = {
  data: ScenarioNode['data'];
  selected?: boolean;
};

const NoReferenceOutput = () => {
  const { t } = useLCATranslation();

  return (
    <CustomNode.Layout disableHover>
      <CustomNode.Content>
        <CustomNode.Icon icon={NODE_ICONS[NODE_TYPES.piece]} disabled />
        <div className='flex-col gap-y-1'>
          <span className='font-body-b2-r text-gray-light'>{t('lcaScenarios.piece')}</span>
          <span className='font-14 weight-700 inter text-neutral-gray-20'>
            {t('lcaDetail.processFlow.processCard.referenceOutput')}
          </span>
        </div>
      </CustomNode.Content>
    </CustomNode.Layout>
  );
};

type ConfigureProcessBTNProps = {
  node_id: string;
  lookAndFeel: 'primary' | 'secondary';
  disabled?: boolean;
};

const ConfigureProcessBTN: React.FC<ConfigureProcessBTNProps> = ({
  node_id,
  lookAndFeel,
  disabled
}) => {
  const { setModalData } = useModalContext();
  const { t } = useLCATranslation();

  const onClick = () => {
    setModalData({
      type: 'edit_process',
      target: node_id
    });
  };

  if (lookAndFeel === 'secondary') {
    return (
      <Button
        lookAndFeel='secondary'
        text={t('lcaDetail.processFlow.processCard.editProcessData')}
        size='small'
        iconNode={<Icon icon='edit' color='gray-dark' size='medium' />}
        onClick={onClick}
        disabled={disabled}
      />
    );
  }

  return (
    <Button
      lookAndFeel='primary'
      text={t('lcaDetail.processFlow.processCard.addProcessData')}
      size='small'
      iconNode={<Icon icon='add' color='white' size='medium' />}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

type ProcessTagProps = {
  disabled: boolean;
  value: string;
};

const ProcessTag = ({ disabled, value }: ProcessTagProps) => {
  const { t } = useLCATranslation();

  if (disabled) {
    return (
      <CustomNode.Tag disabled>
        <Icon icon='warning' color='disabled' size='medium' />
        {disabled && t(`lcaDetail.processFlow.processCard.no${capitalizeFirstLetter(value)}`)}
      </CustomNode.Tag>
    );
  }

  return (
    <CustomNode.Tag>
      <Icon icon='success' color='blocked' size='medium' />
      {t(`lcaDetail.processFlow.processCard.${value}`)}
    </CustomNode.Tag>
  );
};

const CONNECT_NODE_BTN_VARIANTS = {
  primary: {
    lookAndFeel: 'primary',
    color: 'white'
  },
  secondary: {
    lookAndFeel: 'secondary',
    color: 'gray-dark'
  }
} as const;

const getPiece = (piece_name?: string) => {
  if (!piece_name) {
    return null;
  }

  return buildPieceFromString(piece_name);
};

export const ProcessNode: React.FC<ProcessNodeProps> = ({ data, selected }) => {
  const { inProgress } = useConnection();
  const { t } = useLCATranslation();
  const { setModalData } = useModalContext();

  const { id: acv_id } = useParams<{ id: string }>();

  const nodeMetadata = useLCAProcess({ acv_id: acv_id ?? 'none', node_id: data.id });

  const { data: nodeImpact } = useNodeLCAImpact({ node_id: data.id });

  const { isHovered, isActivated, handleMouseEnter, handleMouseLeave, nodeRef } = useHover();

  const onClick = () => {
    setModalData({ type: 'create_node', target: data.id });
  };

  const originsThatNeedsTransport = data.inputs.reduce((accum, curr) => {
    if (curr.type !== NODE_TYPES.transport) {
      accum.push(curr.id);
    }

    return accum;
  }, [] as string[]);

  const connected_product = data?.outputs?.find((output) => output?.type === NODE_TYPES.product);

  const piece = getPiece(nodeMetadata?.reference_output?.name ?? connected_product?.name);

  const tags = generateTags(nodeMetadata, data, NODE_TYPES.process);

  const isMetadataComplete = checkMetadata(nodeMetadata);

  const btnVariant = data?.inputs?.length > 0 || !isMetadataComplete ? 'secondary' : 'primary';

  const hasConnections = data.inputs.length > 0 || data.outputs.length > 0;

  const isFinalProcess = data ? Boolean(connected_product) : true;

  const deleteState = getDeleteStateProcess(hasConnections, isFinalProcess);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={nodeRef}>
      <Handle
        type='target'
        position={Position.Left}
        isConnectableEnd
        isConnectableStart={false}
        style={getHandleStyle('target', inProgress)}
      />
      {originsThatNeedsTransport.length > 0 && (
        <CreateTransportBTN target={data.id} origins={originsThatNeedsTransport} />
      )}
      <TooltipWrapper text={t('lcaDetail.processFlow.processCard.addBlockTooltip')} asChild>
        <Button
          lookAndFeel={CONNECT_NODE_BTN_VARIANTS[btnVariant].lookAndFeel}
          iconNode={<Icon icon='add' color={CONNECT_NODE_BTN_VARIANTS[btnVariant].color} />}
          size='tiny'
          className={btnVariant === 'secondary' ? 'hover:secondary-purple-20' : ''}
          style={{
            position: 'absolute',
            top: 'calc(50% - 20px)',
            width: '28px',
            zIndex: 1,
            boxSizing: 'border-box',
            left: '-43.5px',
            display:
              isActivated || (data?.inputs?.length === 0 && isMetadataComplete) ? 'block' : 'none'
          }}
          onClick={onClick}
        />
      </TooltipWrapper>

      {isActivated && (
        <Toolbar.Layout>
          <TooltipWrapper text={t(PROCESS_DELETE_TOOLTIP_TEXT[deleteState])}>
            <DeleteNodeActionBTN
              acv_id={acv_id}
              node_id={data.id}
              disabled={hasConnections || isFinalProcess}
            />
          </TooltipWrapper>
        </Toolbar.Layout>
      )}
      <CustomNode.Layout selected={selected || (isHovered && inProgress)}>
        <CustomNode.Content>
          <CustomNode.Icon
            icon={NODE_ICONS[NODE_TYPES.process]}
            lookAndFeel='process'
            disabled={tags.some((tag) => tag.disabled && REQUIRED_TAGS.includes(tag.value))}
          />
          <div className='flex-col'>
            <span className='font-body-b2-r text-gray-light'>{t('lcaScenarios.process')}</span>
            <span className='font-body-b1-sb'>{nodeMetadata?.name ?? '-'}</span>
          </div>
          {nodeImpact && (
            <CustomNode.TotalImpact
              impact={nodeImpact?.sum_total_impact.toFixed(2) ?? ''}
              unit={buildLegendUnitFromNodeImpact(nodeImpact)}
              position='right'
            />
          )}
        </CustomNode.Content>

        {tags.length > 0 && (
          <div className='flex gap-x-2 wrap max-w-20rem gap-y-1'>
            {tags.map((tag) => (
              <ProcessTag key={data.id + tag.value} disabled={tag.disabled} value={tag.value} />
            ))}
          </div>
        )}

        <ConfigureProcessBTN
          node_id={data.id}
          lookAndFeel={isMetadataComplete ? 'secondary' : 'primary'}
        />

        <div className='flex-col gap-y-2'>
          <div className='relative'>
            {piece && <PieceNode.Appearance data={piece} disableHover />}
            {!piece && <NoReferenceOutput />}
            <Handle
              type='source'
              position={Position.Right}
              isConnectableStart={data.outputs.length === 0}
              style={getHandleStyle('source', data.outputs.length !== 0)}
              className='custom-source-handle'
            />
          </div>
        </div>
      </CustomNode.Layout>
    </div>
  );
};
