import getCountryName from 'utils/getCountryName';
import { LCAFormMaterial } from '../SelectMaterialModal/constants';
import { FormProcessInput } from './FormContext';
import { TFunction } from 'react-i18next';
import { QUANTITY_DEFAULT, UNIT_DEFAULT } from './constants';

const adaptLCAInputTypeToMaterialType = (type: string) => {
  if (type === 'raw_material' || type === 'consumption') {
    return 'material';
  }

  return 'energy';
};

type LCAMaterialInput = Omit<
  Required<LCAFormMaterial>,
  'code' | 'impact_source_id' | 'tech_notes' | 'description'
> &
  Partial<Pick<LCAFormMaterial, 'impact_source_id'>>;

export const buildForm = (
  t: TFunction,
  metadata?: LCAProcessMetadataFront,
  materials?: LCAMaterialInput[],
  lang?: Lang
) => {
  const inputs: FormProcessInput[] =
    materials?.map((input) => ({
      id: input.material_id,
      material_id: input.material_id,
      name: input.name,
      supplier: input.supplier,
      quantity: input.quantity,
      unit: {
        id: input.unit_id,
        name: input.unit
      },
      type: adaptLCAInputTypeToMaterialType(input.type),
      unit_list: input.unit_list,
      impact_source_id: input.impact_source_id
    })) ?? [];

  const name = metadata?.name ?? '';

  const country = metadata?.country
    ? { id: metadata.country, name: getCountryName(metadata.country, lang ?? 'es') }
    : { id: '', name: '' };

  const reference_output = {
    name: metadata?.reference_output?.name ?? t('reference_output_name_placeholder') ?? '',
    unit_id: metadata?.reference_output?.unit_id || UNIT_DEFAULT,
    value: metadata?.reference_output?.value ?? QUANTITY_DEFAULT
  };

  return { name, country, inputs, reference_output };
};

export const buildMaterialsFromInputs = (inputs: FormProcessInput[]): LCAMaterial[] => {
  const materials =
    inputs?.map((input) => ({
      id: input.material_id,
      material_id: input.material_id,
      name: input.name,
      supplier: input.supplier,
      type: input.type,
      country: '',
      region: '',
      location: '',
      impact_source_id: '',
      unit_list: []
    })) ?? [];

  return materials;
};

export const buildInputsFromMaterials = (materials: LCAMaterial[]) => {
  return materials.map((material) => ({
    id: material.id,
    material_id: material.id,
    name: material.name,
    supplier: material.supplier,
    quantity: '',
    unit: {
      id: '',
      name: ''
    },
    impact_source_id: material.impact_source_id,
    type: material.type,
    unit_list: material.unit_list
  }));
};
