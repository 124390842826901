import { createContext, useContext } from 'react';

export type FormProcessInput = {
  id: string;
  material_id: string;
  name: string;
  supplier: string;
  quantity: string;
  unit: SelectOptionFormat<string>;
  type: 'material' | 'energy';
  unit_list: { unit_id: string; unit_name: string }[];
  impact_source_id?: string;
};

type FormContextType = {
  form: {
    name: string;
    country: SelectOptionFormat<string>;
    inputs: FormProcessInput[];
    reference_output: {
      name: string;
      unit_id: string;
      value: string;
    };
  };
  setForm: React.Dispatch<React.SetStateAction<FormContextType['form']>>;
};

const FormContext = createContext<FormContextType | null>(null);

type ProviderProps = {
  form: FormContextType['form'];
  setForm: FormContextType['setForm'];
};

export const FormProvider: React.FC<ProviderProps> = ({ children, form, setForm }) => {
  return <FormContext.Provider value={{ form, setForm }}>{children}</FormContext.Provider>;
};

export const useFormContext = () => {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }

  return context;
};
