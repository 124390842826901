import Modal from 'components/ui/modal/Modal';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import { GrayBox } from '../../ui/GrayBox/GrayBox';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import { useForm } from 'react-hook-form';
import Tooltip from 'components/ui/tooltip/Tooltip';
import Select from 'components/ui/formComponents2/select/Select';
import { useUnits } from 'customHooks/api/useUnits';
import InputNumber from 'components/ui/formComponents2/inputNumber/InputNumber';
import { zodResolver } from '@hookform/resolvers/zod';
import { manageUnitSchema } from '../schema/manageUnitSchema';
import { adaptHookFormError } from 'lib/ReactHookForm/adaptError';
import { useErrorTranslations } from 'customHooks/translations/useErrorTranslations';
import LoaderTables from 'components/ui/loaders/loaderTables/LoaderTables';
import { useUpdateMaterialUnits } from '../hooks/useUpdateMaterialUnits';
import { useTranslation } from 'react-i18next';

type BodyProps = {
  referenceUnit: { unit_id: string; unit_name: string };
  material_id: string;
  unitList: { unit_id: string; unit_name: string; value?: number }[];
  onClose: () => void;
};

const Body = ({ referenceUnit, unitList, material_id, onClose }: BodyProps) => {
  const { data, isLoading } = useUnits();
  const { t: tError } = useErrorTranslations();
  const { t } = useLCATranslation({ keyPrefix: 'materialManagement.unitsModal' });
  const { t: tUnits } = useTranslation();
  const { updateMaterialUnits, isPending } = useUpdateMaterialUnits();

  const { watch, setValue, handleSubmit, formState } = useForm({
    defaultValues: {
      unitList:
        unitList?.map(({ unit_id, unit_name }) => ({
          unit_id,
          unit_name: tUnits(`units_short.${unit_name}`)
        })) ?? [],
      values: unitList?.map(({ value }) => value?.toString() ?? '') ?? []
    },
    resolver: zodResolver(manageUnitSchema)
  });

  const addUnit = () => {
    setValue('unitList', [...watch('unitList'), { unit_id: '', unit_name: '' }]);
    setValue('values', [...watch('values'), '']);
  };

  const editUnitDropdown = (index: number) => {
    return (newUnit: SelectOptionFormat<string>) => {
      const newUnitList = [...watch('unitList')];

      const unit = newUnitList[index];

      newUnitList[index] = {
        ...unit,
        unit_id: newUnit.id,
        unit_name: newUnit.name
      };

      setValue('unitList', newUnitList);
    };
  };

  const editConversionFactor = (index: number) => {
    return (newConversionFactor: string) => {
      const newValues = [...watch('values')];

      newValues[index] = newConversionFactor;

      setValue('values', newValues);
    };
  };

  if (isLoading) {
    return <LoaderTables />;
  }

  return (
    <form
      className='flex-col gap-y-2'
      onSubmit={handleSubmit(async (result) => {
        await updateMaterialUnits({
          id: material_id,
          units: result.unitList.map((unit, index) => ({
            ...unit,
            value: Number(result.values[index]),
            result_unit_id: referenceUnit.unit_id
          }))
        });

        onClose();
      })}>
      <GrayBox disabled>
        <InputWrapper label={t('officialUnitIs')} disabled>
          <InputText value={tUnits(`units.${referenceUnit.unit_name}`)} disabled />
        </InputWrapper>
      </GrayBox>
      <GrayBox>
        <div className='flex-col gap-y-3'>
          {!watch('unitList')?.length && (
            <>
              <span className='font-10 weight-600 inter text-neutral-gray-cold-20'>
                {t('addedUnits')}
              </span>
              <span className='font-10 weight-400 inter text-neutral-gray-20'>
                {t('noUnitsAdded')}
              </span>
            </>
          )}
          {watch('unitList')?.length > 0 && (
            <div className='grid grid-cols-2 gap-2 max-h-25svh overflow-y-auto'>
              <span className='font-10 weight-600 inter text-neutral-gray-cold-20 mb-1'>
                {t('addedUnits')}
              </span>
              <div className='flex gap-x-3 items-center mb-1'>
                <span className='font-10 weight-600 inter text-neutral-gray-cold-20 '>
                  {t('conversionFactor')}
                </span>
                <Tooltip text={t('conversionFactorTooltip')} />
              </div>
              {watch('unitList')?.map((unit, index) => {
                return (
                  <>
                    <InputWrapper
                      error={adaptHookFormError(
                        formState.errors.unitList?.[index]?.unit_id,
                        tError
                      )}>
                      <Select
                        value={{
                          id: unit.unit_id,
                          name: unit.unit_name
                        }}
                        onChange={editUnitDropdown(index)}
                        options={data}
                      />
                    </InputWrapper>
                    <InputWrapper
                      error={adaptHookFormError(formState.errors.values?.[index], tError)}>
                      <InputNumber
                        decimals={20}
                        value={watch('values')[index]}
                        onChange={editConversionFactor(index)}
                        autoComplete='off'
                        allowNegative
                      />
                    </InputWrapper>
                  </>
                );
              })}
            </div>
          )}
        </div>
      </GrayBox>
      <Button
        lookAndFeel='link-dark'
        iconNode={<Icon icon='add' color='gray-dark' />}
        onClick={addUnit}
        text={t('addUnit')}
      />

      <Modal.Buttons>
        <Button lookAndFeel='secondary' text={t('cancel')} size='medium' onClick={onClose} />
        <Button
          lookAndFeel='primary'
          text={t('save')}
          type='submit'
          size='medium'
          loading={isPending}
        />
      </Modal.Buttons>
    </form>
  );
};

const Header = () => {
  const { t } = useLCATranslation({ keyPrefix: 'materialManagement.unitsModal' });

  return <Modal.Header title={t('title')} description={t('description')} />;
};

type Props = BodyProps & {
  show: boolean;
};

export const ManageUnitsModal = ({
  show,
  onClose,
  referenceUnit,
  unitList,
  material_id
}: Props) => {
  return (
    <Modal.WithPortal show={show} onClose={onClose} maxWidth='560px'>
      <Header />
      <Body
        referenceUnit={referenceUnit}
        unitList={unitList}
        onClose={onClose}
        material_id={material_id}
      />
    </Modal.WithPortal>
  );
};

ManageUnitsModal.Body = Body;
ManageUnitsModal.Header = Header;
