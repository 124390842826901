import type { QueryFunction } from '@tanstack/react-query';
import type {
  GetContractParentQueryKey,
  GetUsedEntitiesQueryKey,
  PayloadCreateOrUpdateOrganization,
  ResponseCreateOrUpdateOrganization,
  ResponseGetUsedEntities
} from 'types/entities/holdings';

import apiFetch from 'utils/apiFetch';

export const createOrUpdateOrganization = async ({
  node_id,
  ...payload
}: PayloadCreateOrUpdateOrganization): Promise<ResponseCreateOrUpdateOrganization> => {
  const response = await apiFetch('POST', `/holdings/create/organization/${node_id}`, payload);

  return response.data;
};

export const getContractParent: QueryFunction<string, GetContractParentQueryKey> = async ({
  queryKey
}) => {
  const [, organizationId] = queryKey;

  const response = await apiFetch('GET', `/holdings/contract_parent/${organizationId}`);

  return response.data;
};

export const getUsedEntities: QueryFunction<
  ResponseGetUsedEntities,
  GetUsedEntitiesQueryKey
> = async ({ queryKey }) => {
  const [, canvas_org_id] = queryKey;

  const response = await apiFetch('GET', `/holdings/contract/entities/${canvas_org_id}`);

  return response.data;
};
