import { useQuery } from '@tanstack/react-query';
import { getNodeMaterials } from '../../../../services/api/lca/lcaPortfolio';

type Params = {
  node_id?: string;
  acv_id?: string;
};

export const useNodeMaterials = ({ acv_id, node_id }: Params) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['lca-node-materials', acv_id, node_id],
    queryFn: () => {
      if (!acv_id || !node_id) {
        return;
      }

      return getNodeMaterials({ acv_id, node_id });
    },
    select: (data) => {
      return data?.map((piece) => {
        return {
          id: piece.material_id,
          material_id: piece.material_id,
          country: piece.country,
          region: piece.region,
          location: piece.location,
          code: piece.code,
          impact_source_id: piece.impact_source_id,
          name: piece.name,
          supplier: piece.supplier,
          quantity: piece.quantity.toString(),
          unit: piece.unit,
          unit_id: piece.unit_id,
          type: piece.type,
          unit_list: piece.unit_list,
          tech_notes: piece.tech_notes,
          description: piece.description
        };
      });
    },
    enabled: !!acv_id && !!node_id
  });

  return { data, isLoading, isFetching };
};
