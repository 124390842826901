export const NODE_TYPES = {
  material: 'material',
  process: 'process',
  piece: 'piece',
  product: 'product',
  transport: 'transport'
} as const;

export const NODE_TYPES_ARRAY = Object.values(NODE_TYPES);

export const KG = { name: 'kilogram_(kg)', id: '61743a63-ff70-459c-9567-5eee8f7dfd5c' };

export const NODE_ICONS = {
  [NODE_TYPES.material]: 'leather',
  [NODE_TYPES.process]: 'factory',
  [NODE_TYPES.piece]: 'objects',
  [NODE_TYPES.product]: 'objects',
  [NODE_TYPES.transport]: 'lorry'
} as const;

export const DEFAULT_HEIGHT = '40svh';

export const DEFAULT_WIDTH = '600px';
