import Icon from 'components/ui/icon/Icon';
import './styles.scss';
type Props = {
  file: {
    id: string;
    name: string;
    url?: string;
  };
  handleRemove: (id: string) => void;
};
const FileAttached = ({ file, handleRemove }: Props) => {
  const handleDownloadFile = () => {
    if (!file.url) return;
    // Check first if file_url is a blob url and download it directly
    if (file.url.startsWith('blob:')) {
      const a = document.createElement('a');
      a.href = file.url;
      a.download = file.name;
      a.click();
      return;
    }

    window.open(file.url, '_blank');
  };

  return (
    <div className='file-attached'>
      <div className='flex items-center justify-between gap-1 width-100'>
        <div className='flex gap-1'>
          {file.url && (
            <Icon
              icon='download'
              color='gradient'
              className='pointer'
              onClick={handleDownloadFile}
              size='small'
            />
          )}
          <div className='flex gap-1'>
            <span className='subtitle3-font'>{file.name}</span>
          </div>
        </div>
        <Icon
          icon='trash'
          color='gray'
          className='pointer'
          onClick={() => handleRemove(file.id)}
          size='small'
        />
      </div>
    </div>
  );
};

export default FileAttached;
