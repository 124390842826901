import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteLCAConnection } from '../../../../services/api/lca/lcaScenarios';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { LCABlock } from '../../types/LCABlock';
import { findAndInvalidateTransport } from '../utils/findAndInvalidateTransport';

export const useDeleteLCAConnection = () => {
  const queryClient = useQueryClient();
  const org = useSelectedOrganization();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteLCAConnection,
    onMutate: ({ source_id, target_id, acv_id }) => {
      queryClient.setQueryData(
        ['lcaBlocks', org?.id, acv_id],
        (oldData: LCABlock[] | undefined) => {
          if (!oldData) return;

          return oldData.map((block) => {
            if (block.id === source_id) {
              return {
                ...block,
                outputs: block.outputs?.filter((output) => output.id !== target_id)
              };
            }

            if (block.id === target_id) {
              return {
                ...block,
                inputs: block.inputs?.filter((input) => input.id !== source_id)
              };
            }

            return block;
          });
        }
      );

      queryClient.resetQueries({ queryKey: ['lcaImpacts'] });
    },
    onSuccess: (_, { acv_id, target_id }) => {
      findAndInvalidateTransport(queryClient, { org_id: org?.id, acv_id, target_id });
    }
  });

  return { deleteConnection: mutateAsync, isPending };
};
