import Icon from 'components/ui/icon/Icon';
import { IconName } from 'types/utils/iconName';

type IconProps = Omit<React.ComponentProps<typeof Icon>, 'color'> & {
  icon: IconName;
  lookAndFeel?: 'default' | 'material' | 'process' | 'piece' | 'transport';
  disabled?: boolean;
};

const variants = {
  default: {
    bg: 'icon-bg-color',
    icon: 'gradient'
  },
  material: {
    bg: 'bg-tertiary-salmon',
    icon: 'white'
  },
  process: {
    bg: 'bg-ui-colors-green',
    icon: 'white'
  },
  piece: {
    bg: 'bg-secondary-purple-20',
    icon: 'white'
  },
  transport: {
    bg: 'bg-transport',
    icon: 'white'
  }
} as const;

const iconSizes = {
  large: 'w-8 h-8 p-2 rounded-8',
  medium: 'w-4 h-4 rounded-4',
  small: 'w-3 h-3',
  xlarge: 'w-12 h-12 p-2 rounded-8'
} as const;

export const LCAIcon: React.FC<IconProps> = ({
  icon,
  lookAndFeel = 'default',
  disabled,
  style,
  ...props
}) => {
  const { bg, icon: color } = variants[lookAndFeel];

  if (disabled) {
    return (
      <div
        className={`${
          iconSizes[props.size ?? 'large']
        } flex items-center justify-center border-box  bg-neutral-gray-warm-40`}
        style={style}>
        <Icon icon={icon} color='white' size='large' {...props} />
      </div>
    );
  }

  return (
    <div
      className={`${
        iconSizes[props.size ?? 'large']
      } flex items-center justify-center border-box ${bg}`}
      style={style}>
      <Icon icon={icon} color={color} size='large' {...props} />
    </div>
  );
};
