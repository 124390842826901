import { useState } from 'react';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import Button from 'components/ui/button/Button';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from 'components/ui/formComponents2/select/Select';
import TotalLegendV2 from 'components/ui/totalLegend/TotalLegendV2';
import { LCADiagram } from '../../../Scenarios/components/LCADiagram/LCADiagram';
import { useACVId } from '../../../Scenarios/hooks/useACVId';
import { adaptLCAImpactCategories } from '../adapters/adaptLCAImpactCategories';
import { LCADetailFacade } from '../components/LCADetailFacade';
import { useLCADetail } from '../hooks/useLCADetail';
import { ImpactCategoryProvider } from './ImpactCategoryContext';
import { buildLegendUnitFromNodeImpact } from '../../../utils';
import { useDispatch } from 'react-redux';
import { setNotification } from 'actions/notification';
import { useLCATotalImpact } from 'components/lca/Scenarios/hooks/useLCATotalImpact';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import Icon from 'components/ui/icon/Icon';
import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

export const LCADetailCanvas = () => {
  const { t } = useLCATranslation();
  const { acv_id } = useACVId();
  const { data } = useLCADetail(acv_id);
  const dispatch = useDispatch();

  const impactCategories = adaptLCAImpactCategories(data?.impact_categories, t);

  const [impactCategory, setImpactCategory] = useState<SelectOptionFormat<string>>(() => {
    if (impactCategories.length > 0) {
      return impactCategories[0];
    }

    return { id: '', name: '' };
  });

  const { totalImpact, refetch, isFetching, isError } = useLCATotalImpact(acv_id, impactCategory);

  const handleCalculate = () => {
    refetch();

    dispatch(setNotification(t('lcaDetail.processFlow.header.calculatingNotification')));
  };

  return (
    <ImpactCategoryProvider impactCategory={impactCategory}>
      <div className='flex justify-between'>
        <div className='flex gap-x-2 items-center'>
          <div className='bg-white'>
            <InputWrapper iconV2='cloud'>
              <span className='p-2 font-body-b2-sb'>
                {t('lcaDetail.processFlow.header.impactCategory')}
              </span>
              <Select
                options={impactCategories}
                value={impactCategory}
                onChange={setImpactCategory}
                style={{ width: '12.5rem' }}
              />
            </InputWrapper>
          </div>
          <TooltipWrapper
            shouldAppear={Boolean(totalImpact)}
            text={t('lcaDetail.processFlow.header.calculateTooltip')}>
            <Button
              lookAndFeel='primary'
              text={t('lcaDetail.processFlow.header.calculate')}
              size='small'
              style={{ width: 'fit-content' }}
              onClick={handleCalculate}
              disabled={isError || Boolean(totalImpact)}
              loading={isFetching}
            />
          </TooltipWrapper>
          {isError && (
            <TooltipWrapper
              text={
                <span>
                  {t('lcaDetail.processFlow.header.needsMatching1')}{' '}
                  <Link to={ROUTES.LCA_MATERIAL_MANAGEMENT} className='cursor-pointer underline'>
                    {t('lcaDetail.processFlow.header.needsMatching2')}
                  </Link>
                </span>
              }
              position='right'
              disableHoverableContent={false}>
              <div className='bg-neutral-gray-warm-60 py-1 px-2 rounded-16'>
                <Icon icon='warning' color='disabled' />
              </div>
            </TooltipWrapper>
          )}
        </div>
        <div className='flex gap-x-4'>
          <TotalLegendV2
            totalLabel={t('lcaDetail.processFlow.header.totalImpact')}
            totalElements={[
              {
                label: totalImpact ? buildLegendUnitFromNodeImpact(totalImpact) : '',
                value: totalImpact?.sum_total_impact
                  ? Number(totalImpact.sum_total_impact.toFixed(2))
                  : 0
              }
            ]}
          />
          <LCADetailFacade />
        </div>
      </div>
      <LCADiagram />
    </ImpactCategoryProvider>
  );
};
