export const PROCESS_DELETE_TOOLTIP_TEXT = {
  default: 'lcaDetail.processFlow.processCard.deleteTooltip',
  has_connections: 'lcaDetail.processFlow.processCard.deleteTooltipHasConnections',
  final_process: 'lcaDetail.processFlow.processCard.deleteTooltipFinalProcess'
} as const;

export const DEFAULT_DELETE_TOOLTIP_TEXT = {
  default: 'lcaDetail.processFlow.materialCard.deleteTooltip',
  has_connections: 'lcaDetail.processFlow.materialCard.deleteTooltipHasConnections'
} as const;
