import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createFrameworkPlan,
  FrameworkPlanPayload,
  SaveFramework,
  updateChildFrameworkPlan,
  updateFrameworkPlan,
  FrameworkPatchPayload
} from 'services/api/frameworks';
const useUpdateVisibilityFrameworkPlan = () => {
  const { mutateAsync } = useMutation({
    mutationFn: ({
      id,
      payload,
      organizationId
    }: {
      id: string;
      payload: FrameworkPatchPayload;
      organizationId?: string;
    }) => {
      return updateChildFrameworkPlan(id, payload, organizationId);
    }
  });
  return { mutateAsync };
};

const useUpdateFrameworkPlan = () => {
  const { mutateAsync } = useMutation({
    mutationFn: ({
      id,
      payload,
      organizationId
    }: {
      id: string;
      payload: FrameworkPlanPayload;
      organizationId?: string;
    }) => {
      return updateFrameworkPlan(id, payload, organizationId);
    }
  });
  return { mutateAsync };
};
const useCreateFrameworkPlan = () => {
  const { mutateAsync } = useMutation({
    mutationFn: ({
      payload,
      organizationId
    }: {
      payload: FrameworkPlanPayload;
      organizationId?: string;
    }) => {
      return createFrameworkPlan(payload, organizationId);
    }
  });
  return { mutateAsync };
};

const useSaveFrameworksPlans = (organizationId = '', visibilityChange = false) => {
  const { mutateAsync: createFrameworkPlan } = useCreateFrameworkPlan();
  const { mutateAsync: updateFrameworkPlan } = useUpdateFrameworkPlan();
  const queryClient = useQueryClient();
  const saveFrameworkPlans = async (frameworkPlans: SaveFramework[]) => {
    try {
      for (const plan of frameworkPlans) {
        // Extract properties to construct payload
        const { id, ...frameworkPlanData } = plan;

        if (id) {
          if (visibilityChange) {
            // Update existing plan for child organization
            await updateChildFrameworkPlan(
              id,
              { visible: frameworkPlanData.visible || false },
              organizationId
            );
          } else {
            // Update existing plan
            await updateFrameworkPlan({ id, payload: frameworkPlanData, organizationId });
          }
        } else {
          // Create new plan
          await createFrameworkPlan({
            payload: frameworkPlanData,
            organizationId
          });
        }
      }
      queryClient.invalidateQueries({ queryKey: ['frameworkPlans'] });
    } catch (error) {
      console.error('Error saving framework plans:', error);
    }
  };
  return { saveFrameworkPlans };
};

export default useSaveFrameworksPlans;
