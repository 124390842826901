import React, { ReactNode, useEffect, useState } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useStepCarousel from '../../../../ui/stepCarouselList/useStepCarousel';
import StepCarouselList from '../../../../ui/stepCarouselList/StepCarouselList';
import './styles.scss';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { DEFAULT_STEP, STEP_IDS } from '../../constants';
import { useContractParent } from '../../Diagram/hooks/useContractParent';

type Props = {
  children: ReactNode;
};

const Steps = () => {
  const selectedOrganization = useSelectedOrganization();

  const {
    isLoading,
    isLoadingError,
    data: contractOrgId
  } = useContractParent(selectedOrganization?.id, {
    retry: false,
    retryOnMount: false
  });

  const [searchParams, setSearchParams] = useSearchParams({
    type: DEFAULT_STEP
  });

  const STEPS = [
    {
      id: STEP_IDS.DIAGRAM,
      text: 'companies.canvas'
    },
    {
      id: STEP_IDS.CHILDS,
      text: 'companies.childs'
    },
    {
      id: STEP_IDS.PARENTS,
      text: 'companies.parents'
    }
  ];

  const [stepsState, setStepsState] = useState(STEPS);

  const { steps } = useStepCarousel({
    stepsText: stepsState,
    selected: searchParams.get('type') ?? DEFAULT_STEP
  });

  const removeDiagram = () => {
    setSearchParams({ type: STEP_IDS.CHILDS });
    setStepsState((prev) => {
      return prev.filter((step) => step.id !== STEP_IDS.DIAGRAM);
    });
  };

  useEffect(() => {
    if (isLoadingError) {
      removeDiagram();
    }
  }, [isLoadingError]);

  useEffect(() => {
    if (!isLoading && !contractOrgId) {
      removeDiagram();
    }
  }, [isLoading]);

  if (selectedOrganization?.is_group_fund || isLoading) {
    return null;
  }

  return (
    <StepCarouselList
      steps={steps}
      handleSelect={(id: string) => {
        setSearchParams({ type: id });
      }}
    />
  );
};

const Base = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='companies__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('companies.title')}</h3>
        <Breadcrumb />
      </div>
      {children}
    </>
  );
};

const CompaniesWrapper = ({ children }: Props) => {
  return (
    <section className='companies'>
      <Base>
        <Steps />
        {children}
      </Base>
    </section>
  );
};

CompaniesWrapper.Base = Base;
CompaniesWrapper.Steps = Steps;

export default CompaniesWrapper;
