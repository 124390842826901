export const HighlightedMarker = () => {
  return (
    <svg style={{ position: 'absolute', top: 0, left: 0 }}>
      <defs>
        <marker
          className='react-flow__arrowhead'
          id='highlighted-marker'
          markerWidth='6.75'
          markerHeight='6.75'
          viewBox='-10 -10 20 20'
          markerUnits='strokeWidth'
          orient='auto-start-reverse'
          refX='0'
          refY='0'>
          <polyline
            className='fill-secondary-purple-20 stroke-secondary-purple-20'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='-5,-4 0,0 -5,4 -5,-4'
          />
        </marker>
      </defs>
    </svg>
  );
};
