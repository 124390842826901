import { EdgeHighlightContext } from './EdgeHighlightContext';

type Props = {
  highlightedEdges: string[];
  setHighlightedEdges: React.Dispatch<React.SetStateAction<string[]>>;
};

export const EdgeHighlightProvider: React.FC<Props> = ({
  highlightedEdges,
  setHighlightedEdges,
  children
}) => {
  return (
    <EdgeHighlightContext.Provider value={{ highlightedEdges, setHighlightedEdges }}>
      {children}
    </EdgeHighlightContext.Provider>
  );
};
