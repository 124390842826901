import { z } from 'zod';

const firstStepSchema = z.object({
  name: z.string().min(1, 'required'),
  country: z.object({
    id: z.string().min(1, 'required'),
    name: z.string().min(1, 'required')
  })
});

const secondStepSchema = firstStepSchema.extend({
  inputs: z.array(
    z.object({
      material_id: z.string(),
      quantity: z.string().refine((value) => {
        return value !== '' && !isNaN(Number(value));
      }),
      unit: z.object({
        id: z.string().min(1, 'required'),
        name: z.string()
      })
    })
  )
});

const thirdStepSchema = secondStepSchema.extend({
  reference_output: z.object({
    value: z.string().refine((value) => {
      return value !== '' && !isNaN(Number(value));
    }),
    unit_id: z.string().min(1, 'required'),
    name: z.string().min(1, 'required')
  })
});

export const formSchema = {
  FIRST: firstStepSchema,
  SECOND: secondStepSchema,
  THIRD: thirdStepSchema
} as const;
