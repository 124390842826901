import { BaseEdge, Position, getSmoothStepPath } from '@xyflow/react';
import { REM } from 'constants/measures';

type Props = {
  id: string;
  sourceX: number;
  sourceY: number;
  sourcePosition: Position;
  targetX: number;
  targetY: number;
  target: string;
  targetPosition: Position;
  selected?: boolean;
  markerEnd?: string;
};

export const DefaultEdge: React.FC<Props> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  targetPosition,
  sourcePosition,
  selected,
  markerEnd
}) => {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY: sourceY - 6,
    targetX: targetX - 8 * REM,
    targetY,
    targetPosition,
    sourcePosition,
    borderRadius: 8
  });

  const edgeClassNames = [];

  if (selected) {
    edgeClassNames.push('stroke-secondary-purple-20!important stroke-width-3px z-10');
  }

  return (
    <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} className={edgeClassNames.join(' ')} />
  );
};
