import { useQuery } from '@tanstack/react-query';
import { getFrameworkCodes } from 'services/api/purchaseSuppliers';
import { PurchaseFramework } from 'utils/mapToPurchaseFramework';

const adapter = (data: string[]) => {
  return data.map((item) => ({
    id: item,
    name: item
  }));
};

type Params = {
  framework: PurchaseFramework.CNAE | PurchaseFramework.SIC;
};
export const useAdminFrameworkCodes = ({ framework }: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: ['frameworkCodes_admin', framework],
    queryFn: () => getFrameworkCodes(framework),
    select: adapter
  });

  return { data: data ?? [], isLoading };
};
