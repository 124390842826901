import { useTranslation } from 'react-i18next';
type Props = {
  category: string;
};
const useSortingOptions = ({ category }: Props) => {
  const { t } = useTranslation();
  const options = [
    { id: 'created_at', name: t('input.sorting.facilities.creationDate') },
    { id: 'quantity', name: t('input.sorting.facilities.quantity') },
    { id: 'start_date', name: t('input.sorting.facilities.startDate') },
    { id: 'end_date', name: t('input.sorting.facilities.endDate') }
    //{ id: 'co2e', name: t('input.sorting.facilities.co2Tons') }
  ];

  if (['heat', 'water', 'recharge', 'electricity'].includes(category)) {
    options.push({ id: 'facility_fuel_name', name: t('input.sorting.facilities.fuel') });
  }
  return options;
};

export default useSortingOptions;
