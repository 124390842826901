import { LCAIcon } from 'components/lca/components/LCAIcon';
import { NODE_ICONS, NODE_TYPES } from '../../constants';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from 'components/ui/formComponents2/select/Select';
import { useTransportTOCOptions } from '../../hooks/useTransportTOCOptions';
import InputNumber from 'components/ui/formComponents2/inputNumber/InputNumber';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';

const Layout: React.FC = ({ children }) => {
  return <div className='flex flex-col gap-y-2'>{children}</div>;
};

const RowWrapper: React.FC = ({ children }) => {
  return <div className='grid grid-cols-10 gap-x-2 px-2 py-1 items-center'>{children}</div>;
};

type DescriptionProps = {
  row: LCATransportDetailsFront['transports'][number];
};

const Description: React.FC<DescriptionProps> = ({ row }) => {
  const adaptedBlockType = row.type === NODE_TYPES.product ? NODE_TYPES.piece : row.type;

  const auxiliarDescription = [row.country];

  if (row.supplier) {
    auxiliarDescription.unshift(row.supplier);
  }

  return (
    <div className='flex gap-x-2 col-span-5'>
      <LCAIcon
        icon={NODE_ICONS[NODE_TYPES[adaptedBlockType]]}
        lookAndFeel={NODE_TYPES[adaptedBlockType]}
        size='large'
      />

      <div className='flex-col gap-y-1'>
        <span className='font-12 weight-400 inter text-neutral-gray-cold-20'>{row.name}</span>
        <span className='font-12 weight-400 inter text-neutral-gray-warm-20'>
          {auxiliarDescription.join(' - ')}
        </span>
      </div>
    </div>
  );
};

type DistanceProps = {
  error?: ErrorType;
} & React.ComponentProps<typeof InputNumber>;

const Distance: React.FC<DistanceProps> = ({ error, ...rest }) => {
  return (
    <div className='col-span-2'>
      <InputWrapper error={error}>
        <InputNumber {...rest} />
      </InputWrapper>
    </div>
  );
};

type TOCProps = {
  error?: ErrorType;
  impact_source_id: string;
  onChange: (value: SelectOptionFormat<string>) => void;
};

const TOC: React.FC<TOCProps> = ({ error, impact_source_id, onChange }) => {
  const { options } = useTransportTOCOptions();

  const value = options.find((option) => option.id === impact_source_id);

  return (
    <div className='col-span-3'>
      <InputWrapper error={error}>
        <Select options={options} value={value ?? { name: '', id: '' }} onChange={onChange} />
      </InputWrapper>
    </div>
  );
};

type DeleteBTNProps = {
  onDelete: () => void;
};

const DeleteBTN: React.FC<DeleteBTNProps> = ({ onDelete }) => {
  return (
    <div className='grid grid-cols-1 place-items-center'>
      <Button
        lookAndFeel='link-dark'
        iconNode={<Icon icon='trash' color='gray-dark' />}
        onClick={onDelete}
      />
    </div>
  );
};

const Headers = () => {
  const { t } = useLCATranslation();

  return (
    <RowWrapper>
      <span className='col-span-5 font-12 weight-600 inter text-neutral-gray-cold-20'>
        {t('transport_configuration.headers.description')}
      </span>
      <span className='col-span-2 font-12 weight-600 inter text-neutral-gray-cold-20'>
        {t('transport_configuration.headers.distance')}
      </span>
      <span className='col-span-3 font-12 weight-600 inter text-neutral-gray-cold-20'>
        {t('transport_configuration.headers.toc')}
      </span>
    </RowWrapper>
  );
};

export const Table = {
  Layout,
  RowWrapper,
  Description,
  Distance,
  TOC,
  DeleteBTN,
  Headers
};
