import { useQuery } from '@tanstack/react-query';
import { adaptHoldingConnections } from 'adapters/adaptHoldingConnections';
import { getConnections } from 'services/api/holdings';

type Params = {
  contractOrgId?: string;
  organizationId?: string;
};

export const useHoldingConnections = ({ contractOrgId, organizationId }: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: ['holding-connections', contractOrgId, organizationId],
    queryFn: getConnections,
    enabled: Boolean(contractOrgId),
    select: adaptHoldingConnections
  });

  return { data: data ?? [], isLoading };
};
