import { ReactNode } from 'react';
import TooltipWrapper from './TooltipWrapper';
import './styles.scss';

type Props = {
  text: string | ReactNode;
  asChild?: boolean;
  position?: 'top' | 'right' | 'left';
  icon?: string;
  size?: string;
  containerPosition?: DOMRect;
  style?: React.CSSProperties;
};

const Tooltip = ({ icon = '/images/icons/info.svg', style, ...rest }: Props) => {
  return (
    <TooltipWrapper {...rest} style={style}>
      <img src={icon} alt='info-icon' className='w-3 h-3 pointer' />
    </TooltipWrapper>
  );
};

const Uncontrolled = ({ icon = '/images/icons/info.svg', style, ...rest }: Props) => {
  return (
    <TooltipWrapper.Uncontrolled {...rest} style={style}>
      <img src={icon} alt='info-icon' className='w-3 h-3 pointer' />
    </TooltipWrapper.Uncontrolled>
  );
};

Tooltip.Uncontrolled = Uncontrolled;

export default Tooltip;
