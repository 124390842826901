import { BaseEdge, Position, getSmoothStepPath } from '@xyflow/react';
import { useTop0 } from './useTop0';
import { REM } from 'constants/measures';

type Props = {
  id: string;
  sourceX: number;
  sourceY: number;
  sourcePosition: Position;
  targetX: number;
  targetY: number;
  target: string;
  targetPosition: Position;
  markerEnd?: string;
};

export const CustomEdge: React.FC<Props> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  target,
  targetPosition,
  sourcePosition,
  markerEnd
}) => {
  const { top0 } = useTop0({ target, targetY });

  const [edgePath] = getSmoothStepPath({
    sourceX: sourceX - 4,
    sourceY,
    targetX,
    targetY: top0 + 2 * REM,
    targetPosition,
    sourcePosition,
    borderRadius: 8
  });

  return <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} />;
};
