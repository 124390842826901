import { CSSProperties } from 'react';

export const getTargetHandleStyle = (connectionInProgress?: boolean): CSSProperties => {
  return {
    top: '0',
    left: '0',
    opacity: '0',
    zIndex: connectionInProgress ? '1' : '0',
    border: '0',
    position: 'absolute',
    width: '100%',
    height: '100%',
    transform: 'none'
  };
};
