import { DisabledContext } from './DisabledContext';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
};

export const DisabledProvider = ({ children, disabled = false }: Props) => {
  return <DisabledContext.Provider value={disabled}>{children}</DisabledContext.Provider>;
};
