/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ReadMoreParagraphProps {
  id: string; // The id of the paragraph
  text: string; // The text to display
  amountOfWords: number; // Number of words to display before truncating
  className?: string; // Optional class name for the container
  style?: CSSProperties; // Optional styles for the container
}

const ReadMoreParagraph: React.FC<ReadMoreParagraphProps> = ({
  id,
  text,
  amountOfWords,
  style = {},
  className
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'readMoreComponent' });

  const splittedText = text.split(' ');
  const itCanOverflow = splittedText.length > amountOfWords;

  // Properly calculate truncated and full text
  const beginText = itCanOverflow ? splittedText.slice(0, amountOfWords).join(' ') : text;
  const endText = itCanOverflow ? splittedText.slice(amountOfWords).join(' ') : '';

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <p id={id} className={className} style={style}>
      {beginText}
      {itCanOverflow && !isExpanded && <span>...</span>}
      {itCanOverflow && isExpanded && <span> {endText}</span>}
      {itCanOverflow && (
        <span
          className='ml-2'
          style={{ cursor: 'pointer' }}
          role='button'
          tabIndex={0}
          aria-expanded={isExpanded}
          aria-controls={id}
          onClick={handleToggle}>
          {isExpanded ? t('showLess') : t('readMore')}
        </span>
      )}
    </p>
  );
};

export default ReadMoreParagraph;
