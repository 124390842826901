import { forwardRef, HTMLAttributes } from 'react';
import formatNumber from '../../../../utils/formatNumber';
import { numberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import IconBackground from '../../iconBackground/IconBackground';
import './styles.scss';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  children?: React.ReactNode;
  listView?: boolean;
}
const Card = forwardRef<HTMLButtonElement, Props>(
  ({ children, listView, disabled = false, ...rest }, ref) => {
    const className = ['card-wrapper card-border-color'];

    if (listView) {
      className.push('card-wrapper--list');
    }

    if (disabled) {
      className.push('card-wrapper__disabled');
    }

    return (
      <button ref={ref} disabled={disabled} className={className.join(' ')} {...rest}>
        {children}
      </button>
    );
  }
);

Card.displayName = 'Card';
// ##########################################################################################
// Top right element, commonly an icon as a button
const CardOptions = ({ children }: { children: React.ReactNode }) => {
  return <div className='card-wrapper__options'>{children}</div>;
};

// ##########################################################################################
type CardIconProps = React.ComponentProps<typeof IconBackground>;
const CardIcon = (props: CardIconProps) => {
  return <IconBackground {...props} />;
};

// ##########################################################################################
const CardContent = forwardRef<HTMLDivElement, { children: React.ReactNode }>(
  ({ children }, ref) => {
    return (
      <div ref={ref} className='card-wrapper__content'>
        {children}
      </div>
    );
  }
);

CardContent.displayName = 'CardContent';

// ##########################################################################################
const CardContentDisabled = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='card-wrapper__content' style={{ opacity: 0.5 }}>
      {children}
    </div>
  );
};

// ##########################################################################################
interface PropsCardTotal extends HTMLAttributes<HTMLDivElement> {
  total: number;
  unit: string;
}
const CardTotal = ({ total, unit, ...rest }: PropsCardTotal) => {
  return (
    <div className='card-wrapper__total' {...rest}>
      <span className='highlight-text-color small-card-font'>
        {formatNumber(numberToDecimalNonZero(total, 2))}
      </span>
      <span className='body1-font'>{unit}</span>
    </div>
  );
};

export default Object.assign(Card, {
  Total: CardTotal,
  ContentDisabled: CardContentDisabled,
  Content: CardContent,
  Icon: CardIcon,
  Options: CardOptions
});
