import { useContext } from 'react';
import { ModalManagerContext } from './ModalManagerContext';

export const useModalManager = () => {
  const context = useContext(ModalManagerContext);

  if (!context) {
    throw new Error('useModalManager must be used within a ModalManagerProvider');
  }

  return context;
};
