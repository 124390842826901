import { useLCATranslation } from 'customHooks/translations/useLCATranslation';

export const EmptyState: React.FC = () => {
  const { t } = useLCATranslation();

  return (
    <div className='flex flex-col'>
      <span className='font-12 weight-600 inter text-neutral-gray-cold-20 p-2'>
        {t('transport_configuration.empty_state.title')}
      </span>
      <p className='font-12 weight-400 inter text-neutral-gray-20 px-2 m-0'>
        {t('transport_configuration.empty_state.description')}
      </p>
    </div>
  );
};
