import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createLCANode } from 'services/api/lca/lcaScenarios';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';

type Params = {
  acv_id: string;
};

export const useCreateNode = ({ acv_id }: Params) => {
  const queryClient = useQueryClient();
  const selectedOrg = useSelectedOrganization();

  const { mutateAsync } = useMutation({
    mutationKey: ['lca-create-node', acv_id],
    mutationFn: createLCANode,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lcaBlocks', selectedOrg?.id, acv_id] });
    }
  });

  return {
    createNode: mutateAsync
  };
};
