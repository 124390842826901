import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../../../customHooks/useOnChangeValue';
import FormElementShort from '../../../../../../../ui/formComponents/formElementShort/FormElementShort';
import FormYesNoRadio from '../../../../../../../ui/formComponents2/formInputs/formYesNoRadio/FormYesNoRadio';
import { FormDataStateESG } from '../ModalDatapoint';
import FormWrapper from '../../../../../../../ui/formComponents/formWrapper/FormWrapper';
import { EvidenceLabel } from './EvidenceLabel';

type Props = {
  formDataState: FormDataStateESG;
  onChangeFiles: (files: File[]) => void;
};

export const YesNoComponent = ({ formDataState, onChangeFiles }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esgMetrics.general' });
  const { formData, setFormData } = formDataState;
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const formDataValue = formData.value as boolean;

  let value = { id: '', name: '' };

  if (formDataValue === true) {
    value = { id: 'yes', name: t('yesNo.yes') };
  }
  if (formDataValue === false) {
    value = { id: 'no', name: t('yesNo.no') };
  }

  const handleOnChangeYesNo = (value: SelectOptionFormat) => {
    if (value.id === 'yes') {
      onChangeValue('value')(true);
    } else {
      onChangeValue('value')(false);
    }
  };

  return (
    <FormWrapper>
      <FormElementShort>
        <FormYesNoRadio
          label={<EvidenceLabel onChangeFiles={onChangeFiles} />}
          value={value}
          onChange={handleOnChangeYesNo}
        />
      </FormElementShort>
    </FormWrapper>
  );
};
