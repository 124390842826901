// I18N
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import englishLiterals from './en.json';
import spanishLiterals from './es.json';
import portugueseLiterals from './pt.json';
import catalanLiterals from './ca.json';

import lcaLiteralsSpanish from './lca/es.json';
import lcaLiteralsEnglish from './lca/en.json';
import lcaLiteralsPortuguese from './lca/pt.json';
import lcaLiteralsCatalan from './lca/ca.json';

import purchaseCodesCatalan from './purchaseCodes/ca.json';
import purchaseCodesSpanish from './purchaseCodes/es.json';
import purchaseCodesEnglish from './purchaseCodes/en.json';
import purchaseCodesPortuguese from './purchaseCodes/pt.json';

import countryNamesCatalan from './countryNames/ca.json';
import countryNamesSpanish from './countryNames/es.json';
import countryNamesEnglish from './countryNames/en.json';
import countryNamesPortuguese from './countryNames/pt.json';

import errorsCatalan from './errors/ca.json';
import errorsEnglish from './errors/en.json';
import errorsSpanish from './errors/es.json';
import errorsPortuguese from './errors/pt.json';

import notificationCatalan from './notification/ca.json';
import notificationSpanish from './notification/es.json';
import notificationEnglish from './notification/en.json';
import notificationPortuguese from './notification/pt.json';

import apiErrorsCatalan from './apiErrors/ca.json';
import apiErrorsSpanish from './apiErrors/es.json';
import apiErrorsEnglish from './apiErrors/en.json';
import apiErrorsPortuguese from './apiErrors/pt.json';

import holdingsCatalan from './holdings/ca.json';
import holdingsEs from './holdings/es.json';
import holdingsEn from './holdings/en.json';
import holdingsPt from './holdings/pt.json';
import tocsSpanish from './tocs/es.json';
import tocsEnglish from './tocs/en.json';
import tocsPortuguese from './tocs/pt.json';

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ['cookie', 'localStorage', 'navigator'],

  // keys or params to lookup language from
  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true
};

i18next.use(LanguageDetector).init({
  interpolation: {
    // React already does escaping
    escapeValue: false
  },
  fallbackLng: 'en',
  fallbackNS: 'translation',
  // whitelist: ['en', 'es'],
  debug: false,
  detection: langDetectorOptions,
  // Using simple hardcoded resources for simple example
  resources: {
    ca: {
      translation: catalanLiterals,
      lca: lcaLiteralsCatalan,
      purchaseCodes: purchaseCodesCatalan,
      countryNames: countryNamesCatalan,
      errors: errorsCatalan,
      notification: notificationCatalan,
      apiErrors: apiErrorsCatalan,
      holdings: holdingsCatalan
    },
    es: {
      lca: lcaLiteralsSpanish,
      purchaseCodes: purchaseCodesSpanish,
      translation: spanishLiterals,
      countryNames: countryNamesSpanish,
      errors: errorsSpanish,
      notification: notificationSpanish,
      apiErrors: apiErrorsSpanish,
      holdings: holdingsEs,
      tocs: tocsSpanish
    },
    en: {
      lca: lcaLiteralsEnglish,
      purchaseCodes: purchaseCodesEnglish,
      translation: englishLiterals,
      countryNames: countryNamesEnglish,
      errors: errorsEnglish,
      notification: notificationEnglish,
      apiErrors: apiErrorsEnglish,
      holdings: holdingsEn,
      tocs: tocsEnglish
    },
    pt: {
      lca: lcaLiteralsPortuguese,
      purchaseCodes: purchaseCodesPortuguese,
      translation: portugueseLiterals,
      countryNames: countryNamesPortuguese,
      errors: errorsPortuguese,
      notification: notificationPortuguese,
      apiErrors: apiErrorsPortuguese,
      holdings: holdingsPt,
      tocs: tocsPortuguese
    }
  },
  react: {
    // https://react.i18next.com/latest/trans-component#trans-props
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i', 'span']
  }
});

export default i18next;
