import apiFetch from '../../utils/apiFetch';

export const validatePassword = async (password: string) => {
  try {
    const response = await apiFetch('POST', '/admin/validate-password', {
      password
    });
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getOrganizationById = async (id: string) => {
  try {
    const response = await apiFetch('GET', `/organizations/${id}`, null);

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getUsers = async (password: string) => {
  try {
    const response = await apiFetch('GET', '/users', null, {
      'x-password': password
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getOrganizationUsageMetrics = async (id: string) => {
  try {
    const response = await apiFetch('GET', `/organizations/metrics/${id}`);

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getPressignedUrlLCAScenarios = async (organizationId: string, fileName: string) => {
  try {
    const response = await apiFetch('POST', '/lcas/scenarios/upload', {
      organization_id: organizationId,
      file_name: fileName
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const createContract = async (
  payload: PayloadCreateContract
): Promise<ResponseCreateContract> => {
  const response = await apiFetch('POST', '/holdings/create/contract', payload);

  return response.data;
};
