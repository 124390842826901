export const adapterConfirmLink = (
  confirmLinkFront: ConfirmLinkPayloadFront
): ConfirmLinkPayload => {
  return {
    parent_id: confirmLinkFront.holdingId,
    child_id: confirmLinkFront.companyId,
    share: parseFloat(confirmLinkFront.share) / 100,
    investment_scopes: confirmLinkFront.includeScope3 ? [1, 2, 3] : [1, 2],
    answer: 'accepted',
    tag: confirmLinkFront.tag
  };
};

export const adapterCreateAndLinkChildren = ({
  createOrganizationHoldingInfo,
  user_id
}: CreateAndLinkChildrenPayloadFront): CreateAndLinkChildrenPayload => {
  return {
    country: createOrganizationHoldingInfo.country,
    company_name: createOrganizationHoldingInfo.company_name,
    sector: createOrganizationHoldingInfo.sector,
    employee_count_signup: createOrganizationHoldingInfo.employee_count_signup,
    vat: createOrganizationHoldingInfo.vat,
    user_id,
    share: createOrganizationHoldingInfo.share,
    investment_scopes: createOrganizationHoldingInfo.includeScope3 ? [1, 2, 3] : [1, 2],
    tag: createOrganizationHoldingInfo.tag
  };
};
