import { environment as enviromnentAutonomy } from './environment-autonomy';
import { environment as environmentDelight } from './environment-delight';
import { environment as environmentDev } from './environment-dev';
import { environment as environmentGrowth } from './environment-growth';
import { environment as environmentLocal } from './environment-local';
import { environment as environmentLocalDev } from './environment-local-dev';
import { environment as environmentLocalStag } from './environment-local-stag';
import { environment as environmentProd } from './environment-prod';
import { getEnvironmentSandbox } from './environment-sandbox';
import { environment as environmentStag } from './environment-stag';

export default function controller() {
  const env = process.env.REACT_APP_ENVIRONMENT;
  const user = process.env.REACT_APP_ENVIRONMENT_USER;

  switch (env) {
    case 'local':
      return environmentLocal;
    case 'dev':
      return environmentDev;
    case 'stag':
      return environmentStag;
    case 'prod':
      return environmentProd;
    case 'sandbox':
      return getEnvironmentSandbox(user);
    case 'local-dev':
      return environmentLocalDev;
    case 'local-stag':
      return environmentLocalStag;
    case 'autonomy':
      return enviromnentAutonomy;
    case 'delight':
      return environmentDelight;
    case 'growth':
      return environmentGrowth;
    default:
      return environmentProd;
  }
}
