import Icon from 'components/ui/icon/Icon';
import { IconName } from 'types/utils/iconName';
import { ICON_SIZES, VARIANTS } from './constants';

type IconProps = Omit<React.ComponentProps<typeof Icon>, 'color'> & {
  icon: IconName;
  lookAndFeel?: keyof typeof VARIANTS;
  disabled?: boolean;
};

export const HoldingIcon: React.FC<IconProps> = ({
  icon,
  lookAndFeel = 'default',
  disabled,
  style,
  ...props
}) => {
  const { bg, icon: color } = VARIANTS[lookAndFeel];

  if (disabled) {
    return (
      <div
        className={`${
          ICON_SIZES[props.size ?? 'large']
        } flex items-center justify-center border-box  bg-neutral-gray-warm-40`}
        style={style}>
        <Icon icon={icon} color='white' size='large' {...props} />
      </div>
    );
  }

  return (
    <div
      className={`${
        ICON_SIZES[props.size ?? 'large']
      } flex items-center justify-center border-box ${bg}`}
      style={style}>
      <Icon icon={icon} color={color} size='large' {...props} />
    </div>
  );
};
