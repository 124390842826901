import { useQuery } from '@tanstack/react-query';
import { adaptLCATransportDetails } from 'adapters/adaptLCATransport';
import { getLCATransportDetails } from 'services/api/lca/transport';

type Params = {
  acv_id: string;
  node_id: string;
  enabled?: boolean;
};

export const useTransportDetails = ({ acv_id, node_id, enabled }: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: ['lca-transport-details', acv_id, node_id],
    queryFn: getLCATransportDetails,
    select: adaptLCATransportDetails,
    enabled
  });

  return { data, isLoading };
};
