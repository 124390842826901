import { FirstStep } from './Steps/FirstStep';
import { SecondStep } from './Steps/SecondStep';
import { ThirdStep } from './Steps/ThirdStep';

export const STEP_IDS = {
  FIRST: 'FIRST',
  SECOND: 'SECOND',
  THIRD: 'THIRD'
} as const;

export const STEP_COMPONENTS = {
  [STEP_IDS.FIRST]: FirstStep,
  [STEP_IDS.SECOND]: SecondStep,
  [STEP_IDS.THIRD]: ThirdStep
} as const;

export const STEPS = [
  {
    id: STEP_IDS.FIRST,
    text: 'lcaDetail.processFlow.processModal.basicInfo'
  },
  {
    id: STEP_IDS.SECOND,
    text: 'lcaDetail.processFlow.processModal.inputs'
  },
  {
    id: STEP_IDS.THIRD,
    text: 'lcaDetail.processFlow.processModal.referenceOutput'
  }
] as const;

export const UNIT_DEFAULT = 'bf800281-bcc7-42f4-81e4-8ec9abb9745e';
export const QUANTITY_DEFAULT = '1';
