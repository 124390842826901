import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../../ui/cards/card/Card';
import TooltipWrapper from '../../../../ui/tooltip/TooltipWrapper';
import { DisabledProvider } from './DisabledProvider';
import { useDisabledContext } from './useDisabledContext';

type Props = {
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
};

const CardEsg = ({ onClick, children, disabled = false }: Props) => {
  return (
    <DisabledProvider disabled={disabled}>
      <TooltipWrapper
        shouldAppear={disabled}
        asChild
        text='Solo los propietarios pueden acceder a esta categoría. Solicita un cambio de rol a las personas de tu equipo.'>
        <Card listView={false} onClick={onClick} disabled={disabled}>
          <Card.Content>{children}</Card.Content>
        </Card>
      </TooltipWrapper>
    </DisabledProvider>
  );
};

// Card Title Component
const Title = ({ title }: { title: string }) => {
  const disabled = useDisabledContext();
  const className = ['font-14 weight-600 inter'];

  if (disabled) {
    className.push('text-neutral-gray-warm-20');
  }

  return <span className={className.join(' ')}>{title}</span>;
};

// Card Description Component
const Description = ({ descriptions }: { descriptions: string[] }) => {
  const disabled = useDisabledContext();

  const className = ['font-12 weight-400 inter'];

  if (disabled) {
    className.push('text-neutral-gray-warm-20');
  }

  return (
    <div>
      {descriptions.map((description, index) => (
        <div key={index}>
          <span className={className.join(' ')}>{description}</span>
        </div>
      ))}
    </div>
  );
};

// Card Subtitle Component
const Subtitle = ({
  mandatoryDatapoints,
  datapoints
}: {
  mandatoryDatapoints: number;
  datapoints: number;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esg' });

  const mandatoryDatapointsText = `${mandatoryDatapoints} ${t('mandatoryDatapoints')}`;
  const datapointsText = `${datapoints} ${t('datapoints')}`;

  return <Description descriptions={[datapointsText, mandatoryDatapointsText]} />;
};

// Card Completeness Component
/* commented till data comes from backend 
const Completeness = ({ completeness }: { completeness: number }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esg' });

  return (
    <span className='subtitle3-font'>
      <span className='highlight-text-color'>{formatNumberToDecimalNonZero(completeness)}%</span>{' '}
      {t('completeness')}
    </span>
  );
}; */

// Card ESRS Component
const Esrs = ({ esrs }: { esrs: string }) => {
  const disabled = useDisabledContext();

  const className = ['font-12 weight-400 inter p-1 border-box rounded-4'];

  if (!disabled) {
    className.push('text-secondary-purple-20 bg-secondary-purple-80');
  }

  if (disabled) {
    className.push('text-neutral-gray-warm-20 bg-neutral-gray-warm-60');
  }

  return <span className={className.join(' ')}>{esrs}</span>;
};

const CardContentDisabled = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='card-wrapper__content' style={{ opacity: 0.5 }}>
      {children}
    </div>
  );
};

const Tooltip = ({ text, children }: { text: string; children: React.ReactNode }) => {
  return <TooltipWrapper text={text}>{children}</TooltipWrapper>;
};

CardEsg.Title = Title;
CardEsg.Description = Description;
CardEsg.Subtitle = Subtitle;
/* CardEsg.Completeness = Completeness; */
CardEsg.Esrs = Esrs;
CardEsg.ContentDisabled = CardContentDisabled;
CardEsg.Tooltip = Tooltip;

export default CardEsg;
