export const getVariant = (isValid: boolean) => {
  if (isValid) {
    return {
      lookAndFeel: 'secondary',
      i18nKey: 'editOrg',
      icon: {
        name: 'edit',
        color: 'gray-dark'
      }
    } as const;
  }

  return {
    lookAndFeel: 'primary',
    i18nKey: 'fillOrg',
    icon: {
      name: 'add',
      color: 'white'
    }
  } as const;
};
