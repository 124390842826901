import { applyEdgeChanges, Edge, EdgeChange } from '@xyflow/react';
import { useCallback, useEffect, useState } from 'react';
import { HoldingConnection } from 'types/entities/holdings';
import { buildEdgeFromConnection, buildEdges } from '../utils';

type Params = {
  holdingConnections: HoldingConnection[];
  isLoading: boolean;
};
export const useHoldingEdges = ({ holdingConnections, isLoading }: Params) => {
  const [edges, setEdges] = useState<Edge[]>([]);

  useEffect(() => {
    if (isLoading) return;

    setEdges(holdingConnections.flatMap(buildEdgeFromConnection));
  }, [holdingConnections, isLoading]);

  const onEdgesChange = useCallback((changes: EdgeChange[]) => {
    setEdges((prevEdges) => {
      return buildEdges(applyEdgeChanges(changes, prevEdges));
    });
  }, []);

  return [edges, setEdges, onEdgesChange] as const;
};
