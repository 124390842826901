import type { ReactFlowProps } from '@xyflow/react';

export const DIAGRAM_CONFIG = {
  NODE_ORIGIN: [0.5, 0.5] as ReactFlowProps['nodeOrigin'],
  MIN_ZOOM: 0.0001 as ReactFlowProps['minZoom'],
  PRO_OPTIONS: {
    hideAttribution: true
  } as ReactFlowProps['proOptions'],
  NODE_DRAG_THRESHOLD: 3 as ReactFlowProps['nodeDragThreshold']
};
