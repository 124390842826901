import Modal from 'components/ui/modal/Modal';
import { useModalManager } from '../ModalManager';
import { ShareForm } from '../ShareForm';
import Button from 'components/ui/button/Button';
import { usePatchShare } from '../../hooks/usePatchShare';
import { shareSchema } from '../../schemas/shareSchema';
import { HoldingEdge, ShareFormValues } from 'types/entities/holdings';
import { useEdge } from 'lib/ReactFlow/useEdge';
import {
  adapterHoldingEdgeToShareFormValues,
  adapterShareFormValuesToPatchPayload
} from 'adapters/adaptHoldingConnections';
import { useHoldingTranslations } from 'customHooks/translations';

type Props = {
  edge_id: string;
};

export const ShareModal: React.FC<Props> = ({ edge_id }) => {
  const { closeModal } = useModalManager();
  const { patchShare, isMutating } = usePatchShare();
  const { t } = useHoldingTranslations();

  const onCancel = () => {
    if (isMutating) {
      return;
    }

    closeModal();
  };

  const edge = useEdge<HoldingEdge>(edge_id);

  const defaultValues = adapterHoldingEdgeToShareFormValues(edge);

  const onSubmit = async (formValues: ShareFormValues) => {
    if (!edge || isMutating) {
      return;
    }

    await patchShare(
      adapterShareFormValuesToPatchPayload({
        source_id: edge.source,
        target_id: edge.target,
        formValues
      })
    );

    closeModal();
  };

  return (
    <Modal.WithPortal show onClose={closeModal}>
      <Modal.Header title={t('shareModal.title')} description={t('shareModal.description')} />
      <Modal.Content>
        <ShareForm defaultValues={defaultValues} onSubmit={onSubmit} zodSchema={shareSchema} />
      </Modal.Content>
      <Modal.Buttons>
        <Button
          lookAndFeel='secondary'
          size='medium'
          onClick={onCancel}
          text={t('shareModal.cancel')}
        />
        <ShareForm.SubmitBTN loading={isMutating} />
      </Modal.Buttons>
    </Modal.WithPortal>
  );
};
