import { queryClient } from 'lib/ReactQuery/client';

export const createLCAMaterialsLoaderSlice = (set: (newVal: boolean) => void) => {
  return {
    lcaMaterialsLoader: false,
    triggerMaterialsLoader: () => {
      set(true);

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['lca-materials'] });

        set(false);
      }, 30 * 1000);
    }
  };
};

export type LCAMaterialsLoaderSlice = ReturnType<typeof createLCAMaterialsLoaderSlice>;
