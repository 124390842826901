import { useTranslation } from 'react-i18next';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { HEAVY_FILE_STATUS } from '../../../../types/entitiesEnums/heavyFilesStatus';
import { CATEGORIES_TRANSLATION, CategoriesUploadedFiles } from '../constants';
import { useIsOwner } from 'customHooks/useIsOwner';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import Icon from 'components/ui/icon/Icon';

const useConstants = () => {
  const { t } = useTranslation();
  const selectedOrganization = useSelectedOrganization();
  const { isOwner, isLoading } = useIsOwner();

  const selectOptions: SelectOptionFormat[] = [
    { id: HEAVY_FILE_STATUS.ERROR, name: t('status.error') },
    { id: HEAVY_FILE_STATUS.IN_PROGRESS, name: t('status.pending') },
    { id: HEAVY_FILE_STATUS.IN_QUEUE, name: t('status.queue') },
    { id: HEAVY_FILE_STATUS.SUCCESS, name: t('status.success') },
    { id: HEAVY_FILE_STATUS.DELETED, name: t('status.deleted') },
    { id: HEAVY_FILE_STATUS.DELETING, name: t('status.deleting') },
    { id: '', name: t('status.all') }
  ];

  const steps = [
    {
      id: CategoriesUploadedFiles.VEHICLES,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.VEHICLES])
    },
    {
      id: CategoriesUploadedFiles.VEHICLE_CONSUMPTIONS,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.VEHICLE_CONSUMPTIONS])
    },

    {
      id: CategoriesUploadedFiles.BUSINESS_TRAVELS,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.BUSINESS_TRAVELS])
    },
    {
      id: CategoriesUploadedFiles.TRANSPORT_DOWNSTREAM,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.TRANSPORT_DOWNSTREAM])
    },
    {
      id: CategoriesUploadedFiles.TRANSPORT_UPSTREAM,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.TRANSPORT_UPSTREAM])
    },
    {
      id: CategoriesUploadedFiles.PURCHASES,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.PURCHASES])
    },
    {
      id: CategoriesUploadedFiles.WASTES,
      text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.WASTES])
    },
    {
      id: CategoriesUploadedFiles.ESG,
      text: isOwner ? (
        t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.ESG])
      ) : (
        <TooltipWrapper asChild text={t('esgMetrics.social.own_workforce_disabledTooltip')}>
          <span className='flex items-center gap-x-1'>
            {t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.ESG])}
            <Icon icon='lock' color='disabled' size='small' />
          </span>
        </TooltipWrapper>
      ),
      disabled: !isOwner
    }
  ];

  if (selectedOrganization?.sector === 'transport') {
    steps.push(
      {
        id: CategoriesUploadedFiles.LOGISTICS_RECHARGES,
        text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.LOGISTICS_RECHARGES])
      },
      {
        id: CategoriesUploadedFiles.LOGISTICS_REQUESTS,
        text: t(CATEGORIES_TRANSLATION[CategoriesUploadedFiles.LOGISTICS_REQUESTS])
      }
    );
  }
  return { selectOptions, steps, categories: CategoriesUploadedFiles, isLoading };
};

export default useConstants;
