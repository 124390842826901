import React from 'react';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';
import { useTranslation } from 'react-i18next';

const MobileEmissionsDisclaimer = () => {
  const { t } = useTranslation();
  // Create a list of 9 elements with numbers from 1 to 9, which is the number of descriptions
  const elements = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <DescriptionBox
      descriptions={elements.map((element) =>
        t(`ghgReport.mobileEmissionsDisclaimer.description${element}`)
      )}
    />
  );
};

export default MobileEmissionsDisclaimer;
