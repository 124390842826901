import type { QueryFunction } from '@tanstack/react-query';
import type {
  GetHoldingConnectionsQueryKey,
  HoldingConnectionBack,
  PayloadCreateConnection,
  PayloadDeleteConnection,
  PayloadPatchShare,
  ResponseCreateConnection,
  ResponsePatchShare
} from 'types/entities/holdings';
import apiFetch from 'utils/apiFetch';

export const getConnections: QueryFunction<
  HoldingConnectionBack[],
  GetHoldingConnectionsQueryKey
> = async ({ queryKey }) => {
  const [, contract_org_id] = queryKey;

  const response = await apiFetch('GET', `/holdings/connections/${contract_org_id}`);

  return response.data;
};

export const createConnection = async ({
  source_id,
  target_id
}: PayloadCreateConnection): Promise<ResponseCreateConnection> => {
  const response = await apiFetch('POST', `/holdings/create/connection/${source_id}/${target_id}`);

  return response.data;
};

export const deleteConnection = async ({
  source_id,
  target_id
}: PayloadDeleteConnection): Promise<boolean> => {
  const response = await apiFetch(
    'DELETE',
    `/holdings/delete/connection/${source_id}/${target_id}`
  );

  return response.data;
};

export const patchShare = async ({
  source_id,
  target_id,
  share
}: PayloadPatchShare): Promise<ResponsePatchShare> => {
  const response = await apiFetch('PATCH', `/holdings/share/${source_id}/${target_id}`, { share });

  return response.data;
};
