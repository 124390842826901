import { useEffect, useState } from 'react';
import { getOrganizationById } from '../../../../services/api/admin';
import { getOrganizations } from '../../../../services/api/organization';
import { Organization } from '../../../../types/entities/organization';
import { User } from '../../../../types/entities/user';

type Props = {
  organization_id: string;
};
const useGetData = ({ organization_id }: Props) => {
  const [organizationOptions, setOrganizationOptions] = useState<SelectOptionFormat[]>([]); // get organizations from backend
  const [members, setMembers] = useState<SelectOptionFormat[]>([]); // get members from backend

  const fetchData = async () => {
    const data = await getOrganizations();
    if (data) {
      setOrganizationOptions(
        data.map((organization: Organization) => ({
          id: organization.id,
          name: organization.company_name
        }))
      );
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchUsers = async () => {
    const data = await getOrganizationById(organization_id);
    if (!data) return;
    setMembers(
      data.users
        .filter((elem: User) => elem.first_name)
        .map((elem: User) => ({
          id: elem.id,
          name: elem.first_name + ' ' + elem.last_name
        }))
    );
  };

  useEffect(() => {
    if (!organization_id) return;
    fetchUsers();
  }, [organization_id]);
  return { organizationOptions, members };
};

export default useGetData;
