import { useQuery } from '@tanstack/react-query';
import { adaptLCATransportMetadata } from 'adapters/adaptLCAMetadata';
import { getLCATransportMetadata } from 'services/api/lca/transport';

type Params = {
  acv_id: string;
  node_id: string;
};

export const useLCATransportMetadata = ({ acv_id, node_id }: Params) => {
  const { data } = useQuery({
    queryKey: ['lca-transport', acv_id, node_id],
    queryFn: getLCATransportMetadata,
    select: adaptLCATransportMetadata
  });

  return { data };
};
