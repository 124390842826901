import { createContext } from 'react';
import type { ModalName } from './types';

type ModalManagerContextType = {
  modal: { modalName: ModalName; payload?: string } | null;
  openModal: (modalName: ModalName, payload?: string) => void;
  closeModal: () => void;
};

export const ModalManagerContext = createContext<ModalManagerContextType | null>(null);
