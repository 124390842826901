import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sectors } from '../../../constants/sectors';
import useOnChangeValue from '../../../customHooks/useOnChangeValue';
import { Organization } from '../../../types/entities/organization';
import apiFetch from '../../../utils/apiFetch';
import checkFormErrors from '../../../utils/checkFormErrors';
import { convertDateToString, convertStringToDate } from '../../../utils/convertDates';
import { formatDate } from '../../../utils/formatDate';
import Button from '../../ui/button/Button';
import useSubscriptionPlanOptions from './hooks/useSubscriptionPlanOptions';
import Modal from '../../ui/modal/Modal';
import FormWrapper from '../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../ui/formComponents2/formInputs/formText/FormText';
import FormNumber from '../../ui/formComponents2/formInputs/formNumber/FormNumber';
import FormCalendar from '../../ui/formComponents2/formInputs/formCalendar/FormCalendar';
import Checkbox from 'components/ui/formComponents2/checkbox/Checkbox';
import LoaderStandard from 'components/ui/loaders/loaderStandard/LoaderStandard';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import useFrameworksPlans from 'customHooks/api/useFrameworksPlans';
import useSaveFrameworksPlans from 'customHooks/api/useSaveFrameworksPlans';
import { RadioButton } from 'components/ui/formComponents2/radioButton/RadioButton';
import { GrayBox } from 'components/ui/GrayBox';
import { useFormWithHelpers } from 'lib/ReactHookForm/useFormWithHelpers';
import { zodResolver } from '@hookform/resolvers/zod';
import { adaptHookFormError } from 'lib/ReactHookForm/adaptError';
import { REALLY_BIG_NUMBER } from './constants';
import { getContractSchema } from './utils/getContractSchema';
import { SubmitHandler } from 'react-hook-form';
import { useErrorTranslations } from 'customHooks/translations';
import { createContract } from 'services/api/admin';
import { adapterContractAdmin, adapterCreateContract } from 'adapters/adaptContract';
import { getDefaultScope3, getScope3DefaultFields } from './utils/getDefaultScope3';
import { getContract } from 'services/api/holdings';

type FormData = {
  name: string;
  sector: SelectOptionFormat;
  limitFacilities: number;
  limitVehicles: number;
  limitEmployees: number;
  limitBusinessTravels: number;
  limitUsers: number;
  limitShipments: number;
  limitDeliveries: number;
  limitPurchases: number;
  limitOfficialSupplierBonus: string;
  subscriptionPlan: SelectOptionFormat;
  lastPaymentDate: string;
  subscriptionPlanExpireDate: string;
  employeeCountSignup: number;
  vat: string;
  hasContract: boolean;
  errors: ErrorType[];
};
type AvailableFramework = {
  id: string;
  name: string;
  version: string;
};
type Props = {
  organizationToEdit: Organization;
  editOrganization: (value: Organization, id: string) => void;
};

function EditOrganization({ organizationToEdit, editOrganization }: Props) {
  const { t, i18n } = useTranslation();
  const { t: tErrors } = useErrorTranslations();
  const flags = useFeatureFlags();
  const subscriptionPlanOptions = useSubscriptionPlanOptions();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate());
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setMonth(0);
  oneYearAgo.setDate(1);
  oneYearAgo.setHours(0, 0, 0, 0);

  let sectorParsed = '';
  const sectorFound = sectors.find((elem) => elem.id === organizationToEdit.sector) as {
    title: { en: string; es: string };
  };
  if (sectorFound) {
    sectorParsed = sectorFound.title[i18n.language.split('-')[0] as keyof Language];
  }

  const defaultScope3 = getDefaultScope3(organizationToEdit);

  const [formData, setFormData] = useState<FormData>({
    name: organizationToEdit.company_name || '',
    sector: { id: organizationToEdit.sector || '', name: sectorParsed },
    limitFacilities: organizationToEdit.limit_facilities || 0,
    limitVehicles: organizationToEdit.limit_vehicles || 0,
    limitEmployees: organizationToEdit.limit_employees || 0,
    limitBusinessTravels: defaultScope3.limitBusinessTravels,
    limitUsers: REALLY_BIG_NUMBER,
    limitShipments: defaultScope3.limitShipments,
    limitDeliveries: defaultScope3.limitDeliveries,
    limitPurchases: defaultScope3.limitPurchases,
    limitOfficialSupplierBonus: organizationToEdit.limit_official_suppliers_bonus ?? '0',
    subscriptionPlan: {
      id: organizationToEdit.subscription_plan,
      name: t(`admin.${organizationToEdit.subscription_plan}`)
    },
    lastPaymentDate: formatDate(
      organizationToEdit.last_payment_date
        ? new Date(organizationToEdit.last_payment_date)
        : new Date()
    ),
    subscriptionPlanExpireDate: formatDate(
      organizationToEdit.subscription_plan_expire_date
        ? new Date(organizationToEdit.subscription_plan_expire_date)
        : new Date()
    ),
    employeeCountSignup: organizationToEdit.employee_count_signup || 0,
    vat: organizationToEdit.vat || '',
    hasContract: organizationToEdit.has_contract || false,
    errors: []
  });

  const [loadingButton, setLoadingButton] = useState(false);
  const [frameworkSelections, setFrameworkSelections] = useState<Record<string, boolean>>({});
  const { onChangeValue } = useOnChangeValue<FormData>({ setFormData });
  const [hasContract, setHasContract] = useState<boolean>(organizationToEdit.has_contract ?? false);
  const [hasScope3, setHasScope3] = useState<boolean>(defaultScope3.hasScope3);

  const { registerNumber, formState, handleSubmit } = useFormWithHelpers({
    defaultValues: () =>
      getContract(organizationToEdit.id)
        .then(adapterContractAdmin)
        .catch(() => adapterContractAdmin(null)),
    resolver: zodResolver(getContractSchema(hasContract))
  });

  const { availableFrameworks, frameworkPlans, frameworksLoading } = useFrameworksPlans(
    organizationToEdit.id
  );
  const { saveFrameworkPlans } = useSaveFrameworksPlans(organizationToEdit.id);
  useEffect(() => {
    // Initialize frameworkSelections
    let selections: Record<string, boolean> = {};
    if (availableFrameworks && frameworkPlans) {
      selections = availableFrameworks.reduce(
        (acc: Record<string, boolean>, framework: AvailableFramework) => {
          const existingPlan = frameworkPlans.find((plan) => plan?.framework.id === framework.id);
          acc[framework.id] = existingPlan?.purchased || false;
          return acc;
        },
        {}
      );
      setFrameworkSelections(selections);
    }
  }, [
    organizationToEdit.id,
    frameworksLoading,
    JSON.stringify(availableFrameworks),
    JSON.stringify(frameworkPlans)
  ]);

  const handleErrors = () => {
    const optionalFields = ['errors'];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));

    if (newErrors.length !== 0) return newErrors;
    return false;
  };

  const onChangeHasContract = (value: boolean) => () => {
    if (organizationToEdit.has_contract && !value) {
      return;
    }

    setHasContract(value);
    onChangeValue('hasContract')(value);
  };

  const onChangeSubscriptionPlan = (value: SelectOptionFormat) => {
    setFormData({
      ...formData,
      subscriptionPlan: value,
      limitFacilities: 1,
      limitVehicles: 1,
      limitEmployees: 0,
      limitBusinessTravels: 0,
      limitUsers: 1,
      limitShipments: 0,
      limitDeliveries: 0,
      limitPurchases: 0,
      subscriptionPlanExpireDate: formatDate(new Date(today.setDate(today.getDate() + 14)))
    });
    setHasScope3(false);
    // Check if ghg_protocol framework is available and if so, check or uncheck depending on the subscription plan selected
    if (!availableFrameworks?.length) return;
    const ghgProtocol = availableFrameworks.find((framework) => framework.name === 'ghg_protocol');
    if (ghgProtocol) {
      setFrameworkSelections((prev) => ({
        ...prev,
        [ghgProtocol.id]: 'custom_paid_plan' === value.id
      }));
    }
  };

  const handleFrameworkChange = (frameworkId: string) => {
    setFrameworkSelections((prev) => ({
      ...prev,
      [frameworkId]: !prev[frameworkId]
    }));
  };
  const handleEditOrganization = async () => {
    if (handleErrors()) return;

    try {
      setLoadingButton(true);
      // Construct the payload based on the required schema
      const organization = {
        company_name: formData.name,
        limit_facilities: formData.limitFacilities,
        limit_vehicles: formData.limitVehicles,
        limit_employees: formData.limitEmployees,
        limit_business_travels: formData.limitBusinessTravels,
        limit_users: formData.limitUsers,
        limit_purchases: formData.limitPurchases,
        limit_shipments: formData.limitShipments,
        limit_deliveries: formData.limitDeliveries,
        limit_official_suppliers_bonus: formData.limitOfficialSupplierBonus,
        subscription_plan: formData.subscriptionPlan.id,
        last_payment_date: moment(convertStringToDate(formData.lastPaymentDate)).format(
          'YYYY-MM-DD'
        ),
        subscription_plan_expire_date: formData.subscriptionPlanExpireDate
          ? moment(convertStringToDate(formData.subscriptionPlanExpireDate)).format('YYYY-MM-DD')
          : null,
        employee_count_signup: formData.employeeCountSignup,
        vat: formData.vat,
        country: organizationToEdit.country,
        sector: formData.sector.id,
        has_contract: formData.hasContract
      };
      if (formData.subscriptionPlan.id === 'free_plan') {
        organization.limit_facilities = 1;
        organization.limit_vehicles = 1;
        organization.limit_employees = 0;
        organization.limit_business_travels = 0;
        organization.limit_users = 1;
        organization.limit_purchases = 0;
        organization.limit_shipments = 0;
        organization.limit_deliveries = 0;
        organization.limit_official_suppliers_bonus = '0';
      }
      if (flags?.sotDcycleDemos) {
        // Prepare framework plans data
        const frameworkPlansToSave = availableFrameworks?.map((framework) => {
          const existingPlan = frameworkPlans?.find((plan) => plan.framework.id === framework.id);

          return {
            id: existingPlan?.id, // Existing plan ID, or undefined for new plans
            framework_id: framework.id,
            purchased: frameworkSelections[framework.id],
            visible: existingPlan?.visible || false,
            plan_start_date: existingPlan?.plan_start_date || moment().format('YYYY-MM-DD'),
            plan_end_date: existingPlan?.plan_end_date || moment().format('YYYY-MM-DD')
          };
        });

        // Save all framework plans
        if (frameworkPlansToSave) {
          await saveFrameworkPlans(frameworkPlansToSave);
        }
      }

      const response = await apiFetch(
        'PATCH',
        `/organizations/${organizationToEdit.id}`,
        organization
      );

      if (response.status === 200) {
        editOrganization(response.data, organizationToEdit.id);
      }
    } catch (error) {
      console.error('Error updating organization:', error);
    } finally {
      setLoadingButton(false);
    }
  };
  // variable that represents today date plus 5 years
  const fiveYearsFromNow = new Date();
  fiveYearsFromNow.setFullYear(fiveYearsFromNow.getFullYear() + 5);

  const disabled = formData.subscriptionPlan.id === 'free_plan';

  const onSubmit: SubmitHandler<ContractFormValues> = (data) => {
    if (hasContract) {
      createContract(adapterCreateContract({ organizationId: organizationToEdit.id, form: data }));
    }

    handleEditOrganization();
  };

  const onChangeScope3 = (value: boolean) => () => {
    setHasScope3(value);
    setFormData((prev) => ({
      ...prev,
      ...getScope3DefaultFields(value)
    }));
  };

  return (
    <form className='edit-organization' id='edit-organization' onSubmit={handleSubmit(onSubmit)}>
      <Modal.Header title={t('admin.editOrganization')} />
      <Modal.Content>
        <FormWrapper>
          <FormSelect
            icon={'/images/icons/piechart.svg'}
            label={t('admin.sector')}
            value={formData.sector}
            onChange={onChangeValue('sector')}
            placeholder={''}
            options={sectors.map((elem) => {
              return {
                id: elem.id,
                name: elem.title[i18n.language.split('-')[0] as keyof Language]
              };
            })}
            error={formData.errors.find((elem) => elem.error === 'sector')}
          />
          <FormText
            icon={'/images/icons/organization.svg'}
            placeholder={t('admin.writeOrganizationName')}
            label={t('admin.name')}
            value={formData.name}
            onChange={onChangeValue('name')}
            error={formData.errors.find((elem) => elem.error === 'name')}
          />
          <FormText
            label={t('admin.vat')}
            icon='/images/icons/idcard.svg'
            placeholder={t('register.vatPlaceholder')}
            value={formData.vat}
            onChange={onChangeValue('vat')}
            error={formData.errors.find((elem) => elem.error === 'vat')}
          />

          <FormSelect
            label={t('admin.subscriptionPlan')}
            icon='/images/icons/envelope.svg'
            options={subscriptionPlanOptions}
            value={formData.subscriptionPlan}
            onChange={onChangeSubscriptionPlan}
          />
          <FormNumber
            iconV2={'cash'}
            label={t('admin.limitOfficialSupplierBonus')}
            value={formData.limitOfficialSupplierBonus}
            onChange={onChangeValue('limitOfficialSupplierBonus')}
            disabled={disabled}
          />
          <div className='flex flex-col gap-y-2 grow'>
            <span className='font-12 weight-600 inter text-neutral-gray-cold-20'>
              {t('admin.scope3')}
            </span>
            <div className='flex flex-col'>
              <RadioButton
                text={t('admin.notHasScope3')}
                selected={!hasScope3}
                onChange={onChangeScope3(false)}
                disabled={disabled}
              />
              <RadioButton
                text={t('admin.hasScope3')}
                selected={hasScope3}
                onChange={onChangeScope3(true)}
                disabled={disabled}
              />
            </div>
          </div>

          {formData.lastPaymentDate && (
            <FormCalendar
              label={t('admin.lastPaymentDate')}
              handleChangeDate={onChangeValue('lastPaymentDate')}
              dateValue={formData.lastPaymentDate}
              error={formData.errors.find((elem) => elem.error === 'lastPaymentDate')}
            />
          )}
          <FormCalendar
            label={t('admin.subscriptionPlanExpireDate')}
            handleChangeDate={onChangeValue('subscriptionPlanExpireDate')}
            dateValue={formData.subscriptionPlanExpireDate}
            error={formData.errors.find((elem) => elem.error === 'subscriptionPlanExpireDate')}
            maxDate={convertDateToString(fiveYearsFromNow)}
          />
          <div className='flex flex-col gap-y-2'>
            <div className='flex gap-x-2 items-center'>
              <span className='font-12 weight-600 inter text-neutral-gray-cold-20'>
                {t('admin.sharedLimits')}
              </span>
            </div>
            <RadioButton
              text={t('admin.notHasContract')}
              onChange={onChangeHasContract(false)}
              selected={!hasContract}
              disabled={disabled || organizationToEdit.has_contract}
            />
            <RadioButton
              text={t('admin.hasContract')}
              onChange={onChangeHasContract(true)}
              selected={hasContract}
              disabled={disabled}
            />
          </div>
          {hasContract && (
            <GrayBox.Padless className='p-4'>
              <span className='font-12 weight-600 inter text-neutral-gray-cold-20'>
                {t('admin.contractLimitsTitle')}
              </span>
              <div className='grid grid-cols-3 gap-x-2'>
                <FormNumber
                  iconV2='facilities'
                  label={t('admin.contractFacilities')}
                  placeholder={t('admin.writeLimitFacilities')}
                  disabled={disabled}
                  error={adaptHookFormError(formState.errors.facilities, tErrors)}
                  {...registerNumber('facilities')}
                />
                <FormNumber
                  iconV2='car'
                  label={t('admin.contractVehicles')}
                  placeholder={t('admin.writeLimitVehicles')}
                  error={adaptHookFormError(formState.errors.vehicles, tErrors)}
                  disabled={disabled}
                  {...registerNumber('vehicles')}
                />
                <FormNumber
                  iconV2='user'
                  label={t('admin.contractEmployees')}
                  placeholder={t('admin.writeLimitEmployees')}
                  error={adaptHookFormError(formState.errors.employees, tErrors)}
                  disabled={disabled}
                  {...registerNumber('employees')}
                />
              </div>
            </GrayBox.Padless>
          )}
          <div className='grid grid-cols-3 gap-x-2 gap-y-3'>
            <div className='col-span-3 flex gap-x-2 items-center'>
              <span className='font-12 weight-600 inter text-neutral-gray-cold-20'>
                {t('admin.limitsTitle')}
              </span>
            </div>
            <FormNumber
              iconV2='facilities'
              label={t('admin.limitFacilities')}
              placeholder={t('admin.writeLimitFacilities')}
              value={formData.limitFacilities.toString()}
              onChange={onChangeValue('limitFacilities')}
              disabled={disabled}
            />
            <FormNumber
              iconV2='car'
              label={t('admin.limitVehicles')}
              placeholder={t('admin.writeLimitVehicles')}
              value={formData.limitVehicles.toString()}
              onChange={onChangeValue('limitVehicles')}
              disabled={disabled}
            />
            <FormNumber
              iconV2='user'
              label={t('admin.limitEmployees')}
              placeholder={t('admin.writeLimitEmployees')}
              value={formData.limitEmployees.toString()}
              onChange={onChangeValue('limitEmployees')}
              disabled={disabled}
            />
          </div>
        </FormWrapper>
        {flags?.sotDcycleDemos &&
          (frameworksLoading && availableFrameworks?.length ? (
            <LoaderStandard />
          ) : (
            <div className='flex flex-col gap-y-2'>
              <h2 className='m-0 p-0 font-12 weight-600 w-full'>{t('admin.frameworksInPlan')}</h2>
              <div className='grid grid-cols-2 gap-x-2 gap-y-2'>
                {(availableFrameworks || []).map((framework) => (
                  <div className='input' key={framework.id}>
                    <Checkbox
                      text={t(`admin.frameworks.${framework.name}`)}
                      onChange={() => handleFrameworkChange(framework.id)}
                      selected={frameworkSelections[framework.id]}
                      type='square'
                      disabled={disabled}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
      </Modal.Content>
      <Modal.Buttons>
        <Button
          lookAndFeel='primary'
          type='submit'
          text={t('admin.save')}
          loading={loadingButton}
          form='edit-organization'
        />
      </Modal.Buttons>
    </form>
  );
}

export default EditOrganization;
