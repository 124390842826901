import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotificationTranslation } from 'customHooks/translations/useNotificationTranslation';
import { useNotification } from 'customHooks/useNotification';
import { deleteMaterial } from 'services/api/lca/materialManagement';

export const useDeleteLCAOfficialMaterial = () => {
  const { t } = useNotificationTranslation();
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteMaterial,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lca-materials'] });
      notify(t('lcaMaterialDelete.success'));
    },
    onError: () => {
      notify(t('lcaMaterialDelete.error'));
    }
  });

  return { deleteMaterial: mutateAsync, isPending };
};
