import { QueryFunction } from '@tanstack/react-query';
import type {
  GetHoldingBlocksQueryKey,
  OrganizationBlockBack,
  PayloadCreateOrganizationBlock,
  PayloadMoveOrganizationBlock,
  ResponseCreateOrganizationBlock
} from 'types/entities/holdings';
import apiFetch from 'utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';

export const getOrganizationBlocks: QueryFunction<
  OrganizationBlockBack[],
  GetHoldingBlocksQueryKey
> = async ({ queryKey }) => {
  const [, organizationId, mode] = queryKey;

  const response = await apiFetch(
    'GET',
    getUrl(`/holdings/canvas/${organizationId}`, {
      queryParams: generateQueryParamsFromObject({ mode })
    })
  );

  return response.data;
};

export const createOrganizationBlock = async ({
  coordinates_x,
  coordinates_y,
  source_id
}: PayloadCreateOrganizationBlock): Promise<ResponseCreateOrganizationBlock> => {
  const response = await apiFetch('POST', '/holdings/create/block', {
    coordinates_x,
    coordinates_y,
    source_id
  });

  return response.data;
};

export const moveOrganizationBlocks = async ({
  contract_org_id,
  blocks
}: PayloadMoveOrganizationBlock): Promise<OrganizationBlockBack[]> => {
  const response = await apiFetch('PATCH', `/holdings/move/blocks/${contract_org_id}`, {
    blocks
  });

  return response.data;
};
