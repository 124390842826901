import { HoldingConnection, HoldingEdge } from 'types/entities/holdings';
import { assignEdgeType } from './assignEdgeType';

export const buildEdgeFromConnection = (connection: HoldingConnection): HoldingEdge => {
  return {
    id: `${connection.source_id}-${connection.target_id}`,
    source: connection.source_id,
    target: connection.target_id,
    data: { share: connection.share },
    type: assignEdgeType(connection.share)
  };
};
