import { switchOrganization } from 'actions/auth';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import { ROUTES } from 'constants/routes';
import { useHoldingTranslations } from 'customHooks/translations';
import { useUser } from 'customHooks/useUser';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_TOOLTIP_DELAY_HOLDINGS_MS } from '../../constants';

type Props = {
  org_id: string;
};
export const SwitchOrgBTN: React.FC<Props> = ({ org_id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useHoldingTranslations();

  const user = useUser();

  if (
    !user?.organizations?.some((org) => org.id === org_id) ||
    user.selectedOrganization === org_id
  ) {
    return null;
  }

  const onClick = async () => {
    await Promise.resolve(dispatch(switchOrganization(org_id)));

    navigate(ROUTES.IMPROVE_DASHBOARD);
  };

  return (
    <TooltipWrapper text={t('switchOrgTooltip')} asChild delay={DEFAULT_TOOLTIP_DELAY_HOLDINGS_MS}>
      <Button
        lookAndFeel='secondary'
        onClick={onClick}
        iconNode={<Icon icon='external_link' color='gray-dark' size='small' />}
        size='small'
        style={{ width: 'fit-content' }}
      />
    </TooltipWrapper>
  );
};
