import { LCAIcon } from 'components/lca/components/LCAIcon';
import { CustomNode as DefaultCustomNode } from 'components/ui/canvas';

const CustomNodeIcon = LCAIcon;

type TotalImpactProps = {
  impact: string;
  unit: string;
  disabled?: boolean;
  position?: 'left' | 'right' | 'default';
};

const POSITION_CLASSES = {
  left: 'mr-auto',
  right: 'ml-auto',
  default: ''
} as const;

const TotalImpact: React.FC<TotalImpactProps> = ({ impact, unit, position = 'default' }) => {
  return (
    <div
      className={`gap-y-1 min-w-20 border-box p-2 flex-col items-center justify-center rounded-8 bg-neutral-gray-warm-80 ${POSITION_CLASSES[position]}`}>
      <h3 className='m-0 font-headings-h4-sb highlight-text-color'>{impact}</h3>
      <p className='m-0 text-tag font-body-b2-r'>{unit}</p>
    </div>
  );
};

export const CustomNode = {
  Layout: DefaultCustomNode.Layout,
  Content: DefaultCustomNode.Content,
  Icon: CustomNodeIcon,
  TotalImpact,
  Tag: DefaultCustomNode.Tag
};
