import { CustomNode } from './CustomNode';
import { useHover } from 'customHooks/useHover';
import { ScenarioNode } from '../../types/LCAScenarios';
import { Toolbar } from './Toolbar';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import { DeleteNodeActionBTN } from './ActionButtons/DeleteNodeActionBTN';
import { useACVId } from '../../hooks/useACVId';
import { Handle, Position, useConnection } from '@xyflow/react';
import { generateTags, getDeleteStateDefault, getHandleStyle } from './utils';
import { NODE_ICONS, NODE_TYPES } from '../../constants';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import { useNodeLCAImpact } from '../../hooks/useNodeLCAImpact';
import { buildLegendUnitFromNodeImpact } from 'components/lca/utils';
import { useLCATransportMetadata } from '../../hooks/useLCATransport';
import Icon from 'components/ui/icon/Icon';
import Button from 'components/ui/button/Button';
import { useModalContext } from '../ModalHandler';
import { DEFAULT_DELETE_TOOLTIP_TEXT } from './constants';

const TAG_ICON = {
  DISABLED: {
    true: {
      icon: 'warning',
      color: 'disabled'
    },
    false: {
      icon: 'success',
      color: 'blocked'
    }
  }
} as const;

const ConfigureTransportBTN: React.FC<{ node_id: string }> = ({ node_id }) => {
  const { t } = useLCATranslation();
  const { setModalData } = useModalContext();
  const { acv_id } = useACVId();
  const { data } = useLCATransportMetadata({ acv_id, node_id });

  const onClick = () => {
    setModalData({ type: 'edit_transport', target: node_id });
  };

  const btnTranslation = ['lcaScenarios', 'transportCard'];

  if (data?.unmatched_materials || !data?.name) {
    btnTranslation.push('addDetails');
  } else {
    btnTranslation.push('editDetails');
  }

  const btnLookAndFeel = btnTranslation.includes('addDetails') ? 'primary' : 'secondary';
  const icon = btnTranslation.includes('addDetails') ? 'add' : 'edit';
  const iconColor = btnTranslation.includes('addDetails') ? 'white' : 'gray-dark';

  return (
    <Button
      onClick={onClick}
      lookAndFeel={btnLookAndFeel}
      size='small'
      iconNode={<Icon icon={icon} color={iconColor} />}>
      {t(btnTranslation.join('.'))}
    </Button>
  );
};

type Props = {
  data: ScenarioNode['data'];
  selected?: boolean;
};

export const TransportNode: React.FC<Props> = ({ data, selected }) => {
  const { inProgress } = useConnection();

  const { t } = useLCATranslation();
  const { handleMouseEnter, handleMouseLeave, nodeRef, isHovered } = useHover();
  const { acv_id } = useACVId();
  const { data: nodeMetadata } = useLCATransportMetadata({ acv_id, node_id: data.id });
  const { data: nodeImpact } = useNodeLCAImpact({ node_id: data.id });

  const tags = generateTags(nodeMetadata, data, NODE_TYPES.transport);
  const hasConnections = data.outputs?.length > 0 || data.inputs?.length > 0;
  const deleteState = getDeleteStateDefault(hasConnections);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={nodeRef}>
      <Handle
        type='target'
        position={Position.Left}
        isConnectableEnd
        isConnectableStart={false}
        style={getHandleStyle('target', inProgress)}
      />
      {isHovered && (
        <Toolbar.Layout>
          <TooltipWrapper text={t(DEFAULT_DELETE_TOOLTIP_TEXT[deleteState])}>
            <DeleteNodeActionBTN acv_id={acv_id} node_id={data.id} disabled={hasConnections} />
          </TooltipWrapper>
        </Toolbar.Layout>
      )}
      <CustomNode.Layout selected={selected || (isHovered && inProgress)}>
        <CustomNode.Content>
          <CustomNode.Icon
            icon={NODE_ICONS[NODE_TYPES.transport]}
            lookAndFeel='transport'
            disabled={
              !data.inputs?.length || !nodeMetadata?.name || nodeMetadata.unmatched_materials > 0
            }
          />
          <div className='flex-col'>
            <span className='font-body-b2-r text-gray-light'>{t('lcaScenarios.transport')}</span>
            <span className='font-body-b1-sb'>{nodeMetadata?.name || '-'}</span>
          </div>
          {nodeImpact && (
            <CustomNode.TotalImpact
              impact={nodeImpact?.sum_total_impact.toFixed(2) ?? ''}
              unit={buildLegendUnitFromNodeImpact(nodeImpact)}
              position='right'
            />
          )}
        </CustomNode.Content>
        {tags.length > 0 && (
          <div className='flex gap-x-2 wrap max-w-20rem gap-y-1'>
            {tags.map((tag) => (
              <CustomNode.Tag key={data.id + tag.value} disabled={tag.disabled}>
                <Icon
                  icon={TAG_ICON.DISABLED[`${tag.disabled}`].icon}
                  color={TAG_ICON.DISABLED[`${tag.disabled}`].color}
                />
                {t(`lcaScenarios.transportTags.${tag.value}`, { count: tag.count })}
              </CustomNode.Tag>
            ))}
          </div>
        )}
        <Handle
          type='source'
          position={Position.Right}
          isConnectableStart={data.outputs?.length === 0}
          className='custom-source-handle'
          style={getHandleStyle('source', data.outputs?.length > 0)}
        />

        <ConfigureTransportBTN node_id={data.id} />
      </CustomNode.Layout>
    </div>
  );
};
