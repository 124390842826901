import { useQuery } from '@tanstack/react-query';
import {
  DatapointOutputData,
  fetchDatapointOutputDataProps,
  fetchDatapointSourceOutputData
} from 'services/api/sotOrganizationData';

export const useDatapointOutputData = (props: fetchDatapointOutputDataProps) => {
  const { data, isLoading, error } = useQuery<DatapointOutputData, Error>({
    queryKey: ['datapointOutputData', props],
    queryFn: () => fetchDatapointSourceOutputData(props)
  });
  return { datapointOutputData: data, isLoading, error };
};
