import debounce from 'lodash.debounce';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import type { IHeavyFileStatusFrontend } from '../../../types/entities/heavyFilesStatus';
import { InputSize } from '../../../types/utilsEnums/input';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import InputCalendarDouble from '../../ui/formComponents2/inputCalendarDouble/InputCalendarDouble';
import InputText from '../../ui/formComponents2/inputText/InputText';
import InputWrapperPlain from '../../ui/formComponents2/inputUtils/inputWrapperPlain/InputWrapperPlain';
import InputWrapperPlainLabel from '../../ui/formComponents2/inputUtils/inputWrapperPlain/InputWrapperPlainLabel';
import Select from '../../ui/formComponents2/select/Select';
import Icon from '../../ui/icon/Icon';
import InfiniteList from '../../ui/infiniteList/InfiniteListV2';
import StepCarouselList from '../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../ui/stepCarouselList/useStepCarousel';
import { DeleteFileModal } from './components/deleteFileModal/DeleteFileModal';
import Legend from './components/legend/Legend';
import { CATEGORIES_TRANSLATION, CategoriesUploadedFiles } from './constants';
import { useColumns } from './hooks/useColumns';
import useConstants from './hooks/useConstants';
import useFetchData from './hooks/useFetchData';
import './styles.scss';
import { useQueryClient } from '@tanstack/react-query';

const areFiltersEmpty = (filters: Record<string, string>) => {
  return Object.values(filters).every((value) => !value);
};

const UploadedFiles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { selectOptions, steps, isLoading } = useConstants();
  // get parameter form url
  const params = useParams<{ category: CategoriesUploadedFiles }>();
  const [queryParams, setQueryParams] = useSearchParams();
  const [name, setName] = useState(queryParams.get('name') ?? '');
  const [dates, setDates] = useState({
    startDate: queryParams.get('startDate') ?? '',
    endDate: queryParams.get('endDate') ?? ''
  });

  useEffect(() => {
    if (!params?.category) navigate(`${ROUTES.MEASURE_UPLOADED_FILES}/${steps[0].id}`);
  }, [params?.category]);

  const category = params.category ?? steps[0].id;

  const [fileToDelete, setFileToDelete] = useState<IHeavyFileStatusFrontend | undefined>(undefined);
  const { columns } = useColumns({ setFileToDelete, category });
  const queryClient = useQueryClient();

  const {
    steps: stepsCompleated,
    handleSelect,
    stepSelected
  } = useStepCarousel({ stepsText: steps, selected: params?.category, isLoading });

  const filters = {
    startDate: dates.startDate,
    endDate: dates.endDate,
    status: queryParams.get('status') ?? '',
    name: queryParams.get('name') ?? ''
  };

  const { heavyFileStatus, loading, total, errors, pending, success, fetchData, deleteFile } =
    useFetchData({
      category: stepSelected?.id || params?.category || steps?.at(0)?.id || '',
      filters
    });

  const onDeleted = (id: string) => {
    deleteFile(id);

    if (category === CategoriesUploadedFiles.PURCHASES) {
      queryClient.invalidateQueries({ queryKey: ['purchases'] });
      queryClient.invalidateQueries({ queryKey: ['purchaseSuppliers'] });
    }
  };

  const onChangeValue = (key: string) => (value: string) => {
    setQueryParams((prev) => {
      if (value) {
        prev.set(key, value);
      } else {
        prev.delete(key);
      }

      return prev;
    });
  };

  const handleName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    debounce(() => onChangeValue('name')(event.target.value), 1000)();
  };

  const handleDate = (key: string) => (date: string) => {
    setDates((prev) => ({ ...prev, [key]: date }));
  };

  const handleSelectCategory = (id: string) => {
    if (steps.find((step) => step.id === id)?.disabled) {
      return;
    }

    handleSelect(id);
    setName('');
    setDates({ startDate: '', endDate: '' });
    navigate(`${ROUTES.MEASURE_UPLOADED_FILES}/${id}`);
  };

  const onClose = () => {
    setFileToDelete(undefined);
  };

  const onChangeStatus = (selectOption: SelectOptionFormat) => {
    onChangeValue('status')(selectOption.id);
  };

  const emptyText = areFiltersEmpty(filters)
    ? t('uploadedFiles.empty', { category: t(CATEGORIES_TRANSLATION[category]).toLowerCase() })
    : t('uploadedFiles.emptyWithFilters');

  return (
    <section className='uploaded-files'>
      <DeleteFileModal
        file={fileToDelete}
        category={category}
        onClose={onClose}
        onDeleted={onDeleted}
      />
      <SectionHeader title={t('uploadedFiles.title')} />
      <div className='uploaded-files-container'>
        <div className='item-header'>
          <StepCarouselList
            steps={stepsCompleated}
            handleSelect={handleSelectCategory}
            lookAndFeel='big'
          />
        </div>
        <InfiniteList
          className='item-table'
          fetchData={fetchData}
          emptyText={emptyText}
          data={heavyFileStatus}
          columns={columns}
          loading={loading}
          total={total}
          header={
            <>
              <div className='table-filters'>
                <InputWrapperPlainLabel
                  size={InputSize.SMALL}
                  leftSection={<Icon icon='search' color={'gradient'} />}>
                  <InputText
                    onChange={handleName}
                    placeholder={t('uploadedFiles.filters.name')}
                    value={name}
                  />
                </InputWrapperPlainLabel>
                <InputWrapperPlain
                  size={InputSize.SMALL}
                  icon='/images/icons/calendar50.svg'
                  width='300px'>
                  <InputCalendarDouble
                    startDate={dates.startDate}
                    endDate={dates.endDate}
                    handleChangeStartDate={handleDate('startDate')}
                    handleChangeEndDate={handleDate('endDate')}
                  />
                </InputWrapperPlain>
                <InputWrapperPlain
                  size={InputSize.SMALL}
                  icon='/images/icons/filter.svg'
                  width='150px'>
                  <Select
                    placeholder={t('uploadedFiles.filters.status')}
                    options={selectOptions}
                    value={
                      selectOptions.find((option) => option.id === queryParams.get('status')) ?? {
                        id: '',
                        name: ''
                      }
                    }
                    onChange={onChangeStatus}
                  />
                </InputWrapperPlain>
              </div>
              <div className='table-tags'>
                <Legend
                  total={total}
                  loading={loading}
                  error={errors}
                  pending={pending}
                  success={success}
                />
              </div>
            </>
          }
        />
      </div>
    </section>
  );
};

export default UploadedFiles;
