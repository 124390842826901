import apiFetch from 'utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';

export type FrameworkPlanPayload = {
  framework_id?: string;
  purchased?: boolean;
  visible?: boolean;
  plan_start_date?: string;
  plan_end_date?: string;
};
export type FrameworkPatchPayload = {
  visible: boolean;
};
export type FrameworkPlan = {
  id: string;
  framework_id: string;
  plan_start_date: string;
  plan_end_date: string;
  purchased: boolean;
  visible: boolean;
  framework: {
    id: string;
    name: string;
    version: string;
  };
};
export type OrganizationFramework = {
  id: string;
  framework_id: string;
  reporting_start_date: string;
  reporting_end_date: string;
  framework: {
    id: string;
    name: string;
    version: string;
  };
};
export type SaveFramework = {
  id?: string;
  framework_id: string;
  plan_start_date: string;
  plan_end_date: string;
  purchased: boolean;
  visible: boolean;
};

export type AvailableFramework = {
  id: string;
  name: string;
  version: string;
};

/**
 * Helper function to conditionally add headers.
 */
const withOrganizationHeader = (organizationId?: string) => {
  return organizationId ? { 'x-organization-id': organizationId } : {};
};

/**
 * Fetch all available frameworks for an organization.
 */
export const fetchAvailableFrameworks = async (organizationId?: string) => {
  const response = await apiFetch(
    'GET',
    '/sot_framework',
    organizationId ? {} : null, // Pass empty body only if organizationId exists
    withOrganizationHeader(organizationId)
  );
  return response.data as AvailableFramework[];
};

/**
 * Fetch framework plans for a specific framework and organization.
 */
export const fetchFrameworkPlans = async (frameworkId: string, organizationId?: string) => {
  try {
    const response = await apiFetch(
      'GET',
      getUrl(`/sot_organization_framework_plan`, {
        queryParams: generateQueryParamsFromObject({ framework_id: frameworkId })
      }),
      organizationId ? {} : null, // Pass empty body only if organizationId exists
      withOrganizationHeader(organizationId)
    );
    return response.data as FrameworkPlan | null;
  } catch (error) {
    console.error(`Error fetching framework plans for framework ${frameworkId}:`, error);
    throw error;
  }
};

/**
 * Fetch framework for a specific organization.
 */
export const fetchOrganizationFrameworks = async (year: string, organizationId?: string) => {
  try {
    const response = await apiFetch(
      'GET',
      getUrl(`/sot_organization_framework`, {
        queryParams: generateQueryParamsFromObject({ year: year })
      }),
      organizationId ? {} : null, // Pass empty body only if organizationId exists
      withOrganizationHeader(organizationId)
    );
    return response.data as OrganizationFramework[] | null;
  } catch (error) {
    console.error(`Error fetching framework plans for frameworks on ${year}:`, error);
    throw error;
  }
};

/**
 * Create a new framework plan.
 */
export const createFrameworkPlan = async (
  payload: FrameworkPlanPayload,
  organizationId?: string
) => {
  try {
    const response = await apiFetch(
      'POST',
      '/sot_organization_framework_plan',
      payload,
      withOrganizationHeader(organizationId)
    );
    return response.data as FrameworkPlan;
  } catch (error) {
    console.error('Error creating framework plan:', error);
    throw error;
  }
};

/**
 * Update an existing framework plan.
 */
export const updateFrameworkPlan = async (
  planId: string,
  payload: FrameworkPlanPayload,
  organizationId?: string
) => {
  try {
    const response = await apiFetch(
      'PUT',
      `/sot_organization_framework_plan/${planId}`,
      payload,
      withOrganizationHeader(organizationId)
    );
    return response.data as FrameworkPlan;
  } catch (error) {
    console.error(`Error updating framework plan ${planId}:`, error);
    throw error;
  }
};

/**
 * Update an existing framework plan for a child organization.
 */
export const updateChildFrameworkPlan = async (
  planId: string,
  payload: FrameworkPatchPayload,
  organizationId?: string
) => {
  try {
    const response = await apiFetch(
      'PATCH',
      `/sot_organization_framework_plan/${planId}`,
      payload,
      withOrganizationHeader(organizationId)
    );
    return response.data as FrameworkPlan;
  } catch (error) {
    console.error(`Error updating framework plan ${planId}:`, error);
    throw error;
  }
};
