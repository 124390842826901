import { Organization } from 'types/entities/organization';
import {
  DONT_HAVE_SCOPE_3_DEFAULT_FIELDS,
  HAVE_SCOPE_3_DEFAULT_FIELDS,
  SCOPE_3_FIELDS
} from '../constants';

export const getScope3DefaultFields = (hasScope3: boolean) => {
  if (hasScope3) {
    return HAVE_SCOPE_3_DEFAULT_FIELDS;
  }

  return DONT_HAVE_SCOPE_3_DEFAULT_FIELDS;
};

export const getDefaultScope3 = (org: Organization) => {
  const hasScope3 = SCOPE_3_FIELDS.some((field) => {
    if (!org[field]) {
      return false;
    }

    return (org[field] as number) > 1;
  });

  return { hasScope3, ...getScope3DefaultFields(hasScope3) };
};
