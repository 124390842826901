import useOnChangeValue from '../../../../../../../../customHooks/useOnChangeValue';
import FormWrapper from '../../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormTextArea from '../../../../../../../ui/formComponents2/formInputs/formTextArea/FormTextArea';
import { FormDataStateESG } from '../ModalDatapoint';
import { EvidenceLabel } from './EvidenceLabel';

type Props = {
  formDataState: FormDataStateESG;
  onChangeFiles: (files: File[]) => void;
};

export const TextAreaComponent = ({ formDataState, onChangeFiles }: Props) => {
  const { formData, setFormData } = formDataState;
  const { onChangeValue } = useOnChangeValue({ setFormData });
  return (
    <FormWrapper>
      <FormTextArea
        label={<EvidenceLabel onChangeFiles={onChangeFiles} />}
        value={formData.value as string}
        onChange={onChangeValue('value')}
        error={formData.errors.find((elem: ErrorType) => elem.error === 'value')}
      />
    </FormWrapper>
  );
};
