import Dot from 'components/ui/dot/Dot';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import Button from 'components/ui/button/Button';
import { useNavigate } from 'react-router-dom';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';
import { ROUTES } from 'constants/routes';

export type FrameworkDatapoint = {
  id: string;
  name_dcycle: string;
  datapoint_id?: string; // Optional for GHGOutputData
  sources?: string[]; // Optional for CSRDOutputData
  mandatory?: boolean; // Optional, as both have this field but not always needed
  being_reported?: boolean; // Optional, same reason as above
  name?: string; // Translated name from the backend
  name_en: string;
  name_es: string;
  name_pt: string;
  default_response?: string; // Optional, only for CSRDOutputData
  csrd_data_type?: string; // Optional, only for CSRDOutputData
  reporting_unit_id?: string; // Optional, only for CSRDOutputData
};
export type FrameworkDatapointBox = {
  datapoints: FrameworkDatapoint[];
  boxColor: string;
  dotColor: string;
  datapointBorderColor: string;
  title: string;
  total: number;
};
export type DatapointListRendererProps = {
  datapointBoxes: FrameworkDatapointBox[];
  category: string;
};
const DatapointListRenderer = ({ datapointBoxes, category }: DatapointListRendererProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const getTranslatedName = (datapoint: FrameworkDatapoint) => {
    const currentLanguage = i18n.language;

    switch (currentLanguage) {
      case 'es':
        return datapoint.name_es;
      case 'pt':
        return datapoint.name_pt;
      default:
        return datapoint.name_en;
    }
  };
  return (
    <div className='flex-col width-100'>
      <div className='flex gap-4' style={{ paddingRight: '5px' }}>
        {datapointBoxes.map((datapointBox, index) => (
          <div
            style={{
              backgroundColor: datapointBox.boxColor,
              width: '33%',
              position: 'relative',
              height: 'min-content'
            }}
            key={index}
            className='rounded-t-8 flex-col'>
            <div className='flex items-center justify-between pt-4 px-4 pb-4'>
              <div className='flex items-center font-12 semibold-font gap-2'>
                <Dot
                  type='custom'
                  color={datapointBox.dotColor}
                  style={{ width: '0.5rem', height: '0.5rem' }}
                />
                {datapointBox.title}
              </div>
              <span className='font-14 semibold-font' style={{ color: datapointBox.dotColor }}>
                {datapointBox.total}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div
        className='flex gap-4 width-100 scrollbar-outside-box'
        style={{ maxHeight: '600px', overflowY: 'scroll', paddingRight: '5px' }}>
        {datapointBoxes.map((datapointBox, index) => (
          <div
            style={{
              backgroundColor: datapointBox.boxColor,
              width: '33%',
              position: 'relative',
              height: 'min-content'
            }}
            key={index}
            className='rounded-b-8 flex-col'>
            {datapointBox.total > 0 && (
              <div className='flex-col gap-4 pb-4 px-4 pt-0'>
                {datapointBox.datapoints.map((datapoint) => {
                  if (datapoint.sources && datapoint.sources.length > 0) {
                    // GHG Datapoint
                    return (
                      <Button
                        key={datapoint.id}
                        title={datapointBox.title}
                        onClick={() =>
                          navigate(
                            getUrl(
                              ROUTES.IMPROVE_DASHBOARD_TOTAL +
                                `/ghg_protocol/${category}/${
                                  datapoint.sources
                                    ? datapoint.sources
                                        .map((source) =>
                                          t(`controlPanel.frameworkProgress.sources.${source}`)
                                        )
                                        .join(', ')
                                    : 'source'
                                }`,
                              {
                                queryParams: generateQueryParamsFromObject({ mode: 'controlPanel' })
                              }
                            ),
                            {
                              state: { datapoint }
                            }
                          )
                        }
                        lookAndFeel='secondary'
                        style={{
                          backgroundColor: '#ffffff',
                          alignItems: 'flex-start'
                        }}
                        className='rounded-8 p-4 border-1 flex-col gap-2 button-as-card-border'>
                        <span className='font-10 regular-font text-neutral-gray-20'>
                          {datapoint.sources
                            .map((source) => t(`controlPanel.frameworkProgress.sources.${source}`))
                            .join(', ')}
                        </span>
                        <span className='font-12 regular-font'>{getTranslatedName(datapoint)}</span>
                      </Button>
                    );
                  } else {
                    // CSRD Datapoint
                    return (
                      <Button
                        key={datapoint.id}
                        title={datapointBox.title}
                        onClick={() =>
                          navigate(
                            getUrl(
                              ROUTES.IMPROVE_DASHBOARD_TOTAL +
                                `/csrd/${category}/${datapoint.datapoint_id}`,
                              {
                                queryParams: generateQueryParamsFromObject({ mode: 'controlPanel' })
                              }
                            ),
                            {
                              state: { datapoint }
                            }
                          )
                        }
                        lookAndFeel='secondary'
                        style={{
                          backgroundColor: '#ffffff',
                          alignItems: 'flex-start'
                        }}
                        className='rounded-8 p-4 border-1 flex-col gap-2 button-as-card-border'>
                        <span className='font-10 regular-font text-neutral-gray-20'>
                          {datapoint.datapoint_id}
                        </span>
                        <span className='font-12 regular-font'>{getTranslatedName(datapoint)}</span>
                      </Button>
                    );
                  }
                })}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DatapointListRenderer;
