import { GrayBox } from 'components/ui/GrayBox/GrayBox';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from 'components/lca/Scenarios/constants';
import FormText from 'components/ui/formComponents2/formInputs/formText/FormText';
import Modal from 'components/ui/modal/Modal';
import StepCarouselList from 'components/ui/stepCarouselList/StepCarouselList';
import { useStepContext } from 'components/ui/stepCarouselList/StepContext';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';
import { useFormContext } from '../FormContext';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputNumber from 'components/ui/formComponents2/inputNumber/InputNumber';
import Select from 'components/ui/formComponents2/select/Select';
import { useUnits } from 'customHooks/api/useUnits';
import Button from 'components/ui/button/Button';
import { adaptZodErrors } from 'adapters/zodErrors';
import { formSchema } from '../schema';
import { useACVId } from 'components/lca/Scenarios/hooks/useACVId';
import { usePatchLCAProcessNode } from 'components/lca/Scenarios/hooks/usePatchLCAProcessNode';
import { useModalContext } from '../../ModalHandler';
import { useCreateLCAReferenceOutput } from 'components/lca/Scenarios/hooks/useCreateLCAReferenceOutput';

type Props = {
  errors: Record<string, ErrorType> | null;
  setErrors: (errors: Record<string, ErrorType>) => void;
};

export const ThirdStep: React.FC<Props> = ({ errors, setErrors }) => {
  const { t } = useLCATranslation();
  const { steps, handleSelect } = useStepContext();
  const { form, setForm } = useFormContext();
  const { data, isLoading } = useUnits();

  const handleChange = (key: keyof (typeof form)['reference_output']) => (value: string) => {
    setForm((prev) => ({
      ...prev,
      reference_output: {
        ...prev.reference_output,
        [key]: value
      }
    }));
  };

  const unit = data.find((unit) => unit.id === form.reference_output.unit_id) ?? {
    id: '',
    name: ''
  };

  return (
    <>
      <Modal.Header
        title={t('lcaDetail.processFlow.processModal.title')}
        description={t('lcaDetail.processFlow.processModal.description')}
      />
      <StepCarouselList
        steps={steps}
        handleSelect={handleSelect}
        style={{ marginBottom: '1rem' }}
      />
      <Modal.Content style={{ flexGrow: 1, height: DEFAULT_HEIGHT, width: DEFAULT_WIDTH }}>
        <GrayBox.Padless className='p-4 border-box h-full'>
          <div className='grid grid-cols-3 gap-y-4'>
            <FormText
              label={t('lcaDetail.processFlow.processModal.reference_output_name')}
              iconV2='shirt'
              placeholder={t(
                'lcaDetail.processFlow.processModal.reference_output_name_placeholder'
              )}
              value={form.reference_output.name}
              error={errors?.reference_output}
              onChange={(event) => handleChange('name')(event.target.value)}
            />
            <InputWrapper
              className='col-start-1'
              label={t('lcaDetail.processFlow.processModal.reference_output_unit_and_value')}
              iconV2='input_numeric'>
              <InputNumber value={form.reference_output.value} onChange={handleChange('value')} />
              <Select
                loading={isLoading}
                options={data}
                value={unit}
                onChange={(newUnit) => handleChange('unit_id')(newUnit.id)}
              />
            </InputWrapper>
          </div>
        </GrayBox.Padless>
      </Modal.Content>
      <Modal.Buttons>
        <Buttons setErrors={setErrors} />
      </Modal.Buttons>
    </>
  );
};

type ButtonsProps = {
  setErrors: (errors: Record<string, ErrorType>) => void;
};

const Buttons = ({ setErrors }: ButtonsProps) => {
  const { t } = useLCATranslation();
  const { setModalData, modalData } = useModalContext();
  const { patchLCAProcessNode, isLoading } = usePatchLCAProcessNode();
  const { createLCAReferenceOutput, isPending } = useCreateLCAReferenceOutput();

  const { acv_id } = useACVId();

  const { form } = useFormContext();

  const onCancel = () => {
    setModalData(undefined);
  };

  const onSave = async () => {
    if (!modalData?.target || modalData.type !== 'edit_process') return;

    const validationResult = formSchema.THIRD.safeParse(form);

    if (!validationResult.success) {
      setErrors(adaptZodErrors(validationResult.error, t));

      return;
    }

    await patchLCAProcessNode({
      form: {
        country: form.country,
        inputs: form.inputs,
        name: form.name
      },
      acv_id,
      nodeId: modalData.target
    });

    await createLCAReferenceOutput({
      acv_id,
      node_id: modalData.target,
      name: form.reference_output.name,
      quantity: Number(form.reference_output.value),
      unit_id: form.reference_output.unit_id
    });

    setModalData(undefined);
  };

  return (
    <>
      <Button
        lookAndFeel='secondary'
        text={t('lcaDetail.processFlow.processModal.cancel')}
        size='small'
        onClick={onCancel}
      />
      <Button
        lookAndFeel='primary'
        text={t('lcaDetail.processFlow.processModal.save')}
        size='small'
        loading={isLoading || isPending}
        onClick={onSave}
      />
    </>
  );
};
