import Icon from '../../../../../../ui/icon/Icon';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import Button from '../../../../../../ui/button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../../constants/routes';
import { convertStringToDateBackend } from '../../../../../../../utils/convertDates';
import Dot from '../../../../../../ui/dot/Dot';
import { fifth_color } from '../../../../../../../styles/colors';
import { CategoriesUploadedFiles } from '../../../../../../measure/uploadedFiles/constants';

type Props = {
  notification: NotificationBackend;
  handleOnClick: (notification: NotificationBackend) => void;
};
const NotificationContent = ({ notification, handleOnClick }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'notifications' });

  const navigate = useNavigate();

  const code = notification.message.params.notification.message?.code ?? '';
  const category = notification.message.params.notification.category;
  const count = notification.message.params.notification.message?.count ?? 0;

  const validationErrors = ['PROCESSING_ERROR', 'VALIDATION_ERROR'];

  const renderTitle = () => {
    if (validationErrors.includes(code)) {
      return count === 1
        ? t('validationError.titleSingular')
        : t('validationError.title', { counter: count });
    }
    if (code === 'DUPLICATED_FILE_ERROR') {
      return t('duplicatedFileError.title');
    }
    if (code === 'FILE_PROCESSING_SUCCESS') {
      return t('fileProcessingSuccess.title');
    }
    if (code === 'NEW_EMISSION_FACTORS_AVAILABLE') {
      return t('newEmissionFactors.title');
    }
    if (code === 'RECALCULATION_COMPLETED') {
      return t('emissionFactorsUpdated.title');
    }
    if (code === 'INVOFOX_ERROR') {
      return t('invofoxError.title');
    }

    return '';
  };

  const renderDescription = () => {
    if (validationErrors.includes(code)) {
      return count === 1
        ? t('validationError.descriptionSingular')
        : t('validationError.description', { counter: count });
    }
    if (code === 'DUPLICATED_FILE_ERROR') {
      return t('duplicatedFileError.description');
    }
    if (code === 'FILE_PROCESSING_SUCCESS') {
      return t('fileProcessingSuccess.description');
    }
    if (code === 'NEW_EMISSION_FACTORS_AVAILABLE') {
      return t('newEmissionFactors.description');
    }
    if (code === 'RECALCULATION_COMPLETED') {
      return t('emissionFactorsUpdated.description');
    }
    if (code === 'INVOFOX_ERROR') {
      return t('invofoxError.description');
    }

    return '';
  };

  const renderCategory = () => {
    if (category === 'TRANSPORT_AND_DISTRIBUTION_DOWNSTREAM') {
      return t('categories.transportAndDistributionDownstream');
    }
    if (category === 'TRANSPORT_AND_DISTRIBUTION_UPSTREAM') {
      return t('categories.transportAndDistributionUpstream');
    }
    if (category === 'VEHICLES') {
      return t('categories.vehicles');
    }
    if (category === 'VEHICLE_CONSUMPTIONS') {
      return t('categories.vehicleConsumptions');
    }
    return '';
  };

  const renderIcon = () => {
    if (
      validationErrors.includes(code) ||
      code === 'DUPLICATED_FILE_ERROR' ||
      code === 'INVOFOX_ERROR'
    ) {
      return <Icon icon='warning' color='warning' size='large' />;
    }
    if (code === 'FILE_PROCESSING_SUCCESS' || code === 'RECALCULATION_COMPLETED') {
      return <Icon icon='success' color='success' size='large' />;
    }

    if (code === 'NEW_EMISSION_FACTORS_AVAILABLE') {
      return <Icon icon='information' color='gradient' size='large' />;
    }

    return <></>;
  };

  const navigateToCategory = (showErrors = false) => {
    if (category === 'TRANSPORT_AND_DISTRIBUTION_DOWNSTREAM') {
      handleOnClick(notification);
      let route = ROUTES.MEASURE_ACTIVITY_SHIPMENTS + '?transport_direction=downstream';
      if (showErrors) route += '&status=error';
      navigate(route);
    }
    if (category === 'TRANSPORT_AND_DISTRIBUTION_UPSTREAM') {
      handleOnClick(notification);
      let route = ROUTES.MEASURE_ACTIVITY_SHIPMENTS + '?transport_direction=upstream';
      if (showErrors) route += '&status=error';
      navigate(route);
    }
    const vehicleCategories = ['VEHICLES', 'VEHICLE_CONSUMPTIONS'];
    if (vehicleCategories.includes(category)) {
      handleOnClick(notification);
      navigate(ROUTES.MEASURE_VEHICLES);
    }
  };

  const navigateToUploadedFiles = () => {
    if (category === 'TRANSPORT_AND_DISTRIBUTION_DOWNSTREAM') {
      handleOnClick(notification);
      navigate(ROUTES.MEASURE_UPLOADED_FILES + '/' + CategoriesUploadedFiles.TRANSPORT_DOWNSTREAM);
    }
    if (category === 'TRANSPORT_AND_DISTRIBUTION_UPSTREAM') {
      handleOnClick(notification);
      navigate(ROUTES.MEASURE_UPLOADED_FILES + '/' + CategoriesUploadedFiles.TRANSPORT_UPSTREAM);
    }

    if (category === 'VEHICLES') {
      handleOnClick(notification);
      navigate(ROUTES.MEASURE_UPLOADED_FILES + '/' + CategoriesUploadedFiles.VEHICLES);
    }
    if (category === 'VEHICLE_CONSUMPTIONS') {
      handleOnClick(notification);
      navigate(ROUTES.MEASURE_UPLOADED_FILES + '/' + CategoriesUploadedFiles.VEHICLE_CONSUMPTIONS);
    }
  };

  const renderButton = () => {
    if (validationErrors.includes(code)) {
      return (
        <Button
          lookAndFeel='secondary'
          text={t('viewIncidences')}
          size='small'
          style={{ width: 'fit-content' }}
        />
      );
    }
    if (code === 'DUPLICATED_FILE_ERROR') {
      return (
        <Button
          lookAndFeel='secondary'
          text={t('viewIncidences')}
          size='small'
          style={{ width: 'fit-content' }}
        />
      );
    }
    if (code === 'FILE_PROCESSING_SUCCESS') {
      return (
        <Button
          lookAndFeel='secondary'
          text={t('viewImpact')}
          size='small'
          style={{ width: 'fit-content' }}
        />
      );
    }

    if (code === 'NEW_EMISSION_FACTORS_AVAILABLE') {
      return (
        <Button
          lookAndFeel='primary'
          text={t('updateEmissionFactors')}
          size='small'
          style={{ width: 'fit-content' }}
        />
      );
    }

    if (code === 'RECALCULATION_COMPLETED') {
      return (
        <Button
          lookAndFeel='secondary'
          text={t('updateVersion')}
          size='small'
          style={{ width: 'fit-content' }}
        />
      );
    }
  };

  const handleOnClickNotification = () => {
    handleOnClick(notification);
    if (validationErrors.includes(code)) {
      navigateToCategory(true);
    }
    if (code === 'DUPLICATED_FILE_ERROR') {
      navigateToUploadedFiles();
    }
    if (code === 'FILE_PROCESSING_SUCCESS') {
      navigateToCategory();
    }

    if (code === 'NEW_EMISSION_FACTORS_AVAILABLE') {
      navigate(ROUTES.MEASURE);
    }

    if (code === 'RECALCULATION_COMPLETED') {
      navigate(ROUTES.PROFILE);
    }
  };

  return (
    <button
      className='notification-content flex gap-2 pointer'
      onClick={handleOnClickNotification}
      style={{ marginLeft: 0 }}>
      {renderIcon()}
      <div className='flex-col gap-2' style={{ width: '100%', boxSizing: 'border-box' }}>
        <div className='flex justify-between'>
          <span
            className={`title font-14 semibold-font ${
              notification.read ? 'disabled-text-color' : ''
            }`}>
            {renderTitle()}
          </span>
          <span className='font-12 regular-font disabled-text-color flex gap-2 items-center'>
            {convertStringToDateBackend(notification.timestamp.split(' ')[0]).toLocaleDateString()}
            {!notification.read && <Dot type='custom' color={fifth_color} />}
          </span>
        </div>
        <span className={`font-14 regular-font ${notification.read ? 'disabled-text-color' : ''}`}>
          {renderDescription()}
        </span>
        <div className='flex gap-2'>
          <Icon icon='file' color='gray' />
          <span className='font-12 regular-font disabled-text-color'>
            {notification.message.params.notification.file_name}
          </span>
        </div>
        <div className='flex justify-between gap-2 items-center'>
          {renderButton()}
          <span className='font-12 regular-font disabled-text-color'>{renderCategory()}</span>
        </div>
      </div>
    </button>
  );
};

export default NotificationContent;
