import { useState, ChangeEvent } from 'react';
import Modal from 'components/ui/modal/Modal';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import { useTranslation } from 'react-i18next';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import Button from 'components/ui/button/Button';
import LoaderStandard from 'components/ui/loaders/loaderStandard/LoaderStandard';
import Select from 'components/ui/formComponents/select/Select';
import { FrameworkPlan } from 'services/api/frameworks';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import {
  FiscalPeriodKeys,
  fiscalPeriodToSelectOption,
  fiscalPeriodTranslationMapper,
  getEndAmountOfDaysForFiscalYear,
  selectOptionToFiscalPeriod
} from 'utils/fiscalYeari18nHandler';
import { updateOrganizationFiscalData } from 'actions/auth';
import { useDispatch } from 'react-redux';
import useSaveFrameworksPlans from 'customHooks/api/useSaveFrameworksPlans';
import useFrameworksPlans from 'customHooks/api/useFrameworksPlans';
import padZero from 'utils/padZero';
import FormText from 'components/ui/formComponents2/formInputs/formText/FormText';
import { adapterFiscalYear, adapterFiscalYearForUpdateOrg } from 'adapters/adapterFiscalYear';
import { updateOrganization } from 'services/api/organization';

type FrameworksCustomizeYearModalProps = {
  saveFrameworksHandler: () => void;
  modalOpen: boolean;
  title: string;
  frameworkId: string;
};

const FrameworksCustomizeYearModal = ({
  saveFrameworksHandler,
  modalOpen,
  title,
  frameworkId
}: FrameworksCustomizeYearModalProps) => {
  const { t } = useTranslation('translation');
  const { frameworkPlans, frameworksLoading } = useFrameworksPlans();
  const { saveFrameworkPlans } = useSaveFrameworksPlans();
  const [baseYear, setBaseYear] = useState<string>(new Date().getFullYear().toString());
  const selectedOrganization = useSelectedOrganization();
  const [fiscalYear, setFiscalYear] = useState(adapterFiscalYear(selectedOrganization));
  const [fiscalYearChanged, setFiscalYearChanged] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const getPlanDates = (): { plan_start_date: string; plan_end_date: string } => {
    const currentYear = parseInt(baseYear, 10);

    return {
      plan_start_date: `${currentYear}-${padZero(fiscalYear.start_month)}-01`,
      plan_end_date: `${currentYear + 9}-${padZero(
        fiscalYear.end_month
      )}-${getEndAmountOfDaysForFiscalYear(fiscalYear)}`
    };
  };
  const dispatch = useDispatch();
  const handleSave = async () => {
    setLoadingButton(true);
    // Update fiscal year if changed
    // Dispatch updated fiscal year to Redux store
    if (fiscalYearChanged && selectedOrganization) {
      await updateOrganization(
        adapterFiscalYearForUpdateOrg({ organizationId: selectedOrganization.id, fiscalYear })
      );
      dispatch(updateOrganizationFiscalData(selectedOrganization?.id, fiscalYear));
    }

    // Get plan dates based on the reporting year type
    const { plan_start_date, plan_end_date } = getPlanDates();
    // prepare the framework payload
    // Find the existing plan for the selected framework
    const existingPlan = frameworkPlans?.find((plan) => plan && plan.id === frameworkId);
    if (!existingPlan) {
      return;
    }
    const frameworkPayload: FrameworkPlan = {
      ...existingPlan,
      plan_start_date,
      plan_end_date
    };
    await saveFrameworkPlans([frameworkPayload]);
    saveFrameworksHandler();
  };

  const handleChangeBaseYear = (value: SelectOptionFormat) => {
    setBaseYear(value.id);
  };

  const handleChangeFiscalYear = (value: SelectOptionFormat) => {
    setFiscalYear({
      ...selectOptionToFiscalPeriod(value.id as FiscalPeriodKeys)
    });
    setFiscalYearChanged(true);
  };
  const getYearsOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 5 }, (_, i) => ({
      id: (currentYear - i).toString(),
      name: (currentYear - i).toString()
    }));
  };
  const fiscalPeriodsOptions = fiscalPeriodTranslationMapper(t);

  return (
    <Modal.WithPortal
      show={modalOpen}
      onClose={saveFrameworksHandler}
      width='780px'
      maxWidth='780px'
      closeOnOutsideClick={false}>
      <h1 className='font-24 weight-600'>{title}</h1>
      <h2 className='font-14 weight-400 mb-12'>
        {t('controlPanel.metrics.configureFrameworksCustomizeYearModal.description')}
      </h2>
      {frameworksLoading ? (
        <LoaderStandard />
      ) : (
        <FormWrapper className='gap-4'>
          {/* <Select
            placeholder={t(
              'controlPanel.metrics.configureFrameworksCustomizeYearModal.selectOption'
            )}
            label={t(
              'controlPanel.metrics.configureFrameworksCustomizeYearModal.reportingYearType'
            )}
            value={{
              id: reportingYearType,
              name: t(
                `controlPanel.metrics.configureFrameworksCustomizeYearModal.${reportingYearType}`
              )
            }}
            options={[
              {
                id: 'naturalYear',
                name: t('controlPanel.metrics.configureFrameworksCustomizeYearModal.naturalYear')
              },
              {
                id: 'fiscalYear',
                name: t('controlPanel.metrics.configureFrameworksCustomizeYearModal.fiscalYear')
              }
            ]}
            onChangeValue={handleChangeReportingYearType}
            iconV2='signal'
          /> */}
          <FormText
            icon={'/images/icons/calendar.svg'}
            label={t(
              'controlPanel.metrics.configureFrameworksCustomizeYearModal.reportingYearType'
            )}
            placeholder={''}
            value={t('controlPanel.metrics.configureFrameworksCustomizeYearModal.fiscalYear')}
            onChange={function (e: ChangeEvent<HTMLInputElement>): void {
              console.log('Function not implemented.');
            }}
            disabled
          />
          <Select
            placeholder={t('controlPanel.metrics.configureFrameworksCustomizeYearModal.selectYear')}
            label={t('controlPanel.metrics.configureFrameworksCustomizeYearModal.baseYear')}
            value={{ id: baseYear, name: baseYear }}
            options={getYearsOptions()}
            onChangeValue={handleChangeBaseYear}
            iconV2='signal'
          />

          {selectedOrganization?.fiscal_year_start_month === undefined && (
            <Select
              iconV2='calendar'
              placeholder={t('profile.selectYourPeriod')}
              label={t('profile.fiscalYearStartEnd')}
              value={{
                id: fiscalPeriodToSelectOption(fiscalYear),
                name: t(`profile.fiscalPeriod.${fiscalPeriodToSelectOption(fiscalYear)}`)
              }}
              options={fiscalPeriodsOptions}
              onChangeValue={handleChangeFiscalYear}
              sort={false}
            />
          )}
        </FormWrapper>
      )}
      <FormButtonSection className='mt-16'>
        <Button
          lookAndFeel='primary'
          text={t('controlPanel.metrics.configureFrameworksCustomizeYearModal.save')}
          onClick={handleSave}
          loading={loadingButton}
        />
      </FormButtonSection>
    </Modal.WithPortal>
  );
};

export default FrameworksCustomizeYearModal;
