import { useQuery } from '@tanstack/react-query';
import { adaptLCAMetadata } from 'adapters/adaptLCAMetadata';
import { getLCAMetadata } from 'services/api/lca/lcaPortfolio';

type Params = {
  acv_id: string;
};

export const useLCANodeMetadata = ({ acv_id }: Params) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['lca-node-metadata', acv_id],
    queryFn: getLCAMetadata,
    select: adaptLCAMetadata
  });

  return { data, isFetching, isLoading };
};
