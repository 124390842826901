import { type ContractLimitFront } from 'components/measure/companies/Diagram/constants';
import { adapterEntityType } from './adaptEntityType';

export const adapterCreateContract = ({
  organizationId,
  form
}: PayloadCreateContractFront): PayloadCreateContract => {
  return {
    organization_id: organizationId,
    limit_facilities: Number(form.facilities),
    limit_vehicles: Number(form.vehicles),
    limit_employees: Number(form.employees)
  };
};

export const adapterContractAdmin = (
  contract: ContractBack | null
): Omit<ContractFront, 'organizationId'> => {
  if (!contract) {
    return {
      facilities: '0',
      vehicles: '0',
      employees: '0'
    };
  }

  return {
    facilities: contract.limit_facilities.toString(),
    vehicles: contract.limit_vehicles.toString(),
    employees: contract.limit_employees.toString()
  };
};

export const adapterContractLimits = (
  contractLimitsBack: ContractLimitBack[]
): ContractLimitFront => {
  const result = contractLimitsBack.reduce((acc, { limit, total_available, total_used }) => {
    acc[adapterEntityType(limit)] = {
      totalAvailable: total_available - total_used,
      totalUsed: total_used,
      total: total_available
    };

    return acc;
  }, {} as ContractLimitFront);

  return result;
};
