import { BackgroundVariant, type ReactFlowProps } from '@xyflow/react';
import { CompanyNode } from './components/CustomNodes';
import { DefaultEdge, ShareEdge } from './components/CustomEdges';
import { IconName } from 'types/utils/iconName';

export const DEFAULT_Y_GAP = 240;
export const DEFAULT_X_GAP = 340;

export const DIAGRAM_CONFIG = {
  NODE_ORIGIN: [0.5, 0.5] as ReactFlowProps['nodeOrigin'],
  MIN_ZOOM: 0.0001 as ReactFlowProps['minZoom'],
  PRO_OPTIONS: {
    hideAttribution: true
  } as ReactFlowProps['proOptions'],
  NODE_DRAG_THRESHOLD: 3 as ReactFlowProps['nodeDragThreshold'],
  BG: {
    VARIANT: BackgroundVariant.Lines,
    GAP: 48
  },
  SNAP_GRID: [DEFAULT_X_GAP, DEFAULT_Y_GAP] as ReactFlowProps['snapGrid']
} as const;

export const NODE_TYPES = {
  COMPANY: 'company'
} as const;

export const NODE_COMPONENTS = {
  [NODE_TYPES.COMPANY]: CompanyNode
} as const;

export const EDGE_TYPES = {
  DEFAULT: 'default',
  SHARE: 'share'
} as const;

export const EDGE_COMPONENTS = {
  [EDGE_TYPES.DEFAULT]: DefaultEdge,
  [EDGE_TYPES.SHARE]: ShareEdge
} as const;

export const DEFAULT_X = 0;
export const DEFAULT_Y = 0;
export const ORGANIZATION_FORM_ID = 'organization-form';
export const SHARE_FORM_ID = 'share-form';

export const ENTITY_TYPE = {
  FACILITIES: 'facilities',
  VEHICLES: 'vehicles',
  EMPLOYEES: 'employees'
} as const;

export const LIMIT_TYPES_ARRAY = Object.values(ENTITY_TYPE);

export type EntityType = (typeof ENTITY_TYPE)[keyof typeof ENTITY_TYPE];

export type ContractLimitFront = Record<
  EntityType,
  { totalUsed: number; totalAvailable: number; total: number }
>;

export const ICON_MAP = {
  [ENTITY_TYPE.FACILITIES]: 'facilities',
  [ENTITY_TYPE.VEHICLES]: 'car',
  [ENTITY_TYPE.EMPLOYEES]: 'user'
} as Record<EntityType, IconName>;

export const DEFAULT_TOOLTIP_DELAY_HOLDINGS_MS = 500;
