import { useQuery } from '@tanstack/react-query';
import { getContractParent } from 'services/api/holdings';

type RetryOptions = {
  retry?: false | number;
  retryOnMount?: false;
};

export const useContractParent = (organizationId?: string, options?: RetryOptions) => {
  const { data, isLoading, isLoadingError, isError } = useQuery({
    queryKey: ['contract-parent', organizationId],
    queryFn: getContractParent,
    enabled: !!organizationId,
    ...options
  });

  return { data, isLoading, isLoadingError, isError };
};
