import { useEffect, useState } from 'react';
import { useACVId } from './useACVId';
import { useLCAProcess } from './useLCAProcess';
import { buildForm } from '../components/ConfigureProcessModal/utils';
import { useNodeInputs } from './useNodeInputs';
import { getLanguage } from 'utils/lang';
import { useLCATranslation } from 'customHooks/translations/useLCATranslation';

export const useConfigureProcessForm = (node_id?: string) => {
  const { i18n, t } = useLCATranslation({ keyPrefix: 'lcaDetail.processFlow.processModal' });

  const { acv_id } = useACVId();

  const lang = getLanguage(i18n);

  const metadata = useLCAProcess({ node_id: node_id ?? '', acv_id });
  const { data: nodeMaterials } = useNodeInputs({ node_id, acv_id });

  const [form, setForm] = useState(buildForm(t, metadata, nodeMaterials, lang));
  const [errors, setErrors] = useState<ErrorContainer<typeof form> | null>(null);

  useEffect(() => {
    setForm(buildForm(t, metadata, nodeMaterials, lang));
    setErrors(null);
  }, [JSON.stringify(metadata), JSON.stringify(nodeMaterials)]);

  return { form, setForm, errors, setErrors };
};
