import { Primitive } from '../types/primitive';
export const groupBy = (xs: any, key: string) => {
  return xs.reduce(function (rv: any, x: any) {
    if (!key || !x[key]) return rv;
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const isInNarrowedArray = <ArrayValues extends Primitive>(
  value: Primitive,
  array: Array<ArrayValues> | ReadonlyArray<ArrayValues>
): value is ArrayValues => {
  return array.includes(value as ArrayValues);
};

export const filterDuplicates = <ArrayValues extends object, By extends keyof ArrayValues>(
  array: ArrayValues[],
  by: By
) => {
  return array.filter((item, index, self) => index === self.findIndex((t) => t[by] === item[by]));
};

export const mergeArrays = <ArrayValues extends object, By extends keyof ArrayValues>(
  array1: ArrayValues[],
  array2: ArrayValues[],
  by: By
) => {
  const mergedArray = [...array1, ...array2];

  return filterDuplicates(mergedArray, by);
};
